import { createStore } from 'vuex'
import router from '../router'
import { auth } from '../firebase'

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import serverConfig   from '@/_helpers/config';
import { objLang, t_trl }   from '@/_helpers/lang';  

import genFunct   from '@/_helpers/gfunct';
//import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/dist/sweetalert2.min.css';


//import Cookies from 'js-cookie'
/*
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  key:'vl',
  storage: window.localStorage
})
*/

import axios from "axios"

const ep = 'https://api.lacasadelgrano.com/v1'

export default createStore({
 
  state: {
    user: null,
    userdata:null,
    categories: [],
    pages: [],
    bannerblock:[],
    slideshows:[],
    contacts:[],
    products:[],
    productDetail:{},
    categorySel:null,
    language:'ita',
    cart:null,
    cartItems:0,
    total_price:0.00,
    detailCart:null,
    weight_stored:0.00,
  },
  mutations: {

    SET_USER (state, user) {
      state.user = user
    },

    CLEAR_USER (state) {
      state.user = null
    },
    SET_TOKEN (state, token) {
      state.userToken = token
    },
    CLEAR_TOKEN (state) {
      state.userToken = null
    },

    setUserData (state, val){
      state.userdata = val
    },
    setCategories(state, val) {
      state.categories = val
    },
    setPages (state, val){
      state.pages = val
    },
    setBannerBlock (state, val){
      state.bannerblock = val
    },
    setContacts (state, val){
      state.contacts = val
    },
    setCountries (state, val){
      state.countries = val
    },    
    setSlideshows  (state, val){
      state.slideshows = val
    },
    setLanguage(state, val){
      state.language = val 
    },
    setTranslate(state, val){
      state.trl = val
    },
    setProducts(state, val) {
      state.products = val
    },
    setProductDetails(state, val) {
      state.productDetail = val
    },
    setCategorySel(state, val){
      state.categorySel = val
    },
    setCart(state, val){
      state.cart = val
    },
    setCartItems(state, val) {
      state.cartItems = val
    },
    setTotalPrice(state, val) {
      state.total_price = val
    },
    setDCart(state, val) {
      state.detailCart = val
    },
    setWeight(state, val) {
      state.weight_stored = parseFloat(val)
    }

  },
  getters: {
    getUser(state){
      return state.user
    },
    getUsrData(state){
      return state.userdata
    },
    getToken(state){
      return state.userToken
    },
    getActiveWidgets( state ){
      return state.categories;
    },
    getLanguage (state) {
      return state.language;
    },
    getActivePage( state ){
      return state.pages;
    },
    getBlocks( state ){
      return state.bannerblock;
    },
    getSShow( state ){
      return state.slideshows;
    },
    getContacts( state){
      return state.contacts;
    },
    getCountries( state){
      return state.countries;
    },
    products(state) {
      return state.products
    },
    productDetails(state) {
      return state.productDetail
    },
    categorySel(state){
      return state.categorySel
    },
    getCart(state) {
      return state.cart;
    },
    getCartItems(state) {
      return state.cartItems
    },
    getTotalPrice(state) {
      return state.total_price
    },
    getDetCart(state) {
      return state.detailCart
    },
    getTrl(state) {
      return state.trl
    },
    getWeight(state) {
      return state.weight_stored
    },
  
  },
  actions: {
    async login ({ commit }, details) {

      const { email, password } = details
      try {
        let logIn = await signInWithEmailAndPassword(auth, email, password)
        const token = await  auth.currentUser.getIdToken();
        cookies.set("uid", auth.currentUser.uid); 
        cookies.set("tk", token);

      } catch (error) {
        let lang = 'ita'
        if (cookies.get("lang")){
          lang = cookies.get("lang")
        } 
        if(!cookies.get("lang")) {
          cookies.set("lang","ita")
          lang = 'ita'
        }        
        
        switch(error.code) {
          case 'auth/user-not-found':
            Swal.fire(objLang.loginUserNotFound[lang])
            break;
          case 'auth/wrong-password':
            Swal.fire(objLang.loginUserWrongPassword[lang])
            break;
          case 'auth/network-request-failed':
            Swal.fire(objLang.swlNetworkError[lang])
            break;   
          default:
            Swal.fire(objLang.SomethingWentWrong[lang])
            break;
        }

        return
      }

      commit('SET_USER', auth.currentUser)
      if (auth.currentUser){

        cookies.set("uid", auth.currentUser.uid); 
        auth.currentUser.getIdToken().then(token => {
          commit('SET_TOKEN', token);
          cookies.set("tk", token);  
        });
      }


      
        if (router.currentRoute._value.fullPath.indexOf("?next=")>0) {
          let url_split = router.currentRoute._value.fullPath.split("?next=")
         
          router.push('/cart/detail/')
        } else {
          router.push('/')
        }

      
    },
    reset ({ commit }, details) {
      let lang = 'ita'
      if (cookies.get("lang")){
        lang = cookies.get("lang")
      } 
      if(!cookies.get("lang")) {
        cookies.set("lang","ita")
        lang = 'ita'
      }             
      const { email } = details
      try {

        sendPasswordResetEmail(auth, email).then((response) => {
         
          Swal.fire(objLang.swlMsgResetPSWSuccess[lang])
         
        }).catch((e) => {
         
 
          switch(e.code) {
            case 'auth/missing-email':
              Swal.fire(objLang.invalidEmail[lang])
              break
            default:
              Swal.fire(objLang.SomethingWentWrong[lang])
              break;
          }
  
          return

        })

        
      } catch (error) {
        Swal.fire(objLang.SomethingWentWrong[lang])
      }
    },
    
    pswChange ({ commit }, details) {
      let lang = 'ita'
      if (cookies.get("lang")){
        lang = cookies.get("lang")
      } 
      if(!cookies.get("lang")) {
        cookies.set("lang","ita")
        lang = 'ita'
      }             
      const { code, password } = details
      try {

        confirmPasswordReset(auth, code, password).then((response) => {
         
          Swal.fire(objLang.swlMsgChangedPSWSuccess[lang])

          setTimeout(()=>{
            router.push('/account/login/')
          }, 4000)
         
        }).catch((e) => {
         
          Swal.fire(objLang.SomethingWentWrong[lang])

        })
        
      } catch (error) {
   
        Swal.fire(objLang.SomethingWentWrong[lang])
      }
    },
    async getAuth({commit}){
      
      console.log("-")
   
    },
    async register ({ commit}, details) {
       const { email, password } = details

      try {
        await createUserWithEmailAndPassword(auth, email, password)
        const token = await  auth.currentUser.getIdToken();
        cookies.set("uid", auth.currentUser.uid); 
        cookies.set("tk", token);
        commit('SET_USER', auth.currentUser)
        commit('SET_TOKEN', token)

      } catch (error) {
        let lang = 'ita'
        if (cookies.get("lang")){
          lang = cookies.get("lang")
        } 
        if(!cookies.get("lang")) {
          cookies.set("lang","ita")
          lang = 'ita'
        }    

        switch(error.code) {
          case 'auth/email-already-in-use':
            Swal.fire(objLang.emailAlreadyInUse[lang])
            break
          case 'auth/invalid-email':
            Swal.fire(objLang.invalidEmail[lang])
            break
          case 'auth/operation-not-allowed':
            alert("Operation not allowed")
            break
          case 'auth/weak-password':
            Swal.fire(objLang.weakPassword[lang])
            break
          default:
            Swal.fire(objLang.SomethingWentWrong[lang])
            break;
        }

        return
      }
      /*
      commit('SET_USER', auth.currentUser)
      if (auth.currentUser){
        auth.currentUser.getIdToken(true).then(token => {
          commit('SET_TOKEN', token);
            
        });
      }
      */
      router.push('/')
    },
    async tkRefresh({commit}){
      if (auth.currentUser){
        auth.currentUser.getIdToken(true).then(token => {
          commit('SET_TOKEN', token);
            console.log(token)
        });

      }        
    },
    async logout ({ commit }) {
      await signOut(auth)

      commit('CLEAR_USER')
      commit('CLEAR_TOKEN')
      cookies.remove("tk");
      cookies.remove("uid");
      router.push('/account/login')
    },
    setLang({commit}, lang) {
      if (lang=="it")
      {
        lang="ita"
      }
      if (lang=="es"){
        lang="esp"
      }
      commit("setLanguage",lang)
      

      commit("setTranslate",t_trl[lang])

      cookies.set("lang",lang);

    },
    getLang({commit}){
      let lang = 'ita'
      if (cookies.get("lang")){
        lang = cookies.get("lang")
      } 
      if(!cookies.get("lang")) {
        cookies.set("lang","ita")
        lang = 'ita'
      }
      commit("setLanguage",lang)
      commit("setTranslate",t_trl[lang]) 
    },
    fetchUser ({ commit }) {
      auth.onAuthStateChanged(async user => {
        if (user === null) {
          commit('CLEAR_USER')
          commit('CLEAR_TOKEN')

        } else {
          commit('SET_USER', user)

          if (router.isReady() && router.currentRoute.value.path === '/account/login') {
            router.push('/')
          }



        }
      })
    },
    getUserdata({ commit }) {
      const ep = 'https://api.lacasadelgrano.com/v1'
      let tk = cookies.get("tk")
      let uid = cookies.get('uid')
      axios.get(`${serverConfig.EP}/customers/check-user/${uid}`, {headers:{ Authorization: 'Bearer '+tk } } ).then((response) => {
          this.detail_user = response.data
          commit('setUserData',response.data)
        }).catch(error => {
            console.log(error);
        });
    },
    getProducts({ commit }, code) {
      axios.get(`${serverConfig.EP}/publicapi/allproducts/${code.toString()}`).then((response) => {
          cookies.set('cat',code)
          commit("setProducts", response.data.products);
          commit("setCategorySel",response.data.category_sel);
        }).catch(error => {
        console.log(error);
        });
    },
    getProductDetails({ commit }, slug) {
      axios.get(`${serverConfig.EP}/publicapi/product/detail/${slug.toString()}`).then((response) => {
          commit("setProductDetails", response.data);
          console.log(response.data)
        }).catch(error => {
        console.log(error);
        });
    },
    getWidgets({ commit }) {
      axios.get(`${serverConfig.EP}/publicapi/widget`).then((response) => {
          let categories = []
          let lang
          if ( cookies.get('lang')){ lang = cookies.get('lang')} else { lang='ita'}
          response.data.categories.forEach(function(item){
            categories.push({
              "value":item.code,
              "label":item.descriptions[lang].title,
              "text": item.descriptions[lang].title,
              "descriptions":item.descriptions,
            })
          })
          commit('setCategories',categories)
          commit('setPages', response.data.pages)
          commit('setBannerBlock', response.data.bannerblock)
          commit('setContacts',response.data.contacts)
          
          commit('setCountries',response.data.countries_shlist)

      }).catch(error => {
          console.log(error);
      });
    },
    async getSlideshows({ commit }) {
      axios.get(`${serverConfig.EP}/publicapi/slideshows`).then((response) => {
          if (response.data){
            commit('setSlideshows', response.data)
          }
          
      }).catch(error => {
          console.log(error);
      });
    },
    async addToCart ({ commit}, data) {     
      axios.post(`${serverConfig.EP}/orders/preregister-summary`, data).then((response) => {

          if (response.data.orderHeader != 0)
          {
            commit('setCart', {"oh":response.data.orderHeader,"puid":response.data.puid,"products_available":response.data.checkQTA});
            cookies.set("oh", response.data.orderHeader);
            cookies.set("puid", response.data.puid);


          } else {
            if (response.data.level == '4'){
              if(!cookies.get("lang")) {
                cookies.set("lang","ita")
              }
              Swal.fire(
                {
                  title:"",
                  text:t_trl[cookies.get("lang")].t_not_available,
                  icon:"info",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                }
              )
            }
          }

      }).catch(error => {
          console.log(error);
      });

    },

    async addToNewCart ({ commit}, data) {
            
      axios.post(`${serverConfig.EP}/orders/preregister`, data).then((response) => {
        
          if (response.data.orderHeader != 0)
          {
            commit('setCart', {"oh":response.data.orderHeader,"puid":response.data.puid,"products_available":response.data.checkQTA});
           
            cookies.set("oh", response.data.orderHeader);
            cookies.set("puid", response.data.puid);
            Swal.fire(
              {
                title:"",
                text:t_trl[cookies.get("lang")].t_added_to_cart,
                icon:"success",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2500,
              }
            )
            //getCartList(response.data.orderHeader,response.data.puid)

          } else {
            if (response.data.level == '4'){
              if(!cookies.get("lang")) {
                cookies.set("lang","ita")
              }
              Swal.fire(
                {
                  title:"",
                  text:t_trl[cookies.get("lang")].t_not_available,
                  icon:"info",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                }
              )
            }
          }

          //commit('setCart', response.data)

      }).catch(error => {
          console.log(error);
      });

    },
    async getCartList({ commit }, oh, puid){
      axios.get(`${serverConfig.EP}/orders/summary-cart/${oh}/${puid}`).then((response) => {
          if (response.data) {
            commit('setDCart', response.data)
            commit('setCartItems', response.data.total_qta);
            commit('setTotalPrice', response.data.order_header.total_price);

            let weight = 0
            response.data.orders.forEach(function(item){
              weight+=item.detail_order.MagPesoNettoUnit
              
            })
            commit('setWeight', parseFloat(genFunct.formatPrice(weight)))

          }

        }).catch(error => {
          console.log(error);
        });
      
    },
    async getDetailsCart({ commit }) {
     
      let cookies_oh = cookies.get("oh")
      let cookies_puid = cookies.get("puid")
      
      if (cookies_oh && cookies_puid) {
        axios.get(`${serverConfig.EP}/orders/summary-cart/${cookies_oh}/${cookies_puid}`).then((response) => {

            if (response.status ==  200){
              if (response.data) {
                commit('setDCart', response.data)
                commit('setCartItems', response.data.total_qta)
                commit('setTotalPrice', response.data.order_header.total_price);
                let weight = 0
               
                response.data.orders.forEach(function(item){
                  weight+=item.detail_order.MagPesoNettoUnit
                  
                })

                commit('setWeight',  parseFloat(weight))
              
              }
            }

          }).catch(error => {
            console.log(error);
          });
      } else {
        commit('setDCart', {})
        commit('setCartItems', 0);        
        commit('setTotalPrice', 0.00);
      }

    },
    setUpdateCart({commit}, order) {

      commit('setDCart', order)
      commit('setCartItems', order.total_qta);
      commit('setTotalPrice', order.order_header.total_price);
      //MagPesoNettoUnit
     // commit('setWeight', order.order_header.MagPesoNettoUnit)

     commit('setWeight', order.order_header.MagPesoNettoUnit)

    },
    updateToken({commit}){
    
      cookies.set("uid", auth.currentUser.uid); 
      auth.currentUser.getIdToken().then(token => {
        commit('SET_TOKEN', token);
        cookies.set("tk", token);  
      });
      
    },
    async removeItem(id) {
      
      axios.delete(`${serverConfig.EP}/orders/delete-item/${id}`).then((response) => {
          
          console.log(response)

        }).catch(error => {
          console.log(error);
        });


    },
    swlAlert(params){
      Swal.fire({
        title: params.title,
        text: params.text,
        icon: params.icon,
        showCancelButton: params.showCancelButton,
        showConfirmButton: params.showConfirmButton,
        timer: params.timer,
      })

    },
    sendWelcomeMsg(data){
      alert(data)
      /*
      axios.post(`${serverConfig.EP}/customers/welcome`, data).then((response) => {
        alert('Email inviata!')

      }).catch(error => {
          console.log(error);
      });
      */
    },
  
    
  },
  //plugins: [vuexLocal.plugin] 
})
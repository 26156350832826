<script>
import {  mapActions, mapGetters } from "vuex";
import { useStore } from 'vuex'
import axios from "axios"
import { useCookies } from "vue3-cookies";
import serverConfig   from '@/_helpers/config';
import { objLang, t_trl }   from '@/_helpers/lang';  
import moment from 'moment'

moment.locale('it')

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
  parsePhoneNumber,
} from 'libphonenumber-js'

export default {
    name: "UserForm",
    props: ["type"],
    setup(){
      const { cookies } = useCookies();
      const store = useStore();
      

      let minYear = () =>{
        return moment().subtract(90,'years').format('YYYY-MM-DD')
      }
      let min_year = minYear()
      let maxYear = () =>{
        return moment().subtract(16,'years').format('YYYY-MM-DD')
      }
      let max_year = maxYear()
      return { cookies, max_year,min_year };
    },
    data() {
      return {
        lang: $cookies.get('lang'),
        validate:null,
        form: {
          PartitaIva :"",
          CodFiscale : "",
          TipoSoggetto :0,
          TipoSoggettoRit :"",
          PersonaCognome : "",
          PersonaNome :"",
          PersonaComuneNascita : "",
          PersonaProvNascita : "",
          PersonaStatoNascita : "",
          PersonaDataNascita :moment().format('YYYY-MM-DD'),
          PersonaSesso : "",
          SoggContribPrevid : "",
          TitolarePartitaIVA : "",
          TipoAnagGen : "",
          CodiceAnagGen : "",
          SedeLegaleNumIndir : "",
          CodAnagPROFIS :"",
          PubblicaAmministr :"",
          CodAteco2007 :"",
          SoggEsteroTipoIdIVA : "",
          SoggEsteroIndSedeEst : "",
          SoggEsteroCodStato : "",
          AbilitatoPEPPOL : "",
          GruppoRiferimento :"",
          RagSoc1 : "",
          RagSoc2 : "",
          Presso : "",
          Indirizzo : "",
          Indirizzo2 : "",
          Cap : "",
          Localita : "",
          Localita2 : "",
          Provincia : "",
          CodStato : "IT",
          NumTel :"",
          NumTel2 : "",
          NumFax : "",
          IndirEmail : "",
          CodCliFor : 0,
          CodUfficioPA : "",
          LuogoDiConsegna : "",
          IndirEmailPECSDI :"",
          detailShipping:{
            Presso:"",
            Indirizzo2 :"",
            Localita2 :"",
            Cap :"",
            Provincia :"",
            CodStato :"IT",
            IndirEmail :"",
            NumTel2 :"",
            indirizzoDiSpedizione:'ragsoc1_shipping',
          },
          
        },
        addressList:[],
        country: 'IT',
        region: '',
        label:{},
        shippingAddressSetting:'ragsoc1_shipping',
        shippingAddressShow:true,
        show: true,
        cod_cli:{},
        checkUserdataList:[],
        check_code:'',
        detail_user:{},
        formCheckList:[],
        errorList:'',
        classErr:'',
        checkFormData:false,
        dataFormTxt : {
          email: "Email di fatturazione",
          PartitaIva: "Partita Iva",
          CodFiscale: "Codice fiscale",
          TipoSoggetto: "Tipo soggetto",
          PersonaCognome: "Cognome",
          PersonaNome: "Nome",
          PersonaComuneNascita: "Comune di nascita",
          PersonaProvNascita: "Provincia di nascita",
          PersonaStatoNascita: "Stato di nascita",
          PersonaDataNascita: "Data di nascita",
          RagSoc1: "Nome azienda",
          RagSoc2: "",
          Presso: "Presso (nome destinatario)",
          Indirizzo: "Indirizzo",
          Indirizzo2: "",
          Cap: "Cap",
          Localita: "Città",
          Localita2: "",
          Provincia: "Provincia",
          CodStato: "Stato",
          NumTel: "Numero di telefono",
          IndirEmail: "Email",
          CodUfficioPA: "Codice univoco SDI",
          IndirEmailPECSDI: "P.E.C.",
        },
        campiObbligatoriPF : [
          "email",
          "CodFiscale",
          "TipoSoggetto",
          "PersonaCognome",
          "PersonaNome",
          "PersonaComuneNascita",
          "PersonaProvNascita",
          "PersonaStatoNascita",
          "PersonaDataNascita",
          "Indirizzo",
          "Cap",
          "Localita",
          "Provincia",
          "CodStato",
          "NumTel",
          "IndirEmail",
        ],
        campiObbligatoriAltro : [
          "email",
          "PartitaIva",
          "TipoSoggetto",
          "RagSoc1",
          "Indirizzo",
          "Cap",
          "Localita",
          "Provincia",
          "CodStato",
          "NumTel",
          "IndirEmail",
          "CodUfficioPA",
          "IndirEmailPECSDI",
        ]
      }
    },
    mounted(){
     
      this.getDetailUsrdata()
    },
    computed:{
     ...mapGetters(["getTrl","getCountries","getUser"]),

      getMaxYear(){
        this.max_year = moment().format('YYYY-MM-DD')
      },
      personaNome() { 
        if (this.form.PersonaNome){
          if(this.form.PersonaNome.length > 3 ){
            this.$emit('setParentUserDataForm')
           
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
           
            this.formCheckList.push("PersonaNome")
            return false
          }
        } else {
          this.formCheckList.push("PersonaNome")
           this.$emit('setNullParentUserDataForm')
          return false
        }
        
      },
      personaCognome() {
        if (this.form.PersonaCognome){   
          if(this.form.PersonaCognome.length > 3 ){
           
            this.$emit('setParentUserDataForm')
            return true
          } else {
            
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          
          this.$emit('setNullParentUserDataForm')
          return false
        }
      },
      personaDataNascita() {
        if (this.form.PersonaDataNascita){
          let date = moment(this.form.PersonaDataNascita).unix()
          let min = moment().subtract(90,'years').unix()
          let max = moment().subtract(16,'years').unix()
          if (date >= min && date <= max){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          } 

        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }
           
      },
      personaComuneNascita() {
        if (this.form.PersonaComuneNascita) {
          if(this.form.PersonaComuneNascita.length > 2 ){
            
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }
            
      },      
      codiceFiscale() {
        if (this.form.CodFiscale) {
         if (this.form.CodFiscale.length!=''){
          return true
         }
        } else {
          return false
        }
        
      }, 
      partitaIva() {

        if (this.form.PartitaIva) {
         if (this.form.PartitaIva.length!=''){
          return true
         }
        } else {
          return false
        }

      }, 
      ragSoc1() {
        if( parseInt(this.form.TipoSoggetto) != 3){
          
        if(this.form.RagSoc1.length > 4 ){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }
      },
      indirizzo() {    
        if (this.form.Indirizzo) {
          if(this.form.Indirizzo.length>5){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }
      },
      localita() {
        if (this.form.Localita) {
          if(this.form.Localita.length>2){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }        
      },
      cap() {
        if (this.form.Cap) {
          if(this.form.Cap.length==5){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }     
      },
      provincia() {
        if (this.form.Provincia) {
          if(this.form.Provincia.length>1){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }            
      },
      indirEmail() {
        return this.validateEmail(this.form.IndirEmail)
      },
      numTel() {
        if (this.form.NumTel) {
          if(this.form.NumTel.length>2){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }   
      },
      codUfficioPA() {
        if (this.form.CodUfficioPA) {
          if(this.form.CodUfficioPA.length>2){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }   
      },
      presso() {
        if (this.form.detailShipping.Presso) {
          if(this.form.detailShipping.Presso.length>2){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }   
      },
      indirizzo2() {
        if (this.form.detailShipping.Indirizzo2) {
          if(this.form.detailShipping.Indirizzo2.length>2){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }             
      },
      localita2() {
        if (this.form.detailShipping.Localita2) {
          if(this.form.detailShipping.Localita2.length>2){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }      
      },
      cap2() {
        if (this.form.detailShipping.Cap) {
          if(this.form.detailShipping.Cap.length==5){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }      
      },
      detailShippingProvincia() {
        if (this.form.detailShipping.Provincia) {
          if(this.form.detailShipping.Provincia.length==2){
            this.$emit('setParentUserDataForm')
            return true
          } else {
            this.$emit('setNullParentUserDataForm')
            return false
          }
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }     
      },
      
    },
    methods: {
    ...mapActions(["logout","updateToken"]),
      hasProp(obj,prop){
        if(obj){
          return obj.hasOwnProperty(prop);
        } else {
          return false
        } 
      },
      updateTK(){
        this.updateToken()
      },
      checkTipologia(){
        alert(typeof this.form.TipoSoggetto)
        return true
      },
      tipologiaCliente(){
        
        if (this.form.TipoSoggetto != '' ) {
         
          if (parseInt(this.form.TipoSoggetto) > 0){
            document.getElementById('TipoSoggetto').setAttribute('style','border:1px solid #eee;border-radius:4px')
            return true
          } else {
            document.getElementById('TipoSoggetto').setAttribute('style','border:1px solid red;border-radius:4px')
            return false
          }
        } else {
          document.getElementById('TipoSoggetto').setAttribute('style','border:1px solid red;border-radius:4px')
          return false
        }

      },

      async getDetailUsrdata() {
          //this.updateTK()
          let tk 
          if (this.getUser.stsTokenManager){
            tk = this.getUser.stsTokenManager.accessToken
          }
          
          if (this.cookies.get("tk")){
            tk = this.cookies.get("tk")
          }
          let uid 
          if (this.getUser.uid){
            uid =  this.getUser.uid
          }          
          if (this.cookies.get("uid")){
            uid = this.cookies.get("uid")
          }
          if (tk)
          {

            try {
              let url = `${serverConfig.EP}/customers/check-user/${uid}`;
              axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
              axios.get(url).then((response) => {
                this.detail_user = response.data.userdata
                this.form = response.data.userdata

                if (this.form.detailShipping.hasOwnProperty('Presso')==false)
                {
                  this.form.detailShipping.Presso = ""
                }

                if (response.data.userdata.PersonaDataNascita!=''){
                  this.form.PersonaDataNascita = moment(response.data.userdata.PersonaDataNascita).format('YYYY-MM-DD')
                }
                if (this.form.CodCliFor !==0 || this.form.CodCliFor!==null){
                  this.getCodCli()
                }
                if (this.form.CodCliFor !==0 || this.form.CodCliFor!==null){
                  this.getAddressList(this.form.CodCliFor)
                }
                

              }).catch(error => {
                  
                  if(error) {
                    if (error.response.status == 401 )
                    {
                      this.$swal(objLang.swlSessionExp[$cookies.get('lang')]).then(() => {
                        this.logout()
                      })    
                    } else {
                      this.$swal(objLang.swlSomethingError[$cookies.get('lang')])
                    }
                  } 


              });
            } catch(error) {
              this.$swal(objLang.swlSomethingError[$cookies.get('lang')])

            }
          } else {
            if (this.lang){
              this.$swal(objLang.swlSessionExp[this.lang]).then(() => {
                this.logout()
              })                
            }
          }

        },
      onSubmit(event) {
        event.preventDefault()   
        this.updateTK()     
        let tk 
        if (this.getUser.stsTokenManager){
            tk = this.getUser.stsTokenManager.accessToken
        }
        if (this.cookies.get("tk")){
          tk = this.cookies.get("tk")
        }


        this.checkData()
        this.changeShippingAddress()
       
        if (this.tipologiaCliente() == true) {
         
          //if(this.validate==true){

            if (tk) {
              
              try {

                let url = `${serverConfig.EP}/customers/register`;
                axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
                axios.post(url, this.form ).then((response) => {

                  if (response.data) {
                    this.detail_user = response.data 
                    //this.getDetailUsrdata()
                    this.getCodCli();
                    location.reload();
                  }

                  //location.reload(); 
                }).catch(error => {
                    console.log(error)
                   this.$swal(objLang.swlSomethingError[$cookies.get('lang')])
                   console.log('onsub 5')
                });
              } catch (e) {
                
                console.log(e)
                this.$swal(objLang.swlSomethingError[$cookies.get('lang')])
              }

            }
          } else {
             this.$swal(objLang.swlSomethingError[$cookies.get('lang')])

          }

      },
      onSubmitUpdate() {
        this.getFormData(this.form)
        this.updateTK()     
        let tk 
        if (this.getUser.stsTokenManager){
            tk = this.getUser.stsTokenManager.accessToken
        }
        if (this.cookies.get("tk")){
          tk = this.cookies.get("tk")
        }


        if (this.tipologiaCliente() == true && this.checkFormData == true) {
          
            if (tk) {
              
              try {
                
                if (this.form.detailShipping.indirizzoDiSpedizione == 'ragsoc1_shipping'){
                  
                  
                  this.form.detailShipping.Indirizzo2 = this.form.Indirizzo
                  this.form.detailShipping.Localita2 = this.form.Localita
                  this.form.detailShipping.Cap = this.form.Cap
                  this.form.detailShipping.Provincia = this.form.Provincia
                  this.form.detailShipping.CodStato = this.form.CodStato
                  this.form.detailShipping.NumTel2 = this.form.NumTel
                  this.form.detailShipping.IndirEmail = this.form.IndirEmail

                  this.form.LuogoDiConsegna = 0
                } 
                

                let url = `${serverConfig.EP}/customers/register`;
                axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
                axios.post(url, this.form ).then((response) => {

                  if (response.data) {
                    this.detail_user = response.data 
                    
                    this.$swal(objLang.swlMsgDataUpdatedSuccess[$cookies.get('lang')])
                  
                    this.getAddressList(this.form.CodCliFor);
                   
                  }


                }).catch(error => {
                   
                   this.$swal(objLang.swlSomethingError[$cookies.get('lang')])
                  
                });
              } catch (e) {
                
                console.log(e)
                this.$swal(objLang.swlSomethingError[$cookies.get('lang')])
              }

            }
          } else {
             this.$swal(objLang.swlMandatoryFields[$cookies.get('lang')])

          }

      },
      getCodCli(){
        this.updateTK()
        let tk 
        if (this.getUser.stsTokenManager){
          tk = this.getUser.stsTokenManager.accessToken
        }
        if (this.cookies.get("tk")){
          tk = this.cookies.get("tk")
        }
        

        let code = ''
        if (this.form.CodFiscale.length > 0 && this.form.CodFiscale.length <=16) {
          code = this.form.CodFiscale
        }
        if (this.form.PartitaIva.length == 11) {
          code = this.form.PartitaIva
        }

        if (code!='') {
          if (this.form.CodCliFor == 0 || this.form.CodCliFor == null){

            if (tk) {
              let url = `${serverConfig.EP}/customers/customd-es/${code}`;
              axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
              axios.get(url).then((response) => {
                if (this.hasProp(response.data,'detail') == true){
                  this.cod_cli = response.data.detail.CodCliFor
                  this.form.CodCliFor = response.data.detail.CodCliFor
                  this.getAddressList(response.data.detail.CodCliFor)
                }
              }).catch(error => {
                if (error)
                {
                  this.$swal(objLang.swlSessionExp[$cookies.get('lang')]).then(() => {
                    this.logout()
                  })   
                }
              });            
            } else {
              this.$swal(objLang.swlSessionExp[$cookies.get('lang')]).then(() => {
                this.logout()
              })
            }    
          } 
        }
        
      },
      getAddressList(code){
        this.updateTK()
        let tk 
        if (this.getUser.stsTokenManager){
          tk = this.getUser.stsTokenManager.accessToken
        }
        if (this.cookies.get("tk")){
          tk = this.cookies.get("tk")
        }

        if (code!='') {
            if (tk) {
              let url = `${serverConfig.EP}/publicapi/indirizzi-spedizione/cliente/${code}`;
              axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
              axios.get(url).then((response) => {
                if(response.data) {
                  this.addressList = response.data
                }
              }).catch(error => {
                if (error)
                {
                  this.$swal(objLang.swlSessionExp[$cookies.get('lang')]).then(() => {
                    this.logout()
                  })   
                }
              });            
            } else {
              this.$swal(objLang.swlSessionExp[$cookies.get('lang')]).then(() => {
                this.logout()
              })
            }    
          
        }
        
      },
      checkData(){
        this.updateTK()
        let tk 
        if (this.getUser.stsTokenManager){
          tk = this.getUser.stsTokenManager.accessToken
        }
        if (this.cookies.get("tk")){
          tk = this.cookies.get("tk")
        }

        let code = ''
        if (this.form.CodFiscale.length > 0 && this.form.CodFiscale.length <=16) {
          code = this.form.CodFiscale
        }
        if (this.form.PartitaIva.length == 11) {
          code = this.form.PartitaIva
        }

        if (code!='') {
          if (this.form.CodCliFor == 0 || this.form.CodCliFor == null){

            if (tk) {
              let url = `${serverConfig.EP}/customers/get-userdata-from-es/${code}`;
              axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
              axios.get(url).then((response) => {
                if (response.data.status == 'data') {
                  this.validate = false
                  this.checkUserdataByCode(code)
                } else {
                  this.validate = true
                }
              }).catch(error => {
                if (error)
                {
                  this.$swal(objLang.swlSomethingError[$cookies.get('lang')]).then(() => {
                    
                  })   
                }
              });            
            } else {
              this.$swal(objLang.swlSomethingError[$cookies.get('lang')]).then(() => {
              
              })
            }    
          } 
        }
        
      },

      checkUserdataByCode(code){   
        this.updateTK()        
        if (code.length >= 11)
        {
         
          let tk 
          if (this.getUser.stsTokenManager){
            tk = this.getUser.stsTokenManager.accessToken
          }
          if (this.cookies.get("tk")){
            tk = this.cookies.get("tk")
          }



          if (tk) {
            let q = code.trim()
            this.check_code = q
            try {
              let url = `${serverConfig.EP}/customers/get-userdata-from-es/${q.toString()}`;
              axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
              axios.get(url).then((response) => {
                    this.checkUserdataList = response.data
                    if (response.data.status == 'data') {
                      this.swlDataImport()
                    }
              }).catch( error => {
                if (error.response.status == 401)
                {
                  this.$swal(this.trl.swlSessionExp[$cookies.get('lang')]).then(() => {
                    this.logout()
                  })  
                }

              })
            } catch {
              this.$swal(this.trl.swlSomethingError[$cookies.get('lang')])
            }

          }
        }

      },
      sendEmail(){
        let data = {
          "email":"giuseppe@neuralika.com"
        }
        axios.post(`${serverConfig.EP}/customers/welcome`, data )
          .then(response => {
           alert('email inviata!!!')
          })
          .catch(error => {
            alert('problema invio email')
        })        
      },
      swlDataImport() {
        this.updateTK()

        let tk 
        if (this.getUser.stsTokenManager){
          tk = this.getUser.stsTokenManager.accessToken
        }
        if (this.cookies.get("tk")){
          tk = this.cookies.get("tk")
        }

        if (tk) {
          this.$swal( {
            title: this.getTrl.t_check_res_title,
            text: this.getTrl.t_check_res_text,                      
            input: 'number',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: this.getTrl.t_confirm,
            cancelButtonText:this.getTrl.t_cancel,
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
              return axios.get(`${serverConfig.EP}/customers/check-and-save-userdata-from-es/${code}/${this.check_code}/${this.form.uid}`, {headers:{ Authorization: 'Bearer '+tk } } )
                      .then(response => {
                     
                        if (response.status !== 200) {
                          throw new Error(response.statusText)
                        }
                        this.form = response.data.results
                        if (this.form.detailShipping.hasOwnProperty('Presso')==false)
                        {
                          this.form.detailShipping.Presso = ""
                        }

                        return response.data
                      })
                      .catch(error => {
                        this.$swal.showValidationMessage(
                          `Request failed: ${error}`
                        )
                      })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
                }).then((result) => {

                if (result.isConfirmed) {
                
                  if (result.value.status == 'data') {
                    this.$swal({
                      title: this.getTrl.t_success_import_data_title,
                      icon: 'success',
                      timer:2000,
                    })

                      this.getDetailUsrdata()
                   
                  } else {
                    this.$swal({
                      title: this.getTrl.t_error_import_data_title,
                      icon: 'error',
                      timer:2000,
                    })                    
                  }

                  
                }
            } )     
        } else {
          this.$swal(this.getTrl.swlSessionExp[$cookies.get('lang')]).then(() => {
            this.logout()
          })  
        }           
      },
      validaCodiceFiscale(cf)
      {

          var validi, i, s, set1, set2, setpari, setdisp;
          if( cf == '' ) {
            this.$emit('setNullParentUserDataForm')
            return '';
          }
          cf = cf.toUpperCase();
          if( cf.length != 16 ){
            this.$emit('setNullParentUserDataForm')
            this.form.codiceFiscale = ''
            return false;
          }
              
          validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
          for( i = 0; i < 16; i++ ){
              if( validi.indexOf( cf.charAt(i) ) == -1 ){
                this.$emit('setNullParentUserDataForm')
                this.form.codiceFiscale = ''
                return false;
              }
                 
          }
          set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
          setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
          s = 0;
          for( i = 1; i <= 13; i += 2 )
              s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
          for( i = 0; i <= 14; i += 2 )
              s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
          if( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) ){
            this.$emit('setNullParentUserDataForm')
            this.form.codiceFiscale = ''
            
            return false;
          }
          this.$emit('setParentUserDataForm')   
          return true;
      },
      controllaCF(codfis) {
        if (codfis) {
          if (codfis!='')
          {
              if (this.validaCodiceFiscale(codfis) == false) {
                  document.getElementById('CodFiscale').setAttribute('style','border:1px solid red;border-radius:4px')
                  document.getElementById('errCodFisc').innerHTML = "<label style='color:red; font-size:10px; padding-bottom:5px'>"+this.getTrl.t_error_fiscal_code+"</label>";
                  this.$swal({
                    title: this.getTrl.t_mandatory_field_title,
                    text: this.getTrl.t_error_fiscal_code,
                    icon: 'danger',
                    timer:2500,
                  })

              } else {
                  document.getElementById('errCodFisc').innerHTML = ""
                  document.getElementById('CodFiscale').setAttribute('style','border:2px solid #eee;')

                  this.checkUserdataByCode(codfis)

              }
          } else {
              document.getElementById('errCodFisc').innerHTML = ""
              document.getElementById('CodFiscale').setAttribute('style','border:2px solid #eee;')
          }
        }
      },

      controllaPartitaIVA(pi) {
        //let pi = document.getElementById('PartitaIva').value
        if (pi){
          if (pi == '') {
            this.$swal({
              title: this.getTrl.t_error_msg,
              text: this.getTrl.t_error_vat_number,
              icon: 'error',
              timer:2000,
            })
          } 
          else if (!/^[0-9]{11}$/.test(pi))
          {
            this.$swal({
              title: this.getTrl.t_error_msg,
              text: this.getTrl.t_error_vat_number_text,
              icon: 'error',
              timer:2000,
            })
          } else {
            var s = 0;
            for (let i=0; i<=9; i+=2) {
              s += pi.charCodeAt(i) - '0'.charCodeAt(0);
            }
            for (let i=1; i<=9; i+=2 ){
              var c = 2*(pi.charCodeAt(i) - '0'.charCodeAt(0));
              if (c > 9) c = c - 9;
              s += c;
            }
            var controllo = (10 - s%10)%10;
            if (controllo != pi.charCodeAt(10) - '0'.charCodeAt(0)){
                document.getElementById('PartitaIva').setAttribute('style','border:2px solid #eee;')
            }
            this.checkUserdataByCode(pi)
          
          }
        }
      },
     checkPartitaIVA(pi) {
        if (pi == '') {
         return false
        } 
        else if (!/^[0-9]{11}$/.test(pi))
        {
          return false
        } else {
          return true
        }
      },      
      changeShippingAddress(){
        if (this.shippingAddressSetting == 'ragsoc2_shipping' && this.shippingAddressShow == true) {
          this.shippingAddressShow = true
        } 
        if (this.shippingAddressSetting == 'ragsoc2_shipping' && this.shippingAddressShow == false) {
          this.shippingAddressShow = true
        } 
        this.setShippingAddr()
      },
      setShippingAddr(){
        if (this.shippingAddressSetting == 'ragsoc1_shipping' || this.form.detailShipping.indirizzoDiSpedizione == 'ragsoc1_shipping'){
          this.form.detailShipping.Indirizzo2 = this.form.Indirizzo
          this.form.detailShipping.Localita2 = this.form.Localita
          this.form.detailShipping.Cap = this.form.Cap
          this.form.detailShipping.Provincia = this.form.Provincia
          this.form.detailShipping.CodStato = 'IT'
          this.form.detailShipping.NumTel2 = this.form.NumTel
          this.form.detailShipping.IndirEmail = this.form.IndirEmail
          this.form.detailShipping.indirizzoDiSpedizione = "ragsoc1_shipping"
          
        } else {
          
          this.form.detailShipping.Indirizzo2 = ""
          this.form.detailShipping.Localita2 = ""
          this.form.detailShipping.Cap = ""
          this.form.detailShipping.Provincia = ""
          this.form.detailShipping.CodStato = 'IT'
          this.form.detailShipping.NumTel2 = ""
          this.form.detailShipping.IndirEmail = ""
          this.form.detailShipping.indirizzoDiSpedizione = ""
        }
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      validateEmail(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          this.$emit('setParentUserDataForm')
          return true
        } else {
          this.$emit('setNullParentUserDataForm')
          return false
        }
      },
      validatePhoneNumber(phone){
        const phoneParse = parsePhoneNumber(phone)
        
        const isPossible = isPossiblePhoneNumber(phone)
            
        const isValid = isValidPhoneNumber(phone)
        
        const validatePhoneNumber = validatePhoneNumberLength(phone)
               
      },
      getFormData(obj){
        let mandatoryList = []
        if (parseInt(obj.TipoSoggetto)==3) {
          mandatoryList = this.campiObbligatoriPF
        } else {
          mandatoryList = this.campiObbligatoriAltro
        }

        let html = ''
        let self = this
        let n = 0
        for(var key in obj) {
          html += '<ul>'
          if(obj[key] === "" && mandatoryList.includes(key) == true) {
            n++;
            
            html += '<li> il campo <strong>'+ self.dataFormTxt[key]+'</strong> è obbligatorio </li>'
          }
          html += '</ul>'
        }
        if (n>0) { this.classErr = 'alert alert-danger'; this.checkFormData=false;   this.$emit('setNullParentUserDataForm') } else { this.checkFormData=true; this.classErr=''; this.$emit('setParentUserDataForm') }
        this.errorList = html
       

      }
  }    
};
</script>
<template>
  <div>
   <b-form @submit="onSubmit" id="userForm" @reset="onReset" v-if="show">
    <div class="row">
      <div class="col-lg-12 col-md-12">
       
        <div  :class="classErr" v-html="errorList"></div>
        

      </div>
      
    </div>
     <div class="row hidden-md-up">
        <template v-if="form.CodCliFor == 0 || form.CodCliFor == null">
         
          <label  class="alert alert-danger">
            <strong>Attezione:</strong> non hai ancora completato la procedura di registrazione. <strong>Completa il modulo sottostante per poter procedere con l'ordine</strong>.
          </label>                                                     
        </template>
      <div class="col-lg-6 col-md-12">
       
          <b-form-group id="input-group-2" :label="getTrl.t_type" label-for="input-2">
           
            <select 
              v-model="form.TipoSoggetto"
              id="TipoSoggetto"
              required
              @change="tipologiaCliente()"
              class="custom-field"
              style=""
            >
            <option value="0">{{getTrl.t_please_select_customer}}</option>
            <option value="1">{{getTrl.t_corporation}}</option>  
            <option value="2">{{getTrl.t_partnership}}</option>            
            <option value="3">{{getTrl.t_natural_person}}</option>            
            <option value="4">{{getTrl.t_other}}</option>            

          </select>

          </b-form-group>           
      </div>
      <div class="col-lg-6 col-md-12">
      <b-form-group id="input-group-2" :label="getTrl.t_clientcode" label-for="input-2" >
        <template v-if="form.CodCliFor != null || form.CodCliFor != 0 ">
          
            <strong class="pt-5 c-code-text">{{form.CodCliFor}}</strong>
          
        </template>
        <template v-else>
          <strong class="pt-5 c-code-text">-</strong>
        </template>
      </b-form-group>
      </div>
    </div>
  <template v-if="form.TipoSoggetto == '3'">
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12"></div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12">
        <b-form-group
          id="t_name"
          class="ct-code-block"
          :label="getTrl.t_name"
          label-for="input-1"
         
        >

          <b-form-input
            id="PersonaNome"
            :state="personaNome"
            v-model="form.PersonaNome"
            type="text"
            placeholder=""
            required
          ></b-form-input>

        </b-form-group>
      </div>
      
        <div class="col-lg-6 col-md-12">
        <b-form-group id="input-group-2" :label="getTrl.t_surname" label-for="input-2">
          <b-form-input
            id="PersonaCognome"
            :state="personaCognome"
            v-model="form.PersonaCognome"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12"></div>
    </div>
     <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12">
        <b-form-group
          id="t_dateOfBirth"
          class="ct-code-block"
          :label="getTrl.t_dateOfBirth"
          label-for="input-1"
        >
          <b-form-input
            id="t_dateOfBirth"
            v-model="form.PersonaDataNascita"
            :state="personaDataNascita"
            type="date"
            :min="min_year"
            :max="max_year"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-lg-6 col-md-12">
        <b-form-group id="input-group-2" :label="getTrl.t_cityofBirth" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.PersonaComuneNascita"
            :state="personaComuneNascita"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12"></div>
    </div>
     <b-row>
      <b-col>
        <b-form-group
          id="PersonaStatoNascita"
          class="ct-code-block"
          :label="getTrl.t_placeOfBirth"
          label-for="PersonaStatoNascita"
         
        >
          <country-select 
            v-model="form.PersonaStatoNascita" 
            :country="form.PersonaStatoNascita" 
            topCountry="IT"  
            placeholder="Seleziona Stato"  
            class="custom-field"/>

        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-group-2" :label="getTrl.t_provOfBirth" label-for="input-2">
          <b-form-input
            id="PersonaProvNascita"
            v-model="form.PersonaProvNascita"
            placeholder=""
            
            size="2"
            maxlength="2"
            class="half-custom-field" 
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </template>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12">
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12">
        <b-form-group
          
          class="ct-code-block"
          :label="getTrl.t_cf"
          label-for="CodFiscale" 
        >
        <div v-if="form.TipoSoggetto > 2" >
          <b-form-input
            id="CodFiscale"
            v-model="form.CodFiscale"
           
            type="text"
            placeholder=""
            @blur="controllaCF(form.CodFiscale)"
            min="16"
            maxlength="16"
            required
          ></b-form-input>
        </div>
        <div v-else> 
          <b-form-input
            id="CodFiscale"
            v-model="form.CodFiscale"
            type="text"
            placeholder=""
            
            @blur="controllaCF(form.CodFiscale)"
            min="16"
            maxlength="16"
          ></b-form-input>          
        </div>
        <div id="errCodFisc"></div>
        </b-form-group>
      </div>
      
      <div class="col-lg-6 col-md-12">
        <b-form-group id="PartitaIva" :label="getTrl.t_piva" label-for="PartitaIva">
          <div v-if="parseInt(form.TipoSoggetto) < 3">
            <b-form-input
              id="PartitaIva"
              v-model="form.PartitaIva"
              :state="partitaIva"
              placeholder=""
              @blur="controllaPartitaIVA(form.PartitaIva)"
              min="11"
              maxlength="11"
              required
            ></b-form-input>
          </div>
          <div v-else>
            <b-form-input
              id="PartitaIva"
              v-model="form.PartitaIva"
              placeholder=""
              @blur="controllaPartitaIVA(form.PartitaIva)"
              min="11"
              maxlength="11"
            ></b-form-input>            
          </div>

        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12">
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12">
        <b-form-group
          id="input-group-1"
          class="ct-code-block"
          :label="getTrl.t_corpName"
          label-for="input-1"
        >
        <template v-if="form.TipoSoggetto != '3'">
          <b-form-input
            id="RagSoc1"
            v-model="form.RagSoc1"
            type="text"
            :state="ragSoc1"
            placeholder=""
            required
          ></b-form-input>
        </template>
        <template v-else>
          <b-form-input
            id="RagSoc1"
            v-model="form.RagSoc1"
            type="text"
            placeholder=""
          ></b-form-input>
        </template>



        </b-form-group>
      </div>

      <div class="col-lg-6 col-md-12">
        <b-form-group id="input-group-2" :label="getTrl.t_billingAddress" label-for="input-2">
          <b-form-input
            id="Indirizzo"
            v-model="form.Indirizzo"
            placeholder=""
            required
            :state="indirizzo"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12">
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12">
        <b-form-group
          id="input-group-1"
          class="ct-code-block"
          :label="getTrl.t_billingCity"
          label-for="input-1"
         
        >
          <b-form-input
            id="input-1"
            v-model="form.Localita"
            :state="localita"
            type="text"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-lg-6 col-md-12">
        <b-form-group id="inputCap" :label="getTrl.t_billingCap" label-for="inputCap">
          <b-form-input
            id="Cap"
            v-model="form.Cap"
            :state="cap"
            placeholder=""
            required
            maxlength="7" 
            size="7"
            class="half-custom-field"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>    
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12">
      </div>
    </div>
    <div class="row hidden-md-up">      
      <div class="col-lg-6 col-md-12">        
        <b-form-group
          id="inputProvincia"
          class="ct-code-block"
          :label="getTrl.t_billingProv"
          label-for="inputProvincia"
         
        >
          <b-form-input
            id="Provincia"
            v-model="form.Provincia"
            :state="provincia"
            type="text"
            placeholder=""
            required
            maxlength="2" 
            size="2"
            class="half-custom-field"
          ></b-form-input>
        </b-form-group>
        </div>
      <div class="col-lg-6 col-md-12">
        <b-form-group id="CodStato" :label="getTrl.t_billingState" label-for="CodStato">
          <country-select v-model="form.CodStato" :country="form.CodStato" topCountry="IT"  placeholder="Seleziona Stato"  class="custom-field"/>
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12">
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12">
        <b-form-group
          id="IndirEmail"
          class="ct-code-block"
          :label="getTrl.t_billingEmail"
          label-for="IndirEmail"
         
        >
          <b-form-input
            id="input-1"
            v-model="form.IndirEmail"
            :state="indirEmail"
            type="email"
            required
          ></b-form-input>
        </b-form-group>
        </div>
        <div class="col-lg-6 col-md-12">
        <b-form-group
          id="t_billingPec"
          class="ct-code-block"
          :label="getTrl.t_billingPec"
          label-for="t_billingPec"
         
        >
          <b-form-input
            id="IndirEmailPECSDI"
            v-model="form.IndirEmailPECSDI"
            placeholder=""
            type="email"
            
          ></b-form-input>
        </b-form-group>
      </div>
      </div>

    <div class="row hidden-md-up">

      <div class="col-lg-6 col-md-12">
        <b-form-group id="input-group-2" :label="getTrl.t_billingCodeU" label-for="input-2">
          <template v-if="form.TipoSoggetto != '3'">
            <b-form-input
              id="CodUfficioPA"
              v-model="form.CodUfficioPA"
              :state="codUfficioPA"
              placeholder="" 
              required 
            ></b-form-input>
          </template>
          <template v-else>
            <b-form-input
              id="CodUfficioPA"
              v-model="form.CodUfficioPA"
              placeholder=""  
            ></b-form-input>            
          </template>


        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-8 col-md-12">
        <b-form-group id="input-group-2" :label="getTrl.t_billingPhone" label-for="input-2">
            <MazPhoneNumberInput
              v-model="form.NumTel"
              show-code-on-list
              
              :preferred-countries="['IT', 'DE','FR','US', 'GB','ES']"
              :ignored-countries="['AC']"
              :translations="getTrl.t_phone_numberInput"
          />
          
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12"></div>
    </div>


  <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 mt-2">
        <label class="alert alert-primary">
          *
          <i class="fa fa-truck pr-5" aria-hidden="true"></i>
          <img id="header-lang-img" src="@/assets/images/flags/it.svg" alt="Header Language" height="15" class="rounded mr-5" />

          <strong class="pl-5">Attenzione:</strong> in questo momento, effettuiamo <strong>spedizioni solo  in Italia</strong>.
        <br>Le <strong>consegne all'estero possono essere tuttavia pianificate</strong>, nel rispetto di determinate condizioni, contattando direttamente i nostri uffici all'indirizzo email:
        <a  id="a-not-login" href="mailto:lacasadelgrano@lacasadelgrano.com">lacasadelgrano@lacasadelgrano.com</a>  
        </label>
      </div>
  </div>
  <div class="row hidden-md-up">
    <div class="col-lg-12 col-md-12">
    </div>
  </div>  
   <div id="div_block-578-404" class="ct-div-block " v-if="shippingAddressShow == true">
      <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12">
      <div id="div_block-360-333" class="ct-div-block">
         <h1 id="headline-362-333" class="ct-headline">Luogo di consegna</h1>
         <div id="_rich_text-630-404" class="oxy-rich-text"></div>
      </div>
     </div>
      </div>
      <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 p-2">
       
          <b-form-group id="input-group-2" label="Indirizzo di consegna  *" label-for="input-2">  
            <span class="p-2"><b>seleziona 'Altro indirizzo...' per modificare la destinazione</b></span>
            <b-form-select 
            v-model="form.detailShipping.indirizzoDiSpedizione"
            required
            @change="changeShippingAddress()"
            class="custom-field"
          >
            <option value="ragsoc1_shipping">{{getTrl.t_same_billing_address}}</option>
            <option value="ragsoc2_shipping">{{getTrl.t_other_shipping_address}}</option>            
          </b-form-select>
        
          </b-form-group>           
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 ml-5 pr-2 pl-5">
        <b-form-group id="input-group-2" label="Presso (nome destinatario)" label-for="input-2">
          <template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
           
            <div v-if="parseInt(form.TipoSoggetto) !== 3">
              {{ form.RagSoc1 }}
            </div>
            <div v-else>
              {{ form.PersonaNome }} {{ form.PersonaCognome }}
            </div>
          
          </template>
          <template v-else>
           
            <b-form-input
            id="detailShippingPresso"
            v-model="form.detailShipping.Presso"
            :state="presso"
            placeholder=""
            required
          ></b-form-input>
          </template>
          
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 ml-5 ">
        <b-form-group id="input-group-2" :label="getTrl.t_shippingAddress" label-for="input-2">
          <template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
            <strong>{{form.Indirizzo}}</strong>
          </template>
          <template v-else>
            <b-form-input
            id="detailShippingIndirizzo2"
            v-model="form.detailShipping.Indirizzo2"
            :state="indirizzo2"
            placeholder=""
            required
          ></b-form-input>
          </template>
          
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 ">
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12 p-2">
        <b-form-group
          id="input-group-1"
          class="ct-code-block"
          :label="getTrl.t_shippingCity"
          label-for="input-1"
         
        ><template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
            <strong>{{form.Localita}}</strong>
          </template>
          <template v-else>
          <b-form-input
            id="detailShippingLocalita2"
            v-model="form.detailShipping.Localita2"
            :state="localita2"
            type="text"
            placeholder=""
            required
          ></b-form-input>
          </template>
        </b-form-group>
      </div>
      <div class="col-lg-6 col-md-12 p-2">
        <b-form-group id="shippingCap" :label="getTrl.t_shippingCap" label-for="shippingCap">
          <template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
            <strong>{{form.Cap}}</strong>
          </template>
          <template v-else>
            <b-form-input
              id="detailShippingCap"
              v-model="form.detailShipping.Cap"
              :state="cap2"
              placeholder=""
              required
              maxlength="7" 
              size="7"
              class="half-custom-field"
            ></b-form-input>
          </template>

        </b-form-group>
    </div>
    </div>   
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 p-2">
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12 p-2">
        <b-form-group
          id="input-group-1"
          class="ct-code-block"
          :label="getTrl.t_shippingProv"
          label-for="input-1"
         
        ><template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
            <strong>{{form.Provincia}}</strong>
          </template>
          <template v-else>
            <b-form-input
              id="detailShippingProvincia"
              v-model="form.detailShipping.Provincia"
              :state="detailShippingProvincia"
              type="text"
              placeholder=""
              required
              maxlength="2" 
              size="2"
              class="half-custom-field"
            ></b-form-input>
          </template>
        </b-form-group>
        </div>
      <div class="col-lg-6 col-md-12 p-2">
        <b-form-group id="detailShippingCodStato" :label="getTrl.t_shippingState" label-for="detailShippingCodStato">
          <template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
            <strong>{{form.CodStato}}</strong>
          </template>
          <template v-else>
          <select v-model="form.detailShipping.CodStato" class="custom-field">
            <option  v-for="item in getCountries" :key="item" :value="item.iso">{{item.name}}</option>
          </select>
          </template>
        </b-form-group>
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12">
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-6 col-md-12 p-2">

        <b-form-group
          id="input-group-1"
          class="ct-code-block"
          :label="getTrl.t_shippingEmail"
          label-for="input-1"
         
        >
        <template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
            <strong>{{form.IndirEmail}}</strong>
          </template>
          <template v-else>
          <b-form-input
            id="detailShippingIndirEmail"
            v-model="form.detailShipping.IndirEmail"
            type="email"
            
          ></b-form-input>
          </template>
        </b-form-group>
        </div>
      </div>
      <div class="row hidden-md-up">
      <div class="col-lg-8 col-md-12 p-2">
        <b-form-group id="input-group-2" :label="getTrl.t_shippingPhone" label-for="input-2">
          <template v-if="form.detailShipping.indirizzoDiSpedizione=='ragsoc1_shipping'">
            <strong>{{form.NumTel}}</strong>
          </template>
          <template v-else>         
            <MazPhoneNumberInput
              v-model="form.detailShipping.NumTel2"
              show-code-on-list
              
              :preferred-countries="['IT', 'DE','FR', 'US', 'GB','ES']"
             
              :translations="getTrl.t_phone_numberInput"
          />          
          </template>
        </b-form-group>
      </div>
    </div>
   </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 p-2">
       
      </div>
    </div>
    <div class="row hidden-md-up">
      <div class="col-lg-12 col-md-12 pt-50 text-center">
        <template v-if="form.CodCliFor == 0 || form.CodCliFor == null">
          <button type="submit" id="link_button-25-67" class="ct-link-button mainBtn">{{getTrl.t_btn_register}}</button>                                                      
        </template>
        <template v-else>
          <div  :class="classErr" v-html="errorList"></div>
          <button type="button" @click="onSubmitUpdate()" id="link_button-25-67" class="ct-link-button mainBtn">{{getTrl.t_btn_update}}</button>                                         
        </template> 
        <br>
      </div>
    </div>
    <div class="row hidden-md-up">
      
    </div>


    </b-form>
  </div>
</template>
<style>
.card .product-image {
  height: 300px;
}
</style>
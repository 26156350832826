<script>
import { mapActions, mapGetters } from "vuex";
import serverConfig   from '@/_helpers/config';
import InputWidget from '@/components/InputWidget'
import axios from "axios"
import { useCookies } from "vue3-cookies";
import { objLang }   from '@/_helpers/lang';  
//import 'maz-ui/css/main.css'
import Carousel from '@/components/Carousel'
import moment from 'moment';

export default {
  name: 'Cart',
  setup(){
    const { cookies } = useCookies();
    return { cookies };
  },
  components:{
    InputWidget,
    Carousel,
  },
  data(){
    return {
      current_date_tmp: moment().unix(),
      current_date: moment().format('LLL'),
      counter:1,
      summary:{},
      trl:objLang,
      lang:'ita',
      weight:0.00,
      procToPay:false,
      coupon_code:'',
    }
  },
  computed: {
     ...mapGetters(["getUser","getCart","getCartItems","getDetCart","getTrl","getWeight"]),
  },
  created(){
    this.getSummaryCart()
    if (  $cookies.get("lang") ){
      this.lang = $cookies.get("lang") 
    }
  },

  methods:{
    ...mapActions(["setUpdateCart", "removeItem","addToCart","getDetailsCart",]),
    hasProp(obj,prop){
      if(obj){
        return obj.hasOwnProperty(prop);
      } else {
        return false
      } 
    },
    checkDiscount(now,start,end) {
      if (now >= start && now <= end){
        return true
      } else {
        return false
      }
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    decrement(qta){
      console.log(qta)
    },
    increment(){
      console.log(qta)
    },
    changeAddCounter(qta_ord,num,prod_id,data){

      let idQta = document.getElementById(`qta_${prod_id}`).value
      
      let qtADD = ++idQta
      if (qtADD>0){
        document.getElementById(`qta_${prod_id}`).value =  qtADD
        data['qta'] = qtADD
      } else {
        document.getElementById(`qta_${prod_id}`).value =  1
        data['qta'] = 1
      }
      this.addProduct(data)
      this.calcW()
    },
    changeSubCounter(qta_ord,num,prod_id,data){
      let idQta = document.getElementById(`qta_${prod_id}`).value
      let qtADD = --idQta
      if (qtADD>0){
        document.getElementById(`qta_${prod_id}`).value =  qtADD
        data['qta'] = qtADD
      } else {
        document.getElementById(`qta_${prod_id}`).value =  1
        data['qta'] = qtADD
      }
      this.addProduct(data)
      this.calcW()
    },
    addProduct(data){
      let self = this
      let cookies_oh = $cookies.get("oh")
      let cookies_puid = $cookies.get("puid")
      
      if(!$cookies.get("oh")){
        data['preorder']=0;
      }else{
        data['preorder']=cookies_oh;
      }
      if(!$cookies.get("puid")){
        data['puid']=0;
      }else{
        data['puid']=cookies_puid;
      }
      if ( this.hasProp(this.getUser,'uid') == true )
      {
        data['usr'] = this.getUser.uid
      }

      this.addToCart(data)
      
      setTimeout(function(){
        self.getResCart()
      },500)

      setTimeout(function(){
        self.getSummaryCart()
      },800)      

    }, 
    getResCart(){
      this.$root.$root.getDCart()
    },
    async deleteItem(id){
      this.$swal( 
        this.trl.swlDeleteConfirm[this.lang]
        ).then((result) => {
         
          if (result.isConfirmed) {
            axios.delete(`${serverConfig.EP}/orders/delete-item/${id}`).then((response) => {
               
                if (response.status==200) {
                  if ( response.data.removeParent != null) {
                    $cookies.remove("oh")
                    $cookies.remove("puid")
                  }

                }

                this.getResCart()
                
              }).catch(error => {
                this.trl.swlSomethingError[this.lang]
                
              });

          }
        }
      )

      //this.removeItem(id)
    },
    renderPriceVat(price, vat) {
      return this.formatPrice(price*vat)
    },
    updateCart(){
      this.getSummaryCart() 
    },
     getSummaryCart() {
      if ($cookies.get("oh") && $cookies.get("puid")) {
        axios.get(`${serverConfig.EP}/orders/summary-cart/${ $cookies.get("oh")}/${$cookies.get("puid")}`).then((response) => {
          if (response.data) {
             let self = this
             this.summary = response.data
             if(response.data.order_header){
              
              this.weight = response.data.order_header.MagPesoNettoUnit
             }
             this.setUpdateCart(this.summary)
             

          }

        }).catch(error => {
          console.log(error);
        });
      }
    },
    checkCoupon(){
      if (this.coupon_code.length == 8){
        this.applyCoupon()
       
      }else {
        /*
          $('#ErrorModal').modal('show');  
          document.getElementById('ErrorModalTitle').innerHTML = "Messaggio di errore";
          document.getElementById('ErrorModalMsg').innerHTML = "Devi applicare un codice valido composto da 8 caratteri alfanumerici.";
        */
      }

    },
    async applyCoupon(){
 
      if ($cookies.get("oh") && $cookies.get("puid")) 
      {            
        axios.get(`${serverConfig.EP}/orders/check-coupon/${ $cookies.get("oh")}/${$cookies.get("puid")}/${this.coupon_code}`)
          .then(response => {
            
            this.$swal({
              title: response.data.title,
              text: response.data.message,
              icon:"question",
              showCancelButton:false,
              showConfirmButton:false,
              timer:2000,
            })

          })
          .catch(function (error) {                        
            this.$swal({
              title: "Messaggio di errore",
              text:"Ops, si è verificato un errore nella procedura di applicazione codice Coupon. Riprova un'altra volta, per favore. Se dovessi riscontrare ancora problemi, contatta il nostro servizio clienti, grazie.",
              icon:"error",
              showCancelButton:false,
              showConfirmButton:false,
              timer:2000,
            })          
          
          });                         

      }  
    },
    discountPrice(discount_price, price){
      return this.formatPrice(price - ((discount_price*price)/100))
    },
    calcW(){
      let w = 0
      for (let item of this.getDetCart.orders){
        w+=item.detail_order.qta

      }  
    },

 }

};
</script>
<template>
 <div class="wrapper">
    <div class="page_container"></div>
    <div class="container mt-20 ">
      <div id="div_block-360-333" class="ct-div-block">
        <div id="headline-51-12">{{getTrl.t_cart}}</div>
      </div>
    </div>
    <div class="container ">
      <div class="row ">
        <div class="col-lg-6 col-md-12">
        <template v-if="getDetCart">
          <template v-if="getDetCart.cestiInCart.length == 0">
            <div v-if="getWeight<=25">
              <b-alert show variant="primary">{{ getTrl.t_cart_min_kg }}<a href="#" class="alert-link">2,5 kg</a>. {{ getTrl.t_current_kg }}<a href="#" class="alert-link">{{ getWeight}} kg</a></b-alert>            
            </div>
            <div v-else>
              <b-alert show variant="warning">{{ getTrl.t_cart_max_kg }}<a href="#" class="alert-link">25 kg</a>. {{ getTrl.t_current_kg }}<a href="#" class="alert-link">{{ getWeight }} kg</a></b-alert>
            </div>
          </template>
        </template>



        </div>
      </div>
    </div>
    <template v-if="hasProp(getDetCart,'order_header') == true">
     <div class="container mt-20 " >
      <div class="row pHeader" >
        <div class="col-lg-2 col-md-12">
        </div>
        <div class="col-lg-5 col-md-12 text-center">
          <strong>{{getTrl.t_product}}</strong>
        </div>
        <div class="col-lg-1 col-md-12 text-center">
          <strong>{{getTrl.t_price}}</strong>
        </div>
        <div class="col-lg-2 col-md-12 text-center">
          <strong>{{getTrl.t_quantity}}</strong>
        </div>                    
        <div class="col-lg-2 col-md-12">
          <strong>{{getTrl.t_subtotal}}</strong>
        </div>  
      </div>
    <template v-if="getDetCart">
      <div class="row pBody" v-for="item in getDetCart.orders"  :key="item.detail_order._id">
        <div class="col-lg-2 col-md-12">
              <div  class="div-block-cart">
                <a :href="'/'+ item.detail_order.descriptions.ita.slug">
                  <img loading="eager" class="ct-image image-box-cart" :alt="item.detail_order.descriptions.ita.title" :src="item.detail_order.image" />
                </a>
              </div>
        </div>
        <div class="col-lg-5 col-md-12">
          <div  class="div-block-cart">
            <a :href="'/'+item.detail_order.descriptions.ita.slug">
              <h3 class="card-product-name-b" v-html="item.detail_order.descriptions.ita.title"></h3>
            </a>
          </div>
        </div>
        <div class="col-lg-1 col-md-12">
          <div  class="div-block-cart price_box">
           
            <template v-if="item.detail_order.discount_perc>0">
              <span class="current_price pr-5">{{ discountPrice(item.detail_order.discount_perc,item.detail_order.price) }} €</span>
             
            </template>
            <template v-else>
              <span class="current_price">{{ formatPrice(item.detail_order.price) }} €</span>
            </template>
          </div>
        </div>
        <div class="col-lg-2 col-md-12">
          <div  class="ct-div-block div-block-cart">
              <div class="m-input-number maz-align-center m-input-number--md" color="primary">

                <button no-shadow="" @click="changeSubCounter(item.detail_order.qta,1, item.detail_order.id,{
                          'id':item.detail_order.id,
                          'gcode': item.detail_order.gcode ,
                          'qta': parseFloat(item.detail_order.qta),
                          'size':'',
                          'colors':'',
                          'usr':'None'
                        })" class="m-input-number__button m-input-number__decrement-button m-btn --md --transparent --cursor-pointer --is-button m-input-number__button m-input-number__decrement-button" type="button" data-v-ecee7cd4=""><span class="maz-flex maz-flex-center" data-v-ecee7cd4=""><svg width="1em" height="1em" aria-hidden="true" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-input-number__button__icon" style="font-size: 1.5rem;" data-v-ecee7cd4-s="">  <path xmlns="http://www.w3.org/2000/svg" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path></svg></span></button>
                  <div class="m-input m-input-number__input maz-flex-1 --primary --md" data-v-e949cff8=""><div class="m-input-wrapper maz-border --default-border maz-rounded-lg" data-v-e949cff8=""><div class="m-input-wrapper-input" data-v-e949cff8="">
                    <input :id="'qta_' +  item.detail_order.id" type="number" name="input" v-model="item.detail_order.qta" class="m-input-number__input maz-flex-1 m-input-input" min="1" max="1000" step="1" @blur="addProduct({
                          'id':item.detail_order.id,
                          'gcode': item.detail_order.gcode ,
                          'qta': parseFloat(item.detail_order.qta),
                          'size':'',
                          'colors':'',
                          'usr':'None'
                        })">
                    </div></div>
                  </div>
                  <button 
                      no-shadow="" 
                      class="m-input-number__button m-input-number__increment-button m-btn --md --transparent --cursor-pointer --is-button m-input-number__button m-input-number__increment-button" 
                      type="button" 
                      data-v-ecee7cd4=""
                      @click="changeAddCounter(item.detail_order.qta,1,item.detail_order.id,{
                          'id':item.detail_order.id,
                          'gcode': item.detail_order.gcode ,
                          'qta': parseFloat(item.detail_order.qta),
                          'size':'',
                          'colors':'',
                          'usr':'None'
                        }
                        )"
                      >
                    
                      <span class="maz-flex maz-flex-center" data-v-ecee7cd4="">
                        <svg width="1em" height="1em" aria-hidden="true" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-input-number__button__icon" style="font-size: 1.5rem;" data-v-ecee7cd4-s="">  <path xmlns="http://www.w3.org/2000/svg" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path></svg></span>
                  </button>

              </div>
          </div>          
        </div>
        <div class="col-lg-1 col-md-12">
              <div class="ct-div-block div-block-cart">
                {{ formatPrice(item.detail_order.total_price) }} €
              </div>
        </div>
        <div class="col-lg-1 col-md-12">
          <div  class="ct-div-block div-block-cart">
            <a  class="ct-link-button remove-prod" href="javascript:void(0)" @click="deleteItem(item._id)" target="_self">X</a>
          </div>
        </div>    
      </div>
    </template>
      <div class="row pFooter">
        <div class="col-lg-2 col-md-12">
          <div id="" class="ct-div-block text-center">
          <template v-if="hasProp(getDetCart,'order_header') == true " >
           <!-- <div v-if="getDetCart.order_header.cod_coupon == '' || order_header.cod_coupon != 'none' ">
              <input 
                type="text" 
                class="form-control" 
                :placeholder="getTrl.t_coupon" 
                :aria-label="getTrl.t_apply_coupon" 
                maxlength="8"  
                v-model="coupon_code"
                >
            </div>
            <div v-else>
                <div class="text-center">
                    <h4>
                        <i class="zmdi zmdi-card-giftcard zmdi-hc-2x mdc-text-red-700"></i>
                        [[coupon_code]]
                    </h4>
                </div>
            </div>    -->                             
          </template>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 pb-2">
          <!--<div id="" class="ct-div-block text-center mt-2" v-if="hasProp(getDetCart,'order_header') == true" >
            <a  class="ct-link-button mainBtn" href="javascript:void(0)" @click="checkCoupon()" target="_self" >
              {{getTrl.t_apply_coupon}}
            </a>
          </div>-->
        </div>
        <div class="col-lg-7 col-md-12">

        </div>  
      </div>
      <div class="row mb-10">
        <div class="col-lg-7 col-md-12">
        </div>
        <div class="col-lg-5 col-md-12">
        <template v-if="getDetCart">
          <div class="row mb-10 mt-10">
            <div class="col-lg-10 col-md-12 mt-20  text-center ct-div-block">
              <h3 class="oxy-rich-text t_total_cart">{{getTrl.t_total_cart}}</h3>
            </div>
          </div>
          <template v-if="hasProp(getDetCart,'order_header') == true"> 
          <div class="row  pHeader">
            <div class="col-lg-5 col-md-12">
              {{getTrl.t_subtotal}}
            </div>
            <div class="col-lg-5 col-md-12 col-tot-p">
              {{formatPrice(getDetCart.order_header.total_price_excl_vat)}} €
            </div>  
          </div>
          <div class="row pBody">
            <div class="col-lg-5 col-md-12">
              {{getTrl.t_shipping_cost}}
            </div>
            <div class="col-lg-5 col-md-12 col-tot-p">
              {{formatPrice(getDetCart.order_header.shipping_cost_excl_iva)}} €
            </div>  
          </div>
          <div class="row pBody">
            <div class="col-lg-5 col-md-12">
              {{getTrl.t_iva}}
            </div>
            <div class="col-lg-5 col-md-12 col-tot-p">
              {{formatPrice(getDetCart.order_header.iva)}} €
            </div>  
          </div>
          <div class="row pFooter">
            <div class="col-lg-5 col-md-12">
              <div class="ct-div-block">
                  <strong>{{getTrl.t_total}}</strong>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-tot-p">
              <strong>{{formatPrice(getDetCart.order_header.total_price)}} €</strong>
            </div>  
          </div>
          </template>
        </template>
          <div class="row  mt-20 ">
           
            <div class="col-lg-12 col-md-12" v-if="getWeight>=2.5 && getWeight<=25 || getDetCart.cestiInCart.length>0">
              
              <div id="" class="ct-div-block text-center">
                <a  class="ct-link-button mainBtn" href="/cart/checkout/" target="_self">{{getTrl.t_proceed_to_checkout}}</a>
              </div>
            </div>
            <div class="col-lg-12 col-md-12" v-else>
              <div id="" class="ct-div-block text-center">
                <template v-if="getWeight<=25">
                  <b-alert show variant="primary">{{ getTrl.t_cart_min_kg }}<a href="#" class="alert-link">2,5 kg</a>. <br>{{ getTrl.t_current_kg }}<a href="#" class="alert-link">{{ getWeight }} kg</a></b-alert>            
                </template>
                <template>
                  <b-alert show variant="warning">{{ getTrl.t_cart_max_kg }}<a href="#" class="alert-link">25 kg</a>. <br>{{ getTrl.t_current_kg }}<a href="#" class="alert-link">{{ getWeight }} kg</a></b-alert>
                </template>
              </div>
            </div>      
          </div>
        </div>  
      </div>
      <div class="row  mt-20  mb-20">
        <div class="col-lg-6 col-md-12">
        </div>
      </div>
    </div>
    </template>
    <template v-else>
     <div class="container mt-20 " >
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-20 mb-20">
          <b-alert show variant="primary">
            {{getTrl.t_cart_empty}}
          </b-alert>
        </div> 
      </div>
      </div>  
      <div class="container mt-20">
      </div>    
    </template>

  </div>
</template>
<style>
input {
  
  font-size: 1rem!important;
  margin-bottom: 0rem!important;
 
}
</style>

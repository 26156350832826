<template>
  <div>
    <vueper-slides :dragging-distance="50">
    <vueper-slide
      v-for="(slide, i) in slides.slides"
        :key="i"
        :image="getPath(slide.path)"
        title=""
        content=""
        link="" 
      />
    </vueper-slides>
 
  </div>


</template>

<script>
import { defineComponent } from 'vue'
import serverConfig   from '@/_helpers/config';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default defineComponent({
  name: 'Slideshow',
  components: {
    VueperSlides, VueperSlide, 
  },
  props:['slides'],
  data() {
    return {
      slide:0,
      sliding:null,
      slidesTest: [
      {
        title: 'Slide #1',
        content: 'Slide 1 content.'
      },
      {
        title: 'Slide #2',
        content: 'Slide 2 content.'
      }
    ]
    }
  },
  methods:{
    getPath(path){
      if(path){
        return `${serverConfig.EP}/${path}`
      }
    },
    checkIndex(val){
      console.log(typeof(val))
      if (val){
        
        if (val==0){
          console.log('zero')
          console.log(val)
          return 'carousel-item active'
        } else {
          console.log('non zero')
          console.log(val)
          return 'carousel-item'
        }
      }

    },
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
  }

})
</script>

<style>
  .carousel-item {
  position: relative;
  display: inline!important;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;

}

.carousel__item {
  min-height: 100px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 1px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>

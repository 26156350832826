<script>
import serverConfig   from '@/_helpers/config';
import objLang   from '@/_helpers/lang';

import { loadScript } from '@paypal/paypal-js';
import { useCookies } from "vue3-cookies";
import axios from "axios"
import {  mapActions } from "vuex";

export default {
  name: 'PayPal',
  beforeCreate: function() {
    loadScript({ 'client-id': CLIENT_ID, currency: "EUR"  }).then((paypal) => {
      paypal
        .Buttons({
          createOrder: this.createOrder,
          onApprove: this.onApprove,
        })
        .render('#paypal-button-container');
    });
  },
  setup(){
    const { cookies } = useCookies();
    return { cookies };
   
  },  
  data() {
    return {
      ep:serverConfig.EP,
      paid: false,
      paidResponse:false,
      trl: objLang,
      lang: this.cookies.get("lang")
      //cartTotal:0.20,
    };
  },

  props:['amount','cartTotal'],

  methods: {
    ...mapActions(["logout","swlAlert"]),

    createOrder: function(data, actions) {
      console.log('Creating order...');
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: this.cartTotal,

            },
            
          },
        ],
      });
    },
    onApprove: function(data, actions) {
      console.log('Order approved...');
      return actions.order.capture().then((response) => {
        this.paid = true;
        this.paidResponse = response
       
        this.confirmPayment()
        
        
      });
    },
    confirmPayment(){
        //const ep = 'https://api.lacasadelgrano.com/v1'
        let tk = this.cookies.get("tk")
        let oh = this.cookies.get("oh")
   
        if ($cookies.get('tk') && $cookies.get('oh')) {

          let url = `${serverConfig.EP}/orders/confirm-payment/${$cookies.get('oh')}`;
          axios.defaults.headers.common['authorization'] = `Bearer ${$cookies.get('tk')}`

          axios.put(url,  this.paidResponse ).then((response) => {
             
                this.$swal(this.trl.objLang.swlPaySuccess[this.cookies.get('lang')])
              let self = this
              
              setTimeout(function(){
                self.sendOrderToEs($cookies.get('oh'))
              }, 6000)
                

          }).catch(error => {
             
              if (error) {
                 this.$swal(this.trl.objLang.swlSomethingError[this.cookies.get('lang')])
              }
          
          });            
        } else {
          this.$swal(this.trl.objLang.swlSomethingError[this.cookies.get('lang')])
        }          
    },

    sendOrderToEs(oh){
       // const ep = 'https://api.lacasadelgrano.com/v1'
        let tk = this.cookies.get("tk")
       
        if ($cookies.get('tk')) {

          let url = `${serverConfig.EP}/orders/send-order-to-es/${$cookies.get('oh')}`;
          axios.defaults.headers.common['authorization'] = `Bearer ${$cookies.get('tk')}`
          axios.get(url).then((response) => {
            if(response){
              this.sendMsg()
            }
            this.$swal(this.trl.objLang.swlOrderCompleted[this.cookies.get('lang')])

            this.$router.push(`/cart/checkout-done/${$cookies.get('puid')}`)

          }).catch(error => {
       

            if (error.response.status == 401)
            {
              this.$swal( this.trl.objLang.swlSessionExp[this.cookies.get('lang')] ).then(() => {
                this.logout()
              })   
            } else {
                this.$swal(this.trl.objLang.swlNetworkError[this.cookies.get('lang')])
            }

          });            
        } else {
            this.$swal( this.trl.objLang.swlSessionExp[this.cookies.get('lang')] ).then(() => {
                this.logout()
            })   
        }          
    },
    sendMsg(oh){
           
        if ($cookies.get('tk')) {

          let url = `${serverConfig.EP}/orders/send-email/${$cookies.get('oh')}`;
          axios.defaults.headers.common['authorization'] = `Bearer ${$cookies.get('tk')}`
          axios.get(url).then((response) => {
            console.log("response")
              console.log(response)
          }).catch(error => {
            console.log(error)
          });            
        } else {
            this.$swal( this.trl.objLang.swlSessionExp[this.cookies.get('lang')] ).then(() => {
                this.logout()
            })   
        }          
    },

    testbutton(){
     
        this.$swal(this.trl.objLang.swlNetworkError[this.cookies.get('lang')])
        
    }
    
  },
};


const CLIENT_ID = serverConfig.PAYPAL_CLIENT_ID;
</script>
<template>  
<div>  
<div id="confirmation">
  
</div>
  <div v-if="!paid" id="paypal-button-container"></div>
  <div v-else id="confirmation">
   <b-alert variant="success" show>{{trl.objLang.swlPaySuccess[lang].text}}</b-alert>
  </div>
 
</div>
</template>
<style>
#paypal-button-container {
  margin: 30px 0;
}
#confirmation {
  color: green;
  margin-top: 1em;
  font-size: 1.2em;
  font-weight: 700;
}
</style>
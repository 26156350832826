<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { objLang, t_trl }   from '@/_helpers/lang';  
import Carousel from '@/components/Carousel'
import { mapActions, mapGetters } from "vuex";
import getFunct   from '@/_helpers/gfunct';
import axios from 'axios'

import 'maz-ui/css/main.css'
export default {
	setup () {
		const login_form = ref({});
		const register_form = ref({});
		const store = useStore();
		
		const login = () => {
			
			if (getFunct.validateEmail(login_form.value.email) == true ){
				store.dispatch('login', login_form.value);	
				this.$gtag.event('login', { method: 'Login Shop' })			
			}
			
		}

		const register = () => {
			

			if (getFunct.validateEmail(register_form.value.email) == true ){
				store.dispatch('register', register_form.value);
			}
		}

		return {
			login_form,
			register_form,
			login,
			register,
			spinner_register:false,
			spinner_login:false,
			password: null,
	    	password_length: 0,
			contains_eight_characters: false,
	    	contains_number: false,
	    	contains_uppercase: false,
			contains_special_character: false,
	    	valid_password: false
		}
	},
	components:{
		Carousel
	},
  	computed: {
     ...mapGetters(["getTrl"]),
  	},
  	methods:{

    checkPassword(password) {
      this.password_length = this.password.length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}
			
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
		this.contains_special_character = format.test(this.password);
      
      if (this.contains_eight_characters === true &&
					this.contains_special_character === true &&
					this.contains_uppercase === true &&
					this.contains_number === true) {
						this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    },

  	}
}
</script>
<template>
<div class="wrapper">
    <!-- <Carousel :items="[]"  :title="getTrl.t_log_or_reg" image="https://beta.lacasadelgrano.com/wp-content/uploads/2022/06/wheat-bkg-scaled.jpg"/>-->
    <div class="page_container "></div>
    <div class="container mt-50 ">
      <div id="div_block-360-333" class="ct-div-block">
        <div id="headline-51-12"></div>
      </div>
    </div>
   <div class="container mb-50">
      <div class="row forms mb-50">
		<div class="col-lg-6 col-md-12">
			<form class="register" @submit.prevent="register">
				<h2 v-html="getTrl.t_txt_register" class="mb-50"></h2>
          		<b-form-group label="Email" >
				<div ></div>
				  <MazInput
				    v-model="register_form.email"
				    placeholder="E-mail"
				    autocomplete="new-email"
				    class="maz-mb-3"
				    clearable
				    left-icon-name="comment"
				    type="email"
				  />		
		  		</b-form-group>
		    	<b-form-group label="Password" >
				  <MazInput
				    v-model="register_form.password"
				    placeholder="Password"
				    type="password"
				    autocomplete="new-password"
				    left-icon-name="lock"
				    clearable
				  />
				</b-form-group>
		        <b-form-group class="mt-10">
					<input 
					type="submit" 
					:value="getTrl.t_txt_register" />
				</b-form-group>
			</form>
		</div>
		<div class="col-lg-6 col-md-12">
			<form class="login" @submit.prevent="login">
				<h2 v-html="getTrl.t_txt_login" class="mb-50"></h2>
          		<b-form-group label="Email" >
				  <MazInput
				    v-model="login_form.email"
				    placeholder="E-mail"
				    autocomplete="new-email"
				    class="maz-mb-3"
				    clearable
				    left-icon-name="comment"
				    type="email"
				  />
				</b-form-group>
				<b-form-group label="Password" >
				  <MazInput
				    v-model="login_form.password"
				    placeholder="Password"
				    type="password"
				    autocomplete="new-password"
				    left-icon-name="lock"
				    clearable
				  />
				</b-form-group>
          		<b-form-group class="mt-10">
					<template v-if="spinner_login">
						<MazSpinner
                        	size="50"
                      	/>
					</template>
					<template v-else>
						<input 
							type="submit" 
							:value="getTrl.t_txt_login" 
						/>
					</template>

				</b-form-group>
				<b-form-group>
					<a href="/account/password/reset">	{{getTrl.t_txt_forget_psw}}</a>
				</b-form-group>
			</form>
		</div>		
	  </div>

</div>
</div>
</template>
<style>
.forms {
	display: flex;
	min-height: 50vh;
}
.forms > input {
	text-align: center;
}
form {
	flex: 1 1 0%;
	padding: 8rem 1rem 1rem;
}

form.register {
	color: #FFF;
	background-color:#640e19;
	background-image: linear-gradient(
		to bottom right,
		#640e19,
		#8c0013
	);
	border-radius:0.5rem;
}

h2 {
	font-size: 3.5rem;
	text-transform: capitalize;
	font-weight: 800;
	margin-bottom: 2rem;
}

input {
	appearance: none;
	border: none;
	outline: none;
	background: none;

	display: block;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	font-size: 1.5rem;
	margin-bottom: 2rem;
	padding: 0.5rem 0rem;
}

input:not([type="submit"]) {
	opacity: 0.8;
	transition: 0.4s;
}

input:focus:not([type="submit"]) {
	opacity: 1;
}

input::placeholder {
	color: inherit;
}

form.register input:not([type="submit"]) {
	color: #000;
	border-bottom: 2px solid #FFF;
	text-align: left;
}

form.login input:not([type="submit"]) {
	text-align: left;
}

form.login input[type="submit"] {
	background-color:#640e19;
	color: #FFF;
	font-weight: 700;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	cursor: pointer;
	text-transform: uppercase;
}

form.register input[type="submit"] {
	background-color: #FFF;
	color: #640e19;
	font-weight: 700;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	cursor: pointer;
	text-transform: uppercase;
}
</style>
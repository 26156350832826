import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
//import { createI18n } from 'vue-i18n'
import VueCookies from 'vue-cookies'

import vueCountryRegionSelect from 'vue3-country-region-select'

import BootstrapVue3 from 'bootstrap-vue-3'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import MazInputNumber from 'maz-ui/components/MazInputNumber'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

import MazSelect from 'maz-ui/components/MazSelect'
import MazCheckbox from 'maz-ui/components/MazCheckbox'
import MazSpinner from 'maz-ui/components/MazSpinner'
import "@fontsource/roboto"; 
//import 'maz-ui/css/main.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './assets/css/styles.css'
import './assets/css/styles_up.css'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'
import VueEasyLightbox from 'vue-easy-lightbox'
import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(VueCookieComply)

app.use(VueCookies,{ expires: '1d',secure:true})
.use(BootstrapVue3)
.use(store)
.use(router)
.use(BootstrapIconsPlugin)
.use(VueSweetalert2)
.use(vueCountryRegionSelect)
.use(VueEasyLightbox)
.use(VueGtag, {
    appName: 'Shop La Casa del Grano',
    pageTrackerScreenviewEnabled: true,
    config: { id: "GTM-T6HVP97M" }
  })
.component('MazBtn', MazBtn)
.component('MazInput', MazInput)
.component('MazInputNumber',MazInputNumber)
.component('MazPhoneNumberInput', MazPhoneNumberInput)
.component('MazSelect', MazSelect)
.component('MazSpinner', MazSpinner)
.component('MazCheckbox', MazCheckbox)
.component('EasyDataTable', Vue3EasyDataTable)
.mount('#app')

<script>
import serverConfig   from '@/_helpers/config';
import { objLang, t_trl }   from '@/_helpers/lang';  
import UserForm from "@/components/form/UserForm";
import {  mapActions, mapGetters } from "vuex";
import axios from "axios"
import Carousel from '@/components/Carousel'

import { useCookies } from "vue3-cookies";
import PayPal from "@/components/PayPal";
import 'maz-ui/css/main.css'

import { reactive} from 'vue'
import { ref } from 'vue'
// reactive state
const state = reactive({
  currentCounterStatus: '',
})


export default {
  name: 'CheckoutDone',
  components:{
   UserForm,
   PayPal,
   Carousel,
  },
  data() {
    return {
      ep:serverConfig.EP,
      trl: objLang,
      mp:'',
      detail_user:{},
      uid:this.$store.state.user,
      checkUserData:null,
      numberOne:1,
      form:{
        customer_note:'',
      },
      message:null,
    }
  },
  setup(){
    const { cookies } = useCookies();
    const numberTwo = ref(2);
    let cookieLang = cookies.get('lang')
    let language = ref(t_trl[cookieLang])
    //const setLang = () => {

    //}
    return { cookies, numberTwo, language };
   
  },
  computed: {
     ...mapGetters(["getUser","getUsrData","getCart","getCartItems","getDetCart","getTrl"]),
    remove(){
      $cookies.remove('oh')
    },    
  },
  mounted(){ 
    this.getMessage() 
  },
  methods:{
    ...mapActions(["getUserdata","tkRefresh","logout"]),
    getMessage(){
      this.message = this.trl.swlOrderCompleted[$cookies.get('lang')]
      $cookies.remove('oh')
    
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  }
};
</script>
<template>
 <div class="wrapper">
   <div class="page_container mt-50"></div>
   <div class="container mt-50 box-message">
    <div class="row ">
      <div class="col-lg-5 col-md-12"></div>
      <div class="col-lg-2 col-md-12" >
       <div class="box-icon ">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 117.72 117.72" style="enable-background:new 0 0 117.72 117.72" xml:space="preserve"><g><path class="st0" d="M58.86,0c9.13,0,17.77,2.08,25.49,5.79c-3.16,2.5-6.09,4.9-8.82,7.21c-5.2-1.89-10.81-2.92-16.66-2.92 c-13.47,0-25.67,5.46-34.49,14.29c-8.83,8.83-14.29,21.02-14.29,34.49c0,13.47,5.46,25.66,14.29,34.49 c8.83,8.83,21.02,14.29,34.49,14.29s25.67-5.46,34.49-14.29c8.83-8.83,14.29-21.02,14.29-34.49c0-3.2-0.31-6.34-0.9-9.37 c2.53-3.3,5.12-6.59,7.77-9.85c2.08,6.02,3.21,12.49,3.21,19.22c0,16.25-6.59,30.97-17.24,41.62 c-10.65,10.65-25.37,17.24-41.62,17.24c-16.25,0-30.97-6.59-41.62-17.24C6.59,89.83,0,75.11,0,58.86 c0-16.25,6.59-30.97,17.24-41.62S42.61,0,58.86,0L58.86,0z M31.44,49.19L45.8,49l1.07,0.28c2.9,1.67,5.63,3.58,8.18,5.74 c1.84,1.56,3.6,3.26,5.27,5.1c5.15-8.29,10.64-15.9,16.44-22.9c6.35-7.67,13.09-14.63,20.17-20.98l1.4-0.54H114l-3.16,3.51 C101.13,30,92.32,41.15,84.36,52.65C76.4,64.16,69.28,76.04,62.95,88.27l-1.97,3.8l-1.81-3.87c-3.34-7.17-7.34-13.75-12.11-19.63 c-4.77-5.88-10.32-11.1-16.79-15.54L31.44,49.19L31.44,49.19z"/></g></svg>
        </div>
      </div>
      <div class="col-lg-5 col-md-12"></div>
      
    </div>
    <div class="row mb-5 ">
      <div class="col-lg-2 col-md-12"></div>
      <div class="col-lg-8 col-md-12" >
          <div class="mb-5 mt-50 text-center">
              <h1 class="h3 font-weight-medium" v-html="getTrl.t_checkout_done"></h1>
              
              <p v-if="message" >{{message.text}}</p>
            </div>
            <div class="text-center">
              <div class="ct-div-block text-center">
                <a class="link-main-btn ct-link-button" href="/" target="_self">{{getTrl.t_continue_shopping}}</a>
              </div>              
            </div>
      </div>
      <div class="col-lg-2 col-md-12"></div>
    
    </div>
   </div>
  </div>
</template>
<style type="text/css">
  .st0{fill:#01A601;}
</style>

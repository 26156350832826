import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDffuEI27LHkPPK8Pm8dzCqNxd04FXrhuo",
  authDomain: "lacasadelgrano-4ed70.firebaseapp.com",
  projectId: "lacasadelgrano-4ed70",
  storageBucket: "lacasadelgrano-4ed70.appspot.com",
  messagingSenderId: "1015914454301",
  appId: "1:1015914454301:web:ab3f44fe925c8438bf15f4",
  measurementId: "G-8BD71GNCTP"
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { auth }
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import PasswordReset from '../views/PasswordReset.vue'

import PasswordChange from '../views/PasswordChange.vue'
import { auth } from '@/firebase'
import Product from '../views/Product.vue';
import Products from '../views/Products.vue';

import Cart from '../views/Cart.vue';
import Checkout from '../views/Checkout.vue';

import CheckoutDone from '../views/CheckoutDone.vue';

import CheckoutError from '../views/CheckoutError.vue';

import { event } from 'vue-gtag'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title: 'Shop online prodotti tipici sardi | La Casa del Grano',
      description: 'Alla ricerca di uno shop online di prodotti tipici sardi? Scopri il sito de La Casa del Grano e ordina i nostri prodotti di alta qualità',
      content: 'Alla ricerca di uno shop online di prodotti tipici sardi? Scopri il sito de La Casa del Grano e ordina i nostri prodotti di alta qualità',

      metaTags: [
        {
          name: 'description',
          content: 'Alla ricerca di uno shop online di prodotti tipici sardi? Scopri il sito de La Casa del Grano e ordina i nostri prodotti di alta qualità.'
        },
        {
          property: 'keywords',
          content: 'shop, sardegna, prodotti, tipici, sardi, Casa, del, Grano, ordina, alta, qualità.'
        }
      ]
    }   
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
   
  },
  {
    path: '/:slug',
    name: 'Product',
    component: Product,
   
  },
  {
      path: '/cart/detail/',
      name: 'Cart',
      component: Cart,
      meta:{
        title: 'La Casa Del Grano Shop',
        metaTags: [
          {
            name: 'description',
            content: 'The home page of our example app.'
          },
          {
            property: 'og:description',
            content: 'The home page of our example app.'
          }
        ]
      }
  },
  {
      path: '/cart/checkout/',
      name: 'Checkout',
      component: Checkout,
      meta:{
        title: 'La Casa Del Grano Shop',
        metaTags: [
          {
            name: 'description',
            content: 'The home page of our example app.'
          },
          {
            property: 'og:description',
            content: 'The home page of our example app.'
          }
        ]
      }
  }, 
  {
      path: '/cart/checkout-done/:id',
      name: 'CheckoutDone',
      component: CheckoutDone,
  }, 
  {
      path: '/cart/checkout-error/:id',
      name: 'CheckoutError',
      component: CheckoutError,
  }, 
   {
    path: '/account/profile/',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  }, 
  {
    path: '/account/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/account/password/reset',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/account/password/change',
    name: 'PasswordChange',
    component: PasswordChange
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? to.meta.title : 'La Casa Del Grano Shop';

  let nextPage = '/'
  if(  to.query.hasOwnProperty('next') ) {
    nextPage = to.query.next
  } 
  

  if (to.path === '/account/login' && auth) {
    next(nextPage)
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !auth) {
    next('/account/login/')
    return;
  }



  next();
})

export default router
<script>
import serverConfig   from '@/_helpers/config';
import { objLang, t_trl }   from '@/_helpers/lang';  
import UserForm from "@/components/form/UserForm";
import {  mapActions, mapGetters } from "vuex";
import axios from "axios"
import Carousel from '@/components/Carousel'

import { useCookies } from "vue3-cookies";
import PayPal from "@/components/PayPal";
import 'maz-ui/css/main.css'

import { reactive} from 'vue'
import { ref } from 'vue'
// reactive state
const state = reactive({
  currentCounterStatus: '',
})


export default {
  name: 'CheckoutError',
  components:{
   UserForm,
   PayPal,
   Carousel,
  },
  data() {
    return {
      ep:serverConfig.EP,
      trl: objLang,
      mp:'',
      detail_user:{},
      uid:this.$store.state.user,
      checkUserData:null,
      numberOne:1,
      form:{
        customer_note:'',
      }
    }
  },
  setup(){
    const { cookies } = useCookies();
    const numberTwo = ref(2);
    let cookieLang = cookies.get('lang')
    let language = ref(t_trl[cookieLang])
    //const setLang = () => {

    //}
    return { cookies, numberTwo, language };
   
  },
  computed: {
     ...mapGetters(["getUser","getUsrData","getCart","getCartItems","getDetCart","getTrl"]),
    
  },
   mounted(){ 

  },
  methods:{
    ...mapActions(["getUserdata","tkRefresh","logout"]),

    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },


  }
};
</script>
<template>
 <div class="wrapper">
   <div class="page_container mt-50"></div>
   <div class="container mt-50 ">
    <div class="row">
      <div class="col-lg-4 col-md-12"></div>
      <div class="col-lg-4 col-md-12">
       <div class="box-icon">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="122.879px" height="122.879px" viewBox="0 0 122.879 122.879" enable-background="new 0 0 122.879 122.879" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" fill="#FF4141" d="M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z"/></g></svg>
        </div>
      </div>
      <div class="col-lg-4 col-md-12"></div>
      
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-12"></div>
      <div class="col-lg-8 col-md-12">
          <div class="mb-5 mt-50">
              <h1 class="h3 font-weight-medium" v-html="getTrl.t_checkout_done"></h1>
              <p>Thank you for your order! Your order is being processed and will be completed within 3-6 hours. You will receive an email confirmation when your order is completed.</p>
            </div>
            <a class="btn btn-primary btn-pill transition-3d-hover px-5" href="/">Continue Shopping</a>
      </div>
      <div class="col-lg-2 col-md-12"></div>
      
    </div>
   </div>
  </div>
</template>
<style type="text/css">
  .st0{fill:#01A601;}
</style>

<script>
import serverConfig   from '@/_helpers/config';
import { objLang, t_trl }   from '@/_helpers/lang';  
import UserForm from "@/components/form/UserForm";
import {  mapActions, mapGetters } from "vuex";
import axios from "axios"
import Carousel from '@/components/Carousel'

import { useCookies } from "vue3-cookies";
import PayPal from "@/components/PayPal";
import 'maz-ui/css/main.css'

import { reactive} from 'vue'
import { ref } from 'vue'
// reactive state
const state = reactive({
  currentCounterStatus: '',
})


export default {
  name: 'Checkout',
  components:{
   UserForm,
   PayPal,
   Carousel,
  },
  data() {
    return {
      ep:serverConfig.EP,
      trl: objLang,
      mp:'',
      detail_user:{},
      uid:this.$store.state.user,
      checkUserData:null,
      numberOne:1,
      form:{
        gift_note:'',
        customer_note:'',
      },
      order_note:{
        gift_note:"",
        customer_note:"",
      }
    }
  },
  setup(){
    const { cookies } = useCookies();
    const numberTwo = ref(2);
    let cookieLang = cookies.get('lang')
    let language = ref(t_trl[cookieLang])
    //const setLang = () => {

    //}
    return { cookies, numberTwo, language };
   
  },
  computed: {
     ...mapGetters(["getUser","getUsrData","getCart","getCartItems","getDetCart","getTrl"]),
    
  },
   mounted(){ 
    this.checkUserDataByOder()

  },
  methods:{
    ...mapActions(["getUserdata","tkRefresh","logout","getDetailsCart"]),

    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    checkNote(){
      if (this.getDetCart){
        this.order_note = {
          gift_note: this.getDetCart.order_header.gift_note,
          customer_note: this.getDetCart.order_header.customer_note,
        }
      }

    },
    checkUserDataByOder(){
    
        let tk = this.cookies.get("tk")
        let oh = this.cookies.get("oh")
        
        if ($cookies.get("tk") && $cookies.get('oh')) {
         try {

              let url = `${serverConfig.EP}/orders/check-order-data-user/${$cookies.get('oh')}`;
              axios.defaults.headers.common['authorization'] = `Bearer ${tk}`
              axios.get(url).then((response) => {
                
                this.checkUserData = response.data.order_updated
                this.checkNote()

              }).catch(error => {
                console.log(error)
                /*
                  if (error)
                  {
                  this.$swal(this.trl.swlSessionExp[this.cookies.get('lang')]).then(() => {
                      this.logout()
                  })  
                  }
                */
              });

         } catch (e){
            
              this.$swal(this.trl.swlSomethingError[this.cookies.get('lang')]).then(() => {
                //this.logout()
              })            
         } 
        } 
            
    },
    updateOrderNote() {
       let oh = this.cookies.get("oh")
       let tk = this.cookies.get("tk")
       if ( $cookies.get('tk') && $cookies.get('oh')) {
          try {
            this.order_note = {
              gift_note: this.getDetCart.order_header.gift_note,
              customer_note: this.getDetCart.order_header.customer_note,
            }
            let url = `${serverConfig.EP}/orders/update-order-detail/${$cookies.get('oh')}`;
            axios.defaults.headers.common['authorization'] = `Bearer ${tk}`

            axios.put(url,this.order_note ).then(() => {
               this.getDetailsCart()
            }).catch(error => {
              
              if (error.response.status == 401)
              {
                this.$swal(this.trl.swlSomethingError[this.cookies.get('lang')]).then(() => {
                 // this.logout()
                })   
              }

            }); 
          } catch {
            
            this.$swal(this.trl.swlSomethingError[this.cookies.get('lang')]).then(() => {
               
              })  
          }

           
        } else {
            this.$swal( this.trl.swlSessionExp[this.cookies.get('lang')] ).then(() => {
               // this.logout()
            }) 
        }          
    },
    setNullUserDataForm(){
      this.checkUserData = null
    },
    setUserDataForm(){
      this.checkUserData = true
    }
  }
};
</script>
<template>
 <div class="wrapper">
   <div class="page_container"></div>
   <div class="container mt-50 ">
      <div class="row">
         <div class="col-lg-6 col-md-12">
       
          <template v-if="getUser">
                 
            <div  class="ct-div-block">
              <div id="headline-56-12" class="">{{getTrl.t_billing}}</div> 
            </div>
            <UserForm type="checkout" @setNullParentUserDataForm="setNullUserDataForm"  @setParentUserDataForm=setUserDataForm />
          </template>
          <template v-else>
            <div id="div_block-624-404" class="ct-div-block">
              <div id="headline-56-12">{{getTrl.t_log_or_reg}}</div>
            </div>            
            <b-alert show variant="primary" class="mt-50">
                {{getTrl.t_mustreg}}
                <a id="a-not-login" href='/account/login/?next=/cart/checkout/' >
                 {{getTrl.t_log_or_reg}}
                </a>
            </b-alert>

          </template>
            
         </div>
         <div class="col-lg-6 col-md-12" v-if="getDetCart">
            <!-- Col b -->
            <div id="div_block-624-404" class="ct-div-block">
              <div id="headline-56-12">{{getTrl.t_cart}}</div>
            </div>
            <div class="row hidden-md-up pHeader mt-50">
              <div class="col-lg-6 col-md-12">
               <strong>{{getTrl.t_product}}</strong> 
              </div>
              <div class="col-lg-2 col-md-12">
                <strong>{{getTrl.t_price}}</strong>
              </div>
              <div class="col-lg-2 col-md-12">
                <strong>{{getTrl.t_quantity}}</strong>
              </div>
              <div class="col-lg-2 col-md-12">
               <strong>{{getTrl.t_subtotal}}</strong>
              </div>
            </div> 
            <div class="row hidden-md-up pBody"  v-for="item in getDetCart.orders" :key="item.detail_order._id">
              <div class="col-lg-6 col-md-12">
                <a :href="'/'+ item.detail_order.descriptions.ita.slug">
                  <img loading="eager" class="ct-image image-box-summary" :alt="item.detail_order.descriptions.ita.title" :src="item.detail_order.image" />
                </a>
                <a :href="'/'+item.detail_order.descriptions.ita.slug">
                  <h1 id="headline-6-67" class="ct-headline" v-html="item.detail_order.descriptions.ita.title"></h1>
                </a>
              </div>
              <div class="col-lg-2 col-md-12">
                {{formatPrice(item.detail_order.price_vat)}} €
              </div>
              <div class="col-lg-2 col-md-12">
               {{item.detail_order.qta}}
              </div>
              <div class="col-lg-2 col-md-12">
                {{formatPrice(item.detail_order.total_price) }} €
              </div>
            </div>
           <div class="row mt-5 pBody">
            <div class="col-lg-5 col-md-12">
              {{getTrl.t_subtotal}}
            </div> 
            <div class="col-lg-4 col-md-12 col-tot-p">
            </div>  
            <div class="col-lg-3 col-md-12 col-tot-p">
              {{formatPrice(getDetCart.order_header.total_price_excl_vat)}} €
            </div>
          </div>
          <div class="row pBody">
            <div class="col-lg-5 col-md-12">
              {{getTrl.t_shipping_cost}}
            </div>
            <div class="col-lg-4 col-md-12 col-tot-p">
            </div>  
            <div class="col-lg-3 col-md-12 col-tot-p">
              {{formatPrice(getDetCart.order_header.shipping_cost_excl_iva)}} €
            </div>
          </div>
          <div class="row pBody">
            <div class="col-lg-5 col-md-12">
              {{getTrl.t_iva}}
            </div> 
            <div class="col-lg-4 col-md-12 col-tot-p">
            </div>  
            <div class="col-lg-3 col-md-12 col-tot-p">
              {{formatPrice(getDetCart.order_header.iva)}} €
            </div>
          </div>
          <div class="row pFooter">
            <div class="col-lg-5 col-md-12">
              <div class="ct-div-block">
                  <strong>{{getTrl.t_total}}</strong>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-tot-p">
            </div>  
            <div class="col-lg-3 col-md-12 col-tot-p">
              <strong>{{formatPrice(getDetCart.order_header.total_price)}} €</strong>
            </div> 
          </div> 
          <!-- GIFT NOTE -->
            <div class="row hidden-md-up" v-if="getDetCart.order_header.total_price">
              <div class="col-lg-12 mt-20 col-md-12">
                 <template v-if="getUser">
                  <b-form-group
                    id="input-gift_note"
                    class="ct-code-block gift-message-box"
                    label-for="input-gift_note"
                  >
                  <b-icon-gift-fill></b-icon-gift-fill>
                  <label class="pb-2 pl-20">{{getTrl.t_gift_note}}</label>
                  
                      <b-form-textarea
                        id="input-gift_note"
                        v-model="getDetCart.order_header.gift_note"
                        rows="3"
                        max-rows="6"
                        @blur="updateOrderNote()"
                      ></b-form-textarea>
                    
                  </b-form-group>
                </template>
              </div>
            </div>
          <!-- / GIFT NOTE -->
            <div class="row hidden-md-up" v-if="getDetCart.order_header.total_price">
              <div class="col-lg-12 col-md-12">
                 <template v-if="getUser">
                 
                  <b-form-group
                    id="input-customer_note"
                    class="ct-code-block"
                    label-for="input-customer_note"
                  >
                    <b-form>
                       <label class="pb-2">{{getTrl.t_order_note}}</label>
                      <b-form-textarea
                        id="input-customer_note"
                        v-model="getDetCart.order_header.customer_note"
                       
                        rows="3"
                        max-rows="6"
                        @blur="updateOrderNote()"
                      ></b-form-textarea>
                    </b-form>
                  </b-form-group>
                </template>
              </div>
            </div>
            <div class="row hidden-md-up" v-if="getDetCart.order_header.total_price">
              <div class="col-lg-12 col-md-12 mt-10 mb-10">
               
                <template v-if="checkUserData!==null && getDetCart.order_header.detailBilling.CodCliFor>0 " >
                  <PayPal :amount="getDetCart.order_header.total_price" :cartTotal="getDetCart.order_header.total_price"/>
                </template>
                <template v-else>

                  <b-alert show variant="primary">
                    {{getTrl.t_not_complete_data}}
                  </b-alert>
                </template>
                

              </div>
            </div>
            <!-- / Col b -->
         </div>
      </div>
      <b-row>
         <b-col>
        
         </b-col>
         <b-col>
            
         </b-col>   
      </b-row>

   </div>
  </div>
</template>
<style >
.form{
  padding: 2.5rem 1rem 1rem !important;
}
</style>


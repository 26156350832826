<template>
    <div id="div_block-24-67" class="ct-div-block">
        <a  class="ct-link-button link_button-27-67" href="javascript:void(0)" @click="changeCounter('1', id)" target="_self">+</a>
            <div  class="ct-text-block text_block-26-67">
                <input type="number" :id="id" :value="counter"  class="text_block-input" @blur="getQTA">
            </div>
        <a  class="ct-link-button link_button-29-67" href="javascript:void(0)" @click="changeCounter('-1', id)" target="_self">–</a>
    </div>          
</template>

<script>
import { mapActions, mapGetters } from "vuex";
     
export default {
  name: 'InputWidget',
  props:['code','qta','id','productID'],
  data(){
    return {
      counter:this.qta
    }
  },
  computed: {
	...mapGetters(["getCart"]),
  },
  methods:{
	...mapActions(["addToCart","getDetailsCart"]),
    editQta(id){
      console.log(id)
      alert(id)
    },
    
    changeCounter: function(num,orderItemId){
      this.counter += +num
      
      !isNaN(this.counter) && this.counter > 1 ? this.counter : this.counter = 1;   
      this.addProduct(orderItemId)     
    },

    getQTA() {
      console.log(this.id)
      console.log(this.code)
      console.log(this.counter)
    },
    addProduct(orderItemId){
      
      var data = {
        "id":this.productID,
        "gcode":this.code,
        "qta": parseFloat(this.counter),
        "size":'',
        "colors":'',
        "usr":"None",
        "orderItemId":orderItemId,
      }
      
      let cart = this.getCart
      if(!cart){
        data['preorder']=0;
        data['puid']=0;
      }else{
        data['preorder']=cart.oh;
        data['puid']=cart.puid;
      }

      this.addToCart(data)
      this.getResCart()
    }, 
    getResCart(){
		let cart = this.getCart
		if (cart.products_available > 0) {
			this.$emit('updateCart')
		} else {
			
			this.$swal(  
			{
				icon: 'error',
				title: 'Product unavailable',
				text: 'Sorry, this product is unavailable',
				footer: ''
			}
			)
		}

    },

 }

};
</script>
<script>
import serverConfig   from '@/_helpers/config';
import getFunct   from '@/_helpers/gfunct';
import axios from 'axios'
import { mapActions, mapGetters } from "vuex";
import { objLang, t_trl }   from '@/_helpers/lang';  
import moment from 'moment'

export default {
  name:'Product',
  components:{
   
  },
  setup() {
    //const { cookies } = useCookies();
    //return { cookies };
  },
  data(){
    return {
      ep:serverConfig.EP,
      current_date_tmp: moment().unix(),
      current_date: moment().format('LLL'),
      cat:'all',
      counter:1,
      trl: objLang,
      btnClick:false,
    }
  },
  computed: {
     ...mapGetters(["getUser","productDetails","getLanguage","getCart","getCartItems","getTrl"]),
  },
  beforeMount(){
   
    this.getProductDetails(this.$route.params.slug);
  },
  mounted(){
    setInterval(() => {
      this.current_date_tmp = moment().unix()
      this.current_date = moment().format('LLL')
    }, 1000);
  },
  methods:{
   ...mapActions(["getProductDetails","addToNewCart","getDetailsCart" ]),
    getFormatP(value){
      return getFunct.formatPrice(value)
    },
    len(lista){
      if (lista) {
        return lista.length
      } else {
        return 0
      }
      
    },
    hasProp(obj,prop){
      if(obj){
        return obj.hasOwnProperty(prop);
      } else {
        return false
      }
      
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    extrObj(obj,lang){
      return obj['descriptions'][lang]['abstract']
    },
   translName(obj,lang){
    if (Object.prototype.hasOwnProperty.call(obj, "descriptions") == true) {
      if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "title") == true )
      {
        return obj['descriptions'][lang]['title']
      } else {
        return ''
      }
    }
   },
   translAbstract(obj,lang){
    if (Object.prototype.hasOwnProperty.call(obj, "descriptions") == true) {
      if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "abstract") == true )
      {
        return obj['descriptions'][lang]['abstract']
      } else {
        return ''
      }
    }
   },
   translText(obj,lang){
    if (obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "descriptions") == true) {
        if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "description") == true )
        {
          return obj['descriptions'][lang]['description']
        } else {
          return ''
        }
      }      
    }
   },
   translTextMoreInfo(obj,lang){
    if (obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "descriptions") == true) {
        if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "description") == true )
        {
                  
          let res = `<div class="col-lg-3 col-md-12" ><h4 class="hprodesc">${this.getTrl.t_product_val_nut}</h4>`
          res += `<strong>${this.getTrl.t_nv_energy}:</strong> ${obj['descriptions'][lang]['nv_energy']}<br>`
          res += `<strong>${this.getTrl.t_nv_fats}:</strong> ${obj['descriptions'][lang]['nv_fats']}<br>`
          res += `<strong>${this.getTrl.t_nv_carbohydrates}:</strong> ${obj['descriptions'][lang]['nv_carbohydrates']}<br>`
          res += `<strong>${this.getTrl.t_nv_protein}:</strong> ${obj['descriptions'][lang]['nv_protein']}<br>`
          res += `<strong>${this.getTrl.t_nv_salt}:</strong> ${obj['descriptions'][lang]['nv_salt']}<br>`
          res +=`</div><div class="col-lg-3 col-md-12" ><h4 class="hprodesc">${this.getTrl.t_more_info_ingredients}</h4>`
          res += `${obj['descriptions'][lang]['more_info_ingredients']}<br>`
          res += `<strong>${this.getTrl.t_more_info_shelfLife}:</strong> ${obj['descriptions'][lang]['more_info_shelfLife']}<br>`
          res += `<strong>${this.getTrl.t_more_info_expiry_date}:</strong> ${obj['descriptions'][lang]['more_info_expiry_date']}<br>`
          res += `<strong>${this.getTrl.t_more_info_storage}:</strong> ${obj['descriptions'][lang]['more_info_storage']}<br>`
          
          res += `<strong>${this.getTrl.t_more_info_place_of_grinding}:</strong> ${obj['descriptions'][lang]['more_info_place_of_grinding']}<br>`
          res += `<strong>${this.getTrl.t_more_info_appearance}:</strong> ${obj['descriptions'][lang]['more_info_appearance']}<br>`
          res += `<strong>${this.getTrl.t_more_info_colour}:</strong> ${obj['descriptions'][lang]['more_info_colour']}<br>`
          res += `<strong>${this.getTrl.t_more_info_cooking_time}:</strong> ${obj['descriptions'][lang]['more_info_cooking_time']}<br>`
          res += `<strong>${this.getTrl.t_more_info_individual_package_weight}:</strong> ${obj['descriptions'][lang]['more_info_individual_package_weight']}<br>`
          res += `</div>`

          return res

        } else {
          return ''
        }
      }      
    }
   },
   translHrefName(obj,lang){
    if (obj) {
      if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "slug") == true )
      {
        return obj['descriptions'][lang]['slug']
      } else {
        return '/'
      }
    }
   },  
    transPrice(obj){
      if (obj){
        return this.roundTo(obj.defPrice*obj.vat.perc, 2)
      }
    },
    roundTo(value, decimal){
      var i = value * Math.pow(10,  decimal)
      i = Math.round(i)
      return i / Math.pow(10, decimal)
    },
    changeCounter: function(num,orderItemId){
      this.counter += +num
      !isNaN(this.counter) && this.counter > 1 ? this.counter : this.counter = 1;   
      this.addProduct(orderItemId)     
    },
    swalClose(){
      this.$swal.close()
    },
    addProduct(data){
      let self = this
      this.btnClick = true
      let cookies_oh = $cookies.get("oh")
      let cookies_puid = $cookies.get("puid")
      
      if(!cookies_oh){
        data['preorder']=0;
        $cookies.set("ohex",0)
      }else{
        data['preorder']=cookies_oh;
      }
      if(!cookies_puid){
        data['puid']=0;
      }else{
        data['puid']=cookies_puid;
      }
      if ( this.hasProp(this.getUser,'uid') == true )
      {
        data['usr'] = this.getUser.uid
      }
      this.addToNewCart(data)
      setTimeout(function(){
        self.getResCart()
      },500)
      if(!cookies_oh){
        setTimeout(function(){
          self.getResCart()
        },1000)
        setTimeout(function(){
          self.getResCart()
        },2000)
      }
      
    }, 
    addRelProduct(id,code){
      let self = this
      this.btnClick = true
      let cookies_oh = $cookies.get("oh")
      let cookies_puid = $cookies.get("puid")
      let data = {
        'id': id,
        'gcode': code,
        'qta': 1,
        'size':'',
        'colors':'',
        'usr':'None'
      }

      if(!cookies_oh){
        data['preorder']=0;
        $cookies.set("ohex",0)
      }else{
        data['preorder']=cookies_oh;
      }
      if(!cookies_puid){
        data['puid']=0;
      }else{
        data['puid']=cookies_puid;
      }
      if ( this.hasProp(this.getUser,'uid') == true )
      {
        data['usr'] = this.getUser.uid
      }
      this.addToNewCart(data)
      setTimeout(function(){
        self.getResCart()
      },500)
      if(!cookies_oh){
        setTimeout(function(){
          self.getResCart()
        },1000)
        setTimeout(function(){
          self.getResCart()
        },2000)
      }

    }, 
    getResCart(){
      const self = this
      this.$root.$root.getDCart()
      
      setTimeout(function(){
        self.btnClick = false
      },600)
    },
    updateCart(){
      this.getProductDetails(this.$route.params.slug)
    },
    checkDiscount(now,start,end) {
      let last_date = moment.unix(end).add(1,'days')

      if (now >= start && now <= last_date){
        return true
      } else {
        return false
      }
    },
    downloadItem (url_val) {
      
      axios({
                    url: url_val, // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     console.log(res)
                     var docUrl = document.createElement('x');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'file.pdf');
                     document.body.appendChild(docUrl);
                     docUrl.click();
        });


  }

   
    

  }


}
</script>
<template>
  <div class="wrapper">
    <b-carousel
    id="carousel-fade"
    style="text-shadow: 0px 0px 2px #000"
    fade
    indicators
    img-width="1024"
    img-height="180"
    >
      <template v-if="productDetails.category_image">
       <b-carousel-slide active>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="180"
            :src="productDetails.category_image.image"
            alt="index"
          >
        </template>
      </b-carousel-slide>        
      </template>     
       
    </b-carousel>
    <div class="page_container">      
    </div>
      <div class="container mt-20">
        <div class="row ">
          <div class="col-lg-6 col-md-6">
            <div class="">
              <img class="card-img-top" :src="productDetails.product.image" alt=""  v-if="productDetails.product"/>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 " v-if="hasProp(productDetails,'product') == true">
              <div id="div_block-5-67" class="ct-div-block" >
                 <div id="headline-51-12" v-if="productDetails.product">
                  <div v-html="translName(productDetails.product, getLanguage)"></div>
                </div>
                <div id="div_block-10-67" class="ct-div-block mt-20" >
                  <div id="text_block-8-67" class="ct-text-block h4" >{{getTrl.t_price}}:&nbsp;</div>
                  <div id="text_block-13-67" class="ct-text-block price_box" >
                    <p  v-if="productDetails">     
                      <template v-if="productDetails.product.discount_perc>0 && checkDiscount(current_date_tmp,productDetails.product.start_date_promo,productDetails.product.end_date_promo) == true">
                        <span class="current_price pr-5">
                            <button class="discount-circle-button" href="javascript:void(0)">- {{productDetails.product.discount_perc}}%<span class="orange-circle-greater-than"></span></button>
                        </span>                        
                        <span class="h3 pr-5">{{ getFormatP(productDetails.product.discount_price) }} €</span>
                        <span class="old_price"> {{productDetails.product.defPrice }} €</span>
                      </template>
                      <template v-else>
                        <span class="h3" v-html="getFormatP(productDetails.product.defPrice)"></span>
                        <span class="h3">&euro;</span>
                      </template>

                    </p>
                  </div>
                </div>
                <div id="div_block-41-67" class="ct-div-block special-content" v-if="productDetails">
                  <div id="text_block-44-67" class="ct-text-block" v-if="hasProp(productDetails,'product') == true ">
                      <template v-if="hasProp(productDetails.product,'giacenzaEffettiva') == true ">
                       <template v-if="productDetails.product.giacenzaEffettiva > 0">
                        <div v-html="translAbstract(productDetails.product, getLanguage)">
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
                <div id="div_block-21-67" class="ct-div-block"  v-if="productDetails.product.giacenzaEffettiva">
                <template v-if="hasProp(productDetails,'product') == true ">  
                  <template v-if="productDetails.product.giacenzaEffettiva > 0">
                    <div class="maz-input-number-prod">
                      <MazInputNumber
                        v-model="counter"           
                        :min="1"
                        :max="productDetails.product.giacenzaEffettiva"
                        :step="1"
                        size="md"
                        color="primary"
                      />
                    </div>
                    <template v-if="productDetails.product.giacenzaEffettiva > 5 && productDetails.product.giacenzaEffettiva == counter">
                      <div class="mt-20">
                        <b-alert show variant="primary">
                          <span class=""> 
                            {{getTrl.t_available_items}} &nbsp;
                            <span class="alert-link">
                            {{ productDetails.product.giacenzaEffettiva }} 
                            </span>
                          </span>
                        </b-alert>
                      </div>
                    </template>
                    <template v-if="productDetails.product.giacenzaEffettiva <= 5">
                      <div class="mt-20">
                        <b-alert show variant="primary">
                          <span class=""> 
                              {{getTrl.t_available_items}} &nbsp;
                            <span class="alert-link">
                              {{ productDetails.product.giacenzaEffettiva }} 
                            </span>
                          </span>
                        </b-alert>
                      </div>
                    </template>                   
                    <div class="mt-20">
                      <template v-if="btnClick==false">
                        <a 
                          class="ct-link-button mainBtn" 
                          href="javascript:void(0)" 
                          target="_self"   
                          @click="addProduct({
                            'id':productDetails.product._id,
                            'gcode': productDetails.product.gcode ,
                            'qta': parseFloat(counter),
                            'size':'',
                            'colors':'',
                            'usr':'None'
                          })">
                         {{getTrl.t_addtocart}}
                        </a>                        
                      </template>
                      <template v-else>
                        <MazSpinner
                          size="50"
                        />
                      </template>
                    </div>                    
                  </template>
                  <template v-else>
                      <b-alert show variant="danger"><span class="alert-link">{{getTrl.t_not_available}}</span></b-alert>
                  </template>
                </template>
                </div>
                <div id="div_block-32-67" class="ct-div-block mt-20" >
                <template v-if="hasProp(productDetails,'product') == true ">
                  <div id="text_block-35-67" class="ct-text-block" > {{getTrl.t_product_code}}:</div>
                  <div id="text_block-39-67" class="ct-text-block" >{{productDetails.product.gcode}}</div>
                </template>
                </div>
              </div>
          </div>
        </div>  
      </div>
      <div class="container mt-20">
          <b-card id="prodTabs"  no-body>
            <b-tabs card>
              <b-tab :title="getTrl.t_product_desc" active>
                <div class="mt-20 text_block-35-67">
                  <b-card-text >
                   <div v-html="translText(productDetails.product, getLanguage)"></div> 


                    <template v-if="hasProp(productDetails,'product') == true ">
                     <template  v-if="productDetails.files">
                      <div class="mt-50 ml-20">
                        <h4 class="hprodesc">Allegati</h4>
                        <div class="" v-for="item in productDetails.files" v-bind:key="item._id">

                          <a :href="ep+'/'+item.path" class="attached" target="_blank">{{item.descriptions.ita.title}}</a>
                        </div>
                      </div>
                     </template>

                    </template>

                  </b-card-text>
                </div>                
              </b-tab>
              <b-tab :title="getTrl.t_product_other_info">
                <div class="mt-20">
                  <b-card-text>
                  <div class="row" v-html="translTextMoreInfo(productDetails.product, getLanguage)">
                    
                  </div>
                  </b-card-text>

                </div>
              </b-tab>
            </b-tabs>
        </b-card>

    </div>
    <div class="container" v-if="len(productDetails.related_products) > 0">
      <template v-if="hasProp(productDetails,'related_products')==true">
      <b-row class="mt-20">
        <b-col>            
          <h3 class="h-related-products">{{getTrl.t_product_related_products}}</h3>
        </b-col>
      </b-row>
      <div class="row hidden-md-up mt-20">
          <div class="productBox col-md-3"  v-for="item in productDetails.related_products" :key="item._id" >
            <a :href="'/' + item.descriptions.ita.slug">
            <div class="card text-center content_img">
              <div class="card-block">
                <img :src="item.image" class="img-thumbnail" :alt="item.descriptions.ita.title">
                <h5 class="card-title card-product-name">{{item.descriptions.ita.title}}</h5>
                
                <p class="card-text p-y-1 card-product-price price_box">
                  <template v-if="item.discount_perc>0 && checkDiscount(current_date_tmp,item.start_date_promo,item.end_date_promo) == true">
                    <span class="current_price pr-5">
                      <button class="discount-circle-button" href="javascript:void(0)">- {{item.discount_perc}}%<span class="orange-circle-greater-than"></span></button>
                    </span>  
                    <span class="current_price pr-5">{{ getFormatP(item.discount_price) }} €</span>
                    <span class="old_price"> {{item.defPrice }} €</span>
                  </template>
                  <template v-else>
                    <span class="current_price">{{ getFormatP(item.defPrice) }} €</span>
                  </template>

                </p>


                  <div class="middle">
                    <a 
                      href="javascript:void(0)"
                      class="ct-link-button mainBtnmin"  
                      target="_self" 
                      :title="getTrl.t_addtocart" 
                      @click="addRelProduct(item._id, item.gcode)"
                      >
                      <svg fill="#ffffff" width="40px" height="40px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_iconCarrier"><path d="M 4 7 C 3.449219 7 3 7.449219 3 8 C 3 8.550781 3.449219 9 4 9 L 6.21875 9 L 8.84375 19.5 C 9.066406 20.390625 9.863281 21 10.78125 21 L 23.25 21 C 24.152344 21 24.917969 20.402344 25.15625 19.53125 L 27.75 10 L 25.65625 10 L 23.25 19 L 10.78125 19 L 8.15625 8.5 C 7.933594 7.609375 7.136719 7 6.21875 7 Z M 22 21 C 20.355469 21 19 22.355469 19 24 C 19 25.644531 20.355469 27 22 27 C 23.644531 27 25 25.644531 25 24 C 25 22.355469 23.644531 21 22 21 Z M 13 21 C 11.355469 21 10 22.355469 10 24 C 10 25.644531 11.355469 27 13 27 C 14.644531 27 16 25.644531 16 24 C 16 22.355469 14.644531 21 13 21 Z M 16 7 L 16 10 L 13 10 L 13 12 L 16 12 L 16 15 L 18 15 L 18 12 L 21 12 L 21 10 L 18 10 L 18 7 Z M 13 23 C 13.5625 23 14 23.4375 14 24 C 14 24.5625 13.5625 25 13 25 C 12.4375 25 12 24.5625 12 24 C 12 23.4375 12.4375 23 13 23 Z M 22 23 C 22.5625 23 23 23.4375 23 24 C 23 24.5625 22.5625 25 22 25 C 21.4375 25 21 24.5625 21 24 C 21 23.4375 21.4375 23 22 23 Z"/></g></svg>
                    </a> 
                  </div>
              </div>
            </div>
            </a>
          </div>
      </div>
      </template>
    </div>
    <div class="container mt-20">

    </div>

  </div>

</template>
<style>
p>a {
  color:#640e19!important;
  font-weight: 600;
}
.attached {
  color:red; font-weight:bold
}
</style>
let objLang = {
	"loginUserNotFound":{
		"ita":{
			title:"Utente non trovato",
			text:"Spiacente, utente non trovato.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"User not found",
			text:"Sorry, user not found",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title: "Utilisateur non trouvé",
			text: "Utilisateur non trouvé",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title: "Benutzer nicht gefunden",
			text: "Benutzer nicht gefunden",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title: "Usuario no encontrado",
			text: "Lo sentimos, usuario no encontrado",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},
	"loginUserWrongPassword":{
		"ita":{
			title:"Password non valida",
			text:"Spiacente, la password inserita non è valida.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"Wrong Password",
			text:"Sorry, Wrong Password",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title: "Mot de passe invalide",
			text: "Désolé, le mot de passe que vous avez saisi n'est pas valide.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title: "Ungültiges Passwort",
			text: "Das von Ihnen eingegebene Passwort ist leider ungültig.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title: "Contraseña inválida",
			text: "Lo sentimos, contraseña incorrecta",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},	
	"SomethingWentWrong":{
		"ita":{
			title:"Ops, qualcosa è andato storto",
			text:"Spiacente, qualcosa è andato storto. Riprova.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"Something went wrong",
			text:"Sorry, something went wrong, try againg",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title: "Ops, Quelque chose s'est mal passé",
			text: "Désolé, quelque chose s'est mal passé, essayez à nouveau.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title: "Ungültiges Passwort",
			text: "Tut mir leid, etwas ist schief gelaufen, versuchen Sie es noch einmal.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title: "Algo ha ido ma",
			text: "Lo siento, algo ha ido mal, inténtalo de nuevo.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},	
	"emailAlreadyInUse":{
		"ita":{
			title:"Email già in uso.",
			text:"Spiacente, email già presente.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"Email already in use",
			text:"Sorry, email already in use",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title: "L'email est déjà utilisé",
			text: "Désolé, l'email est déjà utilisé.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title: "E-Mail bereits in Gebrauch",
			text: "Tut mir leid, E-Mail bereits in Gebrauch.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title: "Email ya está en uso",
			text: "Lo sentimos, email ya está en uso.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},	
	"invalidEmail":{
		"ita":{
			title:"Email non valida",
			text:"Email non valida.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"Invalid email",
			text:"Invalid email",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title: "Email non valide",
			text: "Email non valide",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title: "Ungültige E-Mail",
			text: "Ungültige E-Mail",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title: "Email no válido",
			text: "Email no válido",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},	
	"weakPassword":{
		"ita":{
			title:"Password debole",
			text:"Attenzione: la password inserita è debole.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"Weak password",
			text:"Warning: weak password.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title: "Mot de passe faible",
			text: "Attention : mot de passe faible.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title: "Schwaches Passwort",
			text: "Warnung: Schwaches Passwort.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title: "Contraseña débil",
			text: "Advertencia: contraseña débil.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},	
	"swlPaySuccess":{
		"ita":{
			title:"Pagamento",
			text:"Il pagamento è andato a buon fine!",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"Payment",
			text:"Your payment was successful!",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title:"Paiement",
			text:"Votre paiement a été effectué avec succès !",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title:"Zahlung",
			text:"Ihre Zahlung war erfolgreich!",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title:"Pago",
			text:"¡Su pago fue exitoso! ",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},	
	"swlOrderCompleted":{
		"ita":{
			title:"Ordine completato",
			text:"Grazie per aver completato il tuo ordine. Ti abbiamo inviato una email riepilogativa del tuo ordine",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"en":{
			title:"Ordine completato",
			text:"Grazie per aver completato il tuo ordine. Ti abbiamo inviato una email riepilogativa del tuo ordine",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"fr":{
			title:"Ordine completato",
			text:"Grazie per aver completato il tuo ordine. Ti abbiamo inviato una email riepilogativa del tuo ordine",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"de":{
			title:"Ordine completato",
			text:"Grazie per aver completato il tuo ordine. Ti abbiamo inviato una email riepilogativa del tuo ordine",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"esp":{
			title:"Ordine completato",
			text:"Grazie per aver completato il tuo ordine. Ti abbiamo inviato una email riepilogativa del tuo ordine",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		}
	},
	"swlDeleteConfirm":{
		"ita":{
      title: 'Sei sicuro di voler eliminare questo articolo?',
      text: "Non sarà possibile annullare l'operazione!",
      icona: 'avviso',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sì, cancellalo!',
      cancelButtonText: 'Annulla',
		},
		"en":{
      title: 'Are you sure you want to delete this item?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
		},
		"fr":{
			title : 'Êtes-vous sûr de vouloir supprimer cet élément ?',
			texte : "Vous ne pourrez pas revenir en arrière",
			icône : 'warning',
			showCancelButton : true,
			confirmButtonColor : '#3085d6',
			cancelButtonColor : '#d33',
			confirmButtonText : 'Yes, delete it !'
		},
		"de":{
			title: 'Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?',
			text: "Das können Sie nicht mehr rückgängig machen!",
			icon: 'Warnung',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ja, löschen Sie es!'
		},
		"esp":{
			title: '¿Seguro que quiere eliminar este artículo?',
			text: "¡No podrá revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, ¡elimínalo!'
		}
	},
	"swlSessionExp":{
		"ita":{
			title:"La sessione è scaduta",
			text:"La sessione è scaduta, effettuare il login per continuare.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"en":{
			title:"Session has expired",
			text:"Your session has expired, please login to continue",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"fr":{
			title:"Votre session a expiré",
			text:"Votre session a expiré, veuillez vous connecter pour continuer.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"de":{
			title:"Ihre Sitzung ist abgelaufen",
			text:"Ihre Sitzung ist abgelaufen, bitte melden Sie sich an, um fortzufahren.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"esp":{
			title:"Su sesión ha expirado",
			text:"Su sesión ha expirado, por favor inicie sesión para continuar.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		}
	},	
	"swlLogOrRegToOrder":{
		"ita":{
			title:"La sessione è scaduta",
			text:"La sessione è scaduta, effettuare il login per continuare.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"en":{
			title:"Session has expired",
			text:"Your session has expired, please login to continue",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"fr":{
			title:"Votre session a expiré",
			text:"Votre session a expiré, veuillez vous connecter pour continuer.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"de":{
			title:"Ihre Sitzung ist abgelaufen",
			text:"Ihre Sitzung ist abgelaufen, bitte melden Sie sich an, um fortzufahren.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"esp":{
			title:"Su sesión ha expirado",
			text:"Su sesión ha expirado, por favor inicie sesión para continuar.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		}
	},	
	"swlNetworkError":{
		"ita":{
			title:"Errore di rete",
			text:"Oops, controllare le impostazioni di rete e riprovare.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"en":{
			title:"Network error",
			text:"Oops, please check your network settings and try again.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"fr":{
			title:"Erreur de réseau",
			text:"Oups, veuillez vérifier vos paramètres réseau et réessayer.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"de":{
			title:"Netzwerk-Fehler",
			text:"Ups, bitte überprüfen Sie Ihre Netzwerkeinstellungen und versuchen Sie es erneut.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"esp":{
			title:"Error de red",
			text:"Oops, por favor, compruebe su configuración de red y vuelva a intentarlo.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		}
	},
	"swlFillOutForms":{
		"ita":{
			title:"Dati anagrafici incompleti",
			text:"Attenzione: non hai ancora completato la tua registrazione. Completa il modulo con i dati di fatturazione per poter effettuare un ordine.",
			icon:"warning",
			showCancelButton:false,
			showConfirmButton:true,
			timer:6000,
		},
		"en":{
			title:"",
			text:"Oops, please check your network settings and try again.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:4000,
		},
		"fr":{
			title:"Problème non défini",
			text:"Oups, quelque chose s'est mal passé, réessayez.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:4000,
		},
		"de":{
			title:"Unbestimmtes Problem",
			text:"Ups, etwas ist schief gelaufen, versuchen Sie es noch einmal.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:4000,
		},
		"esp":{
			title:"Problema de indefinición",
			text:"Oops, algo salió mal, por favor inténtelo de nuevo.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:4000,
		}
	},
	"swlMandatoryFields":{
		"ita":{
			title:"Modulo dati incompleto",
			text:"Compila tutti i campi obbligatori, per favore.",
			icon:"info",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
	},
	"swlSomethingError":{
		"ita":{
			title:"Problema non definito",
			text:"Ops, qualcosa è andato storto, prova di nuovo.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"en":{
			title:"Undefined problem",
			text:"Oops, please check your network settings and try again.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"fr":{
			title:"Problème non défini",
			text:"Oups, quelque chose s'est mal passé, réessayez.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"de":{
			title:"Unbestimmtes Problem",
			text:"Ups, etwas ist schief gelaufen, versuchen Sie es noch einmal.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		},
		"esp":{
			title:"Problema de indefinición",
			text:"Oops, algo salió mal, por favor inténtelo de nuevo.",
			icon:"error",
			showCancelButton:false,
			showConfirmButton:false,
			timer:2000,
		}
	},
	"swlMsgResetPSWSuccess":{
		"ita":{
			title:"Reimposta password",
			text:"Ti abbiamo inviato una email con un link, seguilo per reimpostare la password",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"en":{
			title: "Reset password",
			text: "We sent you an e-mail with a link, follow it to reset your password.",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"fr":{
			title : "Réinitialiser le mot de passe",
			text : "Nous vous avons envoyé un e-mail avec un lien, suivez-le pour réinitialiser votre mot de passe",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"de":{
			title: 'Passwort zurücksetzen',
			text: 'Wir haben Ihnen eine E-Mail mit einem Link geschickt, folgen Sie ihm, um Ihr Passwort zurückzusetzen',
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"esp":{
			title: 'Restablecer contraseña',
			text: 'Le hemos enviado un correo electrónico con un enlace, sígalo para restablecer su contraseña',
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		}
	},
	"swlMsgDataUpdatedSuccess":{
		"ita":{
			title:"Dati aggiornati",
			text:"I tuoi dati sono stati aggiornati con successo!",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
	},
	"swlMsgChangedPSWSuccess":{
		"ita":{
			title:"Nuova password",
			text:"La tua password è stata modificata con successo!",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"en":{
			title: "New Password",
			text: "Your password has been successfully changed!",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"fr":{
			title : "Nouveau mot de passe",
			text : "Votre mot de passe a été modifié avec succès !",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"de":{
			title: "Neues Passwort",
			text: "Ihr Passwort wurde erfolgreich geändert",			
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		},
		"esp":{
			title: "Nueva contraseña",
			text: "¡Su contraseña ha sido cambiada con éxito!",
			icon:"success",
			showCancelButton:false,
			showConfirmButton:false,
			timer:3500,
		}
	},
	"txt":{
		"ita":{
			t_cart:"Carrello",
			t_cart_empty: "Il tuo carrello è attualmente vuoto",
			t_billing:"Fatturazione",
			t_shipping:"Luogo di Spedizione",
			t_all_shipping_address:"Indirizzi di spedizione registrati",
			t_show_shipping_address:"Mostra Indirizzo di consegna",
			t_same_billing_address:"Uguale all'indirizzo di fatturazione",
			t_other_shipping_address:"Altro Indirizzo di consegna",
			t_update:"Aggiorna",
			t_register:"Registrati",
			t_product:"Prodotto",
			t_price:"Prezzo",
			t_subtotal:"Imponibile",
			t_quantity:"Quantità",
			t_total:"Totale",
			t_shipping_cost:"Spese di spedizione",
			t_iva:"Iva",
			t_discount:"Sconto",
			t_voucher:"Codice sconto",
			t_apply_coupon:"Applica codice coupon",
			t_proceed_to_checkout:"Procedi alla cassa",

			t_mustreg:" Per effettuare un ordine tramite il nostro sito web è necessario essere registrati. \n La registrazione è facile e veloce ed è un ottimo modo per semplificare la vostra esperienza online.",
			t_log_or_reg:"Accedi/Registrati",
			t_reset_psw:"Reset your password",

		},	
		"en":{
			
		},
		"fr":{
			
		},
		"de":{
		
		},
		"esp":{

		}
	},		
}

const t_trl = {
	"ita":{
		t_welcome:'Benvenuto nel negozio on line',
		t_cart:"Carrello",
		t_checkout:"Cassa",
		t_my_account:"Il mio account",
		t_my_orders: "I miei ordini",
		t_my_orderlist: "Lista ordini",
		t_order_detail: "Dettaglio ordine",
		t_date_header:'Data',
		t_status_header:'Status',
		t_shipping_addr_header:'Indirizzo di consegna',
		t_city_header:'Città',
		t_zip_header:'Cap',
		t_prov_addr_header:'Provincia',
		t_shipping_cost_header:'Costi di spedizione',
		t_total_header:'Totale',
		t_checkout_done:"Il tuo ordine è stato completato!",
		t_continue_shopping:"Continua lo shopping",
		t_followus:"Seguici sui social",
		t_products:"Prodotti",
		t_detail_shipping: 'Dettagli di spedizione',
		t_select_product:"Seleziona prodotto",
		t_sitemaps:"Mappa del sito",
		t_contact:"Contatti",
		t_cart_empty: "Il tuo carrello è attualmente vuoto.",
		t_cart_min_kg:"L'ordine minimo è di ",
		t_cart_max_kg:"L'ordine massimo è di ",
		t_current_kg:"Attualmente il totale è di ",
		t_billing:"Fatturazione",
		t_shipping:"Spedizione",
		t_all_shipping_address : "Indirizzi di spedizione registrati",
		t_show_shipping_address:"Mostra Indirizzo di consegna",
		t_same_billing_address:"Uguale all'indirizzo di fatturazione",
		t_other_shipping_address:"Altro Indirizzo di consegna",
		t_update:"Aggiorna",
		t_register:"Registrati",
		t_addtocart:"Aggiungi al carrello",
		t_product_code:"Codice prodotto",
		t_product_desc:"Descrizione prodotto",
		t_product_other_info:"Altre informazioni",
		t_product_related_products:"Prodotti correlati",
		t_product_val_nut:"Valori nutrizionali medi",
		t_nv_energy:"Energia",
		t_nv_fats:"Grassi",
		t_nv_carbohydrates:"Carboidrati",
		t_nv_fibres:"Fibre",
		t_nv_protein:"Proteine",
		t_nv_salt:"Sale",
		t_more_info_ingredients:"Ingredienti",
		t_more_info_shelfLife:"Shelf Life",
		t_more_info_expiry_date:"Data scadenza",
		t_more_info_storage:"Conservazione",
		t_more_info_place_of_grinding:"Luogo di macinazione",
		t_more_info_appearance:"Aspetto",
		t_more_info_colour:"Colore",
		t_more_info_cooking_time:"Tempo di cottura",
		t_more_info_individual_package_weight:"Peso della confezione singola",
		t_product_info_gen:"Informazioni generali",
		t_removefromcart:"Rimuovi",
		t_available_items: "Articoli ancora disponibili",
		t_added_to_cart: "Prodotto aggiunto nel carrello!",
		t_not_available: "Prodotto non disponibile",
		t_qty_not_available:"Quantità richiesta non disponibile.",
		t_product:"Prodotto",
		t_price:"Prezzo",
		t_subtotal:"Imponibile",
		t_quantity:"Quantità",
		t_total:"Totale",
		t_shipping_cost:"Spese di spedizione",
		t_iva:"Iva",
		t_total_cart:"Totale carrello",
		t_discount:"Sconto",
		t_coupon:"Codice coupon",
		t_apply_coupon:"Applica codice coupon",
		t_proceed_to_checkout:"Procedi alla cassa",
		t_mustreg:" Per effettuare un ordine tramite il nostro sito web è necessario essere registrati. \n La registrazione è facile e veloce ed è un ottimo modo per semplificare la vostra esperienza online.",
		t_log_or_reg:"Accedi/Registrati",
		t_reset_psw:"Reimposta la tua password",
		t_insert_new_psw:"Inserisci una nuova password",
		t_save_btn:"Salva",
		t_edit_btn:"Modifica",

		t_reset_psw_text:"Inserisci il tuo indirizzo e-mail e ti invieremo un link per accedere di nuovo al tuo account.",
		t_reset_psw_btn:"Reset",
		t_txt_login:"Accedi",
		t_txt_register:"Registrati",
		t_txt_register_text:"Non hai un account?",
		t_txt_forget_psw:"Password dimenticata?",
		// FORM LABEL
        t_type:"Tipologia Cliente",
        t_please_select_customer:"Seleziona un'opzione, per favore",
        t_corporation:"Società di Capitali",
        t_partnership:"Società di persone",
        t_natural_person:"Persona Fisica",
        t_other:"Altro",
        t_clientcode:"Codice Cliente",
        t_cf:"Codice Fiscale",
        t_piva:"Partita Iva",
        t_name:"Nome",
        t_surname:"Cognome",
        t_cityofBirth:"Luogo di nascita",
        t_dateOfBirth:"Data di nascita",
        t_placeOfBirth:"Stato di nascita",
        t_provOfBirth:"Sigla Provincia",
        t_corpName:"Nome azienda",
        t_billingAddress:"Indirizzo",
        t_billingCity:"Città",
        t_billingCap:"CAP",
        t_billingProv:"Sigla Provincia",
        t_billingState:"Stato",
        t_billingPec:"P.E.C.",
        t_billingCodeU:"Codice Univoco",
        t_billingEmail:"Email",
        t_billingPhone:"Telefono",
        t_shippingAddress:"Indirizzo",
        t_shippingCity:"Città",
        t_shippingCap:"CAP",
        t_shippingProv:"Sigla Provincia",
        t_shippingState:"Stato",
        t_shippingEmail:"Email",
        t_shippingPhone:"Telefono",
        t_shippingAddressSetting:"Indirizzo di consegna",
        t_shippingAddress_bill:"Uguale all'indirizzo di fatturazione",
        t_shippingAddress_alt:"Altro indirizzo",
        t_shippingAddressShow:"Mostra Indirizzo di consegna",
        t_shippingAddressHide:"Nascondi Indirizzo di consegna",
        t_not_complete_data:"Inserisci i tuoi dati di fatturazione e spedizione, per favore.",
        t_btn_register:"Completa Registrazione",
		t_btn_update:"Aggiorna",
        t_check_res_title: 'Esito controllo dati',
        t_check_res_text: 'Abbiamo riscontrato la presenza del tuo codice fiscale(o partita iva) nel nostro sistema. Se sei già in possesso di un codice cliente, potrai utilizzarlo per importare i tuoi dati.',
		t_success_import_data_title:'Dati importati con successo',
		t_error_import_data_title:'Non è stato possibile importare i dati. Assicurati che il codice cliente sia corretto, per favore.',
		t_error_fiscal_code:'Codice fiscale non corretto. Inserisci un codice valido, per favore.',
		t_error_vat_number:'Non hai inserito la Partita Iva, inseriscila, per favore.',
		t_error_vat_number_text:'La partita IVA deve contenere 11 cifre.',
		t_confirm:'Conferma',
		t_cancel:'Annulla',
		t_error_msg:'Messaggio di errore',
		t_mandatory_field_title:'Campo obbligatorio',
		t_country_select:'Seleziona Paese',
		t_category_select:'Seleziona Categoria',
		t_phone_numberInput: {
              countrySelector: {
                placeholder: 'Codice Paese',
                error: 'Scegli Paese',
              },
              phoneInput: {
                placeholder: 'Numero di telefono',
                example: 'Esempio:',
              },
        },
        t_order_note:"Note (facoltative)",
		t_gift_note: "Vuoi includere una dedica?",
		t_delivery_carrier_tracking: "Corriere",
		t_delivery_note_tracking: "Note di tracciamento della spedizione",
		t_delivery_url_tracking: "Traccia la spedizione",
		t_estimated_delivery: "Data di consegna stimata",
		t_received: "Data di consegna",
	},
	"en":{
		t_welcome:'Welcome to the online shop',
		t_cart: "Cart",
		t_checkout: "Checkout",
		t_my_account:"My account",
		t_my_orders: "My orders",
		t_my_orderlist: 'Order list',
		t_order_detail: 'Order detail',
		t_date_header:'Date',
		t_status_header:'Status',
		t_shipping_addr_header:'Shipping address',
		t_city_header:'City',
		t_zip_header:'Zip',
		t_prov_addr_header:'Province',
		t_shipping_cost_header:'Shipping costs',
		t_total_header:'Total',
		t_checkout_done:"Your order is completed!",
		t_continue_shopping:"Continue shopping",
		t_followus: "Follow us on social",
		t_products: "Products",
		t_detail_shipping: 'Shipping details',
		t_select_product: "Select product",
		t_sitemaps: "Site map",
		t_contact: "Contact",
		t_cart_empty: "Your cart is currently empty",
		t_cart_min_kg: "Minimum order is ",
		t_cart_max_kg: "The maximum order is ",
		t_current_kg: "Current total is ",
		t_billing: "Billing",
		t_shipping: "Shipping",
		t_all_shipping_address:'shipping addresses recorded',
		t_show_shipping_address: "Show shipping address",
		t_same_billing_address: "Same as billing address",
		t_other_shipping_address: "Other shipping address",
		t_update: "Update.",
		t_register: "Register",	
		t_addtocart: "Add to cart",
		t_removefromcart: "Remove",
		t_product_code: "Product code",
		t_product_desc: "Product description",
		t_product_other_info: "Other information",
		t_product_related_products: "Related products",
		t_product_val_nut: "Average nutritional values",
		t_nv_energy: "Energy",
		t_nv_fats: "Fats",
		t_nv_carbohydrates: "Carbohydrates",
		t_nv_fibres: "Fibres",
		t_nv_protein: "Protein",
		t_nv_salt: "Salt",
		t_more_info_ingredients: "Ingredients",
		t_more_info_shelfLife: "Shelf Life",
		t_more_info_expiry_date: "Expiry Date",
		t_more_info_storage: "Storage",
		t_more_info_place_of_grinding: "Place of grinding",
		t_more_info_appearance: "Appearance",
		t_more_info_colour: "Colour",
		t_more_info_cooking_time: "Cooking time",
		t_more_info_individual_package_weight: "Individual package weight",
		t_product_info_gen: "General information",
		t_available_items: "Items still available",
		t_not_available: "Product temporarily unavailable",
		t_qty_not_available:"Requested Quantity is not Available",
		t_product: "Product",
		t_price: "Price",
		t_subtotal: "Taxable",
		t_quantity: "Quantity",
		t_total: "Total",
		t_shipping_cost: "Shipping cost",
		t_vat: "VAT",
		t_discount: "Discount",
		t_coupon: "Coupon code",
		t_apply_coupon: "Apply coupon code",
		t_proceed_to_checkout: "Proceed to checkout",
		t_total_cart: "Total cart",
		t_mustreg:" To place an order through our website, you must be registered. \n Registration is quick and easy and is a great way to simplify your online experience.",
		t_log_or_reg: "Login/Register",
		t_reset_psw:"Reset your password",
		t_reset_psw_text: "Enter your e-mail address and we will send you a link to access your account again.",
		t_reset_psw_btn: "Reset",
		t_insert_new_psw: "Enter a new password",
		t_save_btn: "Save",
		t_edit_btn: "Edit",
		t_txt_login: "Login",
		t_txt_register: 'Register',
		t_txt_register_text: "You do not have an account?",
		t_txt_forget_psw:"Forget password?",
		// FORM LABEL
        t_type: "Customer Type",
		t_please_select_customer:'Please select an option',
		t_corporation: "Corporation",
		t_partnership: "Association of persons",
		t_natural_person: "Natural Person",
		t_other: "Other",
        t_clientcode: "Customer Code",
        t_cf: "Tax Code",
        t_piva: "Vat Number",
        t_name: "First Name",
        t_surname: "Surname",
        t_cityofBirth: "Place of Birth",
        t_dateOfBirth: "Date of birth",
        t_placeOfBirth:'State of birth',
        t_provOfBirth:'Province Code',
        t_corpName: "Company Name",
        t_billingAddress: "Address",
        t_billingCity: "City",
        t_billingCap: "Postcode",
        t_billingProv: "Province Code",
        t_billingState: "State",
        t_billingPec: "P.E.C.",
        t_billingCodeU: "Unique Code",
        t_billingEmail: "Email",
        t_billingPhone: "Phone",
        t_shippingAddress: "Address",
        t_shippingCity: "City",
        t_shippingCap: "Postcode",
        t_shippingProv: "Province Code",
        t_shippingState: "State",
        t_shippingEmail: "Email",
        t_shippingPhone: "Phone",
        t_shippingAddressSetting: "Shipping Address",
        t_shippingAddress_bill: "Same as billing address",
        t_shippingAddress_alt: "Other address",
        t_shippingAddressShow: "Show shipping address",
        t_shippingAddressHide: "Hide shipping address",		
        t_not_complete_data:"Please enter your billing and shipping information.",
        t_btn_register:"Register",
		t_btn_update:"Update",
		t_check_res_title: 'Data check result',
		t_check_res_text: 'We have detected the presence of your tax code (or VAT number) in our system. If you already have a customer code, you can use it to import your data.',
		t_success_import_data_title:'Data imported successfully',
		t_error_import_data_title:'The data could not be imported. Please make sure the customer code is correct.',
		t_error_fiscal_code:'Incorrect tax code. Please enter a valid code.',
		t_error_vat_number:'You did not enter the VAT number, please enter it.',
		t_error_vat_number_text:'VAT number must contain 11 digits.',	
		t_confirm:'Confirm',
		t_cancel:'Cancel',
		t_error_msg:'Error message',
		t_country_select:'Select Country',
		t_category_select:'Select Category',
		t_phone_numberInput: {
              countrySelector: {
                placeholder: 'Country code',
                error: 'Choose country',
              },
              phoneInput: {
                placeholder: 'Phone number',
                example: 'Example:',
              },
        },
        t_order_note:'Notes (optional)',
		t_gift_note: "Do you have to include a dedication?",
		t_delivery_carrier_tracking: "Carrier",
		t_delivery_note_tracking: "Shipment tracking notes", 
		t_delivery_url_tracking: "Track shipment",
		t_estimated_delivery: "Estimated delivery date",
		t_received: 'Delivery date',

		
	},
	"fr":{
		t_welcome : "Bienvenue dans la boutique en ligne",
		t_cart : "Panier",
		t_checkout : "Checkout",
		t_my_account : "Mon compte",
		t_my_orders : "Mes commandes",
		t_my_orderlist : "Liste des commandes",
		t_order_detail : "Détail de la commande",		
		// Da tradurre in lingua corrente
		t_date_header : "Date",
		t_status_header : "Statut",
		t_shipping_addr_header : "Adresse de livraison",
		t_city_header : "Ville",
		t_zip_header : "Zip",
		t_prov_addr_header : "Province",
		t_shipping_cost_header : "Frais d'expédition",
		t_total_header : "Total",
		// Da tradurre in lingua corrente
		t_checkout_done : "Votre commande est terminée !",
		t_continue_shopping : "Continuer les achats",
		t_followus : "Suivez-nous sur les médias sociaux",
		t_products : "Produits",
		t_detail_shipping : "Détails d'expédition",
		t_select_product : "Sélectionnez un produit",
		t_sitemaps : "Plan du site",
		t_contact : "Contacts",
		t_cart_empty : "Votre panier est actuellement vide",
		t_cart_min_kg : "La commande minimale est ",
		t_cart_max_kg : "La commande maximale est ",
		t_current_kg : "Le total actuel est ",
		t_billing : "Facturation",
		t_shipping : "Expédition",
		t_all_shipping_address : "adresses d'expédition enregistrées",
		t_shipping_address : "Afficher l'adresse de livraison",
		t_same_billing_address : "Identique à l'adresse de facturation",
		t_other_shipping_address : "Autre adresse de livraison",
		t_update : "Mise à jour",
		t_register : "S'inscrire",
		t_addtocart : "Ajouter au panier",
		t_removefromcart : "Supprimer",
		t_product_code : "Code produit",
		t_product_desc : "Description du produit",
		t_product_other_info : "Autres informations",
		t_product_related_products : "Produits connexes",
		t_product_val_nut : "Valeurs nutritionnelles moyennes",
		t_nv_energy : "Énergie",
		t_nv_fats : "Fats",
		t_nv_carbohydrates : "Carbohydrates",
		t_nv_fibres : "Fibres",
		t_nv_protein : "Protéine",
		t_nv_salt : "Sel",
		t_more_info_ingredients : "Ingrédients",
		t_more_info_shelfLife : "Durée de vie",
		t_more_info_expiry_date : "Date d'expiration",
		t_more_info_storage : "Stockage",
		t_more_info_place_of_grinding : "Lieu de broyage",
		t_more_info_appearance : "Apparence",
		t_more_info_colour : "Couleur",
		t_more_info_cooking_time : "Temps de cuisson",
		t_more_info_individual_package_weight : "Poids du paquet individuel",
		t_product_info_gen : "Informations générales",
		t_available_items : 'Article encore disponible',
		t_not_available : "Produit temporairement indisponible",
		t_qty_not_available:"La quantité demandée n'est pas disponible",
		t_product : "Produit",
		t_price : "Prix",
		t_subtotal : "Imposable",
		t_quantity : "Quantité",
		t_total : "Total",
		t_shipping_cost : "Frais d'expédition",
		t_vat : "TVA",
		t_discount : "Remise",
		t_coupon : "Coupon code",
		t_apply_coupon : "Appliquer le code du coupon",
		t_proceed_to_checkout : "Passez à la caisse",
		t_total_cart : "Panier total",
		t_mustreg :" Pour passer une commande via notre site web, vous devez être enregistré. \L'enregistrement est rapide et facile et constitue un excellent moyen de simplifier votre expérience en ligne.",
		t_log_or_reg : "Se connecter ou s'enregistrer",
		t_reset_psw : "Réinitialisez votre mot de passe",
		t_reset_psw_text : "Entrez votre adresse e-mail et nous vous enverrons un lien pour accéder à nouveau à votre compte",
		t_reset_psw_btn : "Réinitialiser",	
		ve_btn : "Enregistrer",
		t_edit_btn : "Modifier",	
		t_txt_login : "Se connecter",
		t_txt_register : "Enregistrer",
		t_txt_register_text : "Vous n'avez pas de compte?",
		t_txt_forget_psw : "Mot de passe oublié?",
		// FORM LABEL
        t_type : "Type de client",
		t_please_select_customer : "Veuillez sélectionner une option",
		t_corporation : "Corporation",
		t_partnership : "Partenariat",
		t_natural_person : "Personne naturelle",
		t_other : "Autre",
        t_clientcode : "Code client",
        t_cf : "Code des impôts",
        t_piva : "Numéro de TVA",
        t_name : "Prénom",
        t_surname : "Nom de famille",
        t_cityofBirth : "Lieu de naissance",
        t_dateOfBirth : "Date de naissance",
        t_placeOfBirth : "État de naissance",
        t_provOfBirth : "Code de la province",
        t_corpName : "Nom de la société",
        t_billingAddress : "Adresse",
        t_billingCity : "Ville",
        t_billingCap : "Postcode",
        t_billingProv : "Code de la province",
        t_billingState : "État",
        t_billingPec : "P.E.C.",
        t_billingCodeU : "Code unique",
        t_billingEmail : "Email",
        t_billingPhone : "Téléphone",
        t_shippingAddress : "Adresse",
        t_shippingCity : "Ville",
        t_shippingCap : "Postcode",
        t_shippingProv : "Code de la province",
        t_shippingState : "État",
        t_shippingEmail : "Email",
        t_shippingPhone : "Téléphone",
        t_shippingAddressSetting : "Adresse de livraison",
        t_shippingAddress_bill : "Identique à l'adresse de facturation",
        t_shippingAddress_alt : "Autre adresse",
        t_shippingAddressShow : "Afficher l'adresse de livraison",
        t_shippingAddressHide : "Masquer l'adresse de livraison",		
        t_not_complete_data:"Veuillez saisir vos informations de facturation et d'expédition.",
		t_btn_register : "Register",
		t_btn_update : "Mise à jour",
		t_check_res_title : 'Résultat du contrôle des données',
		t_check_res_text : "Nous avons détecté la présence de votre code fiscal (ou numéro de TVA) dans notre système. Si vous avez déjà un code client, vous pouvez l'utiliser pour importer vos données.",
		t_success_import_data_title : 'Les données importées ont réussi',
		t_error_import_data_title : "Les données n'ont pas pu être importées. Veuillez vous assurer que le code client est correct.",
		t_error_fiscal_code : "Code fiscal incorrect. Veuillez entrer un code valide",
		t_error_vat_number : "Vous n'avez pas saisi votre numéro de TVA, veuillez le saisir.",
		t_error_vat_number_text : "Le numéro de TVA doit comporter 11 chiffres.",
		t_confirm : "Confirmer",
		t_cancel : "Cancel",
		t_error_msg : "Message d'erreur",
		t_country_select:'Choisir le pays',
		t_category_select:'Choisir la catégorie',
		t_phone_numberInput : {
              countrySelector : {
                placeholder : 'Country code',
                error : 'Choose country',
              },
              phoneInput : {
                placeholder : 'Phone number',
                example : 'Exemple:',
              },
        },
        t_order_note : "Notes (facultatif)",
		t_gift_note : "Doit-on inclure une dédicace ?",
		t_delivery_carrier_tracking : "Transporteur",
		t_delivery_note_tracking : "Notes de suivi de l'envoi", 
		t_delivery_url_tracking : "Suivi de l'envoi",
		t_estimated_delivery : "Date de livraison estimée",
		t_received : "Date de livraison",

	},
	"de":{
		t_welcome:'Willkommen im Online Shop',
		t_cart: "Warenkorb",
		t_checkout: "Kasse",
		t_my_account: "Mein Konto",
		t_my_orders: "Meine Bestellungen",
		t_my_orderlist: 'Auftragsliste',
		t_order_detail: 'Detail der Bestellung',
		// Da tradurre in lingua corrente
		t_date_header:'Datum',
		t_status_header:'Status',
		t_shipping_addr_header:'Lieferadresse',
		t_city_header:'Stadt',
		t_zip_header:'Postleitzahl',
		t_prov_addr_header:'Bundesland',
		t_shipping_cost_header:'Versandkosten',
		t_total_header:'Gesamt',
		// Da tradurre in lingua corrente
		t_checkout_done: "Ihre Bestellung ist abgeschlossen!",
		t_continue_shopping: "Weiter einkaufen",
		t_followus: "Folgen Sie uns in den sozialen Medien",
		t_products: "Produkte",
		t_detail_shipping: 'Versanddetails',
		t_select_product:'Produkt auswählen',
		t_sitemaps: "Lageplan",
		t_contact: "Kontakte",
		t_cart_empty: "Ihr Warenkorb ist derzeit leer",
		t_cart_min_kg: "Mindestbestellwert ist ",
		t_cart_max_kg: "Die maximale Bestellung ist ",
		t_current_kg: "Aktueller Gesamtbetrag ist ",
		t_billing: "Rechnungsstellung",
		t_shipping: "Versand",
		t_all_shipping_address:'Erfasste Versandadressen',
		t_shipping_address: "Lieferadresse anzeigen",
		t_same_billing_address: "Gleiche Adresse wie die Rechnungsadresse",
		t_other_shipping_address: "Andere Lieferadresse",
		t_update: "Aktualisierung",
		t_register: "Registrieren",
		t_addtocart: "In den Warenkorb legen",
		t_removefromcart: "Entfernen",
		t_product_code: "Produktcode",
		t_product_desc: "Produktbeschreibung",
		t_product_other_info: "Sonstige Informationen",
		t_product_related_products: "Ähnliche Produkte",
		t_product_val_nut: "Durchschnittliche Nährwertangaben",
		t_nv_energy: "Energie",
		t_nv_fats: "Fette",
		t_nv_carbohydrates: "Kohlenhydrate",
		t_nv_fibres: "Fasern",
		t_nv_protein: "Protein",
		t_nv_salt: "Salz",
		t_more_info_ingredients: "Zutaten",
		t_more_info_shelfLife: "Haltbarkeitsdauer",
		t_more_info_expiry_date: "Verfallsdatum",
		t_more_info_storage: "Lagerung",
		t_more_info_Ort_des_Schleifens: "Ort des Schleifens",
		t_more_info_appearance: "Erscheinungsbild",
		t_more_info_colour: "Farbe",
		t_more_info_cooking_time: "Kochzeit",
		t_more_info_individual_package_weight: "Gewicht des einzelnen Packstücks",
		t_product_info_gen: "Allgemeine Informationen",
		t_available_items: 'Artikel noch verfügbar',
		t_not_available: 'Produkt vorübergehend nicht verfügbar',
		t_qty_not_available:"Angeforderte Menge ist nicht verfügbar",
		t_product: "Produkt",
		t_price: "Preis",
		t_subtotal: "Steuerpflichtig",
		t_quantity: "Menge",
		t_total: "Gesamt",
		t_shipping_cost: "Versandkosten",
		t_vat: "Mehrwertsteuer",
		t_discount: "Rabatt",
		t_coupon: "Gutscheincode",
		t_apply_coupon: "Gutscheincode anwenden",
		t_proceed_to_checkout: "Zur Kasse gehen",
		t_total_cart: "Gesamtwarenkorb",
		t_mustreg:" Um eine Bestellung über unsere Website aufzugeben, müssen Sie registriert sein. \Die Registrierung geht schnell und einfach und ist eine großartige Möglichkeit, Ihr Online-Erlebnis zu vereinfachen.",
		t_reset_psw: "Setzen Sie Ihr Passwort zurück",
		t_reset_psw_text: "Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen einen Link, mit dem Sie erneut auf Ihr Konto zugreifen können",
		t_reset_psw_btn: "Zurücksetzen",
		t_insert_new_psw: "Geben Sie ein neues Passwort ein",
		t_save_btn: "Speichern",
		t_edit_btn: "Bearbeiten",	
		t_txt_login: "Anmelden",
		t_txt_register: 'Registrieren',
		t_txt_register_text: "Sie haben kein Konto?",
		t_txt_forget_psw: "Passwort vergessen?",
		// FORM LABEL
        t_type: "Kundentyp",
		t_please_select_customer:'Bitte wählen Sie eine Option',
		t_corporation: "Gesellschaft",
		t_partnership: "Partnerschaft",
		t_natural_person: "Natürliche Person",
		t_other: "Sonstiges",
        t_clientcode: "Kundencode",
        t_cf: "Steuernummer",
        t_piva: "Umsatzsteuer-Identifikationsnummer",
        t_name: "Vorname",
        t_surname: "Nachname",
        t_cityofBirth: "Ort der Geburt",
        t_dateOfBirth: "Geburtsdatum",
        t_placeOfBirth:'Geburtsstaat',
        t_provOfBirth:'Province Code',
        t_corpName: "Name des Unternehmens",
        t_billingAddress: "Adresse",
        t_billingCity: "Stadt",
        t_billingCap: "Postleitzahl",
        t_billingProv: "Province Code",
        t_billingState: "Staat",
        t_billingPec: "P.E.C.",
        t_billingCodeU: "Eindeutiger Code",
        t_billingEmail: "E-Mail",
        t_billingPhone: "Telefon",
        t_shippingAddress: "Adresse",
        t_shippingCity: "Stadt",
        t_shippingCap: "Postleitzahl",
        t_shippingProv: "Province Code",
        t_shippingState: "Staat",
        t_shippingEmail: "E-Mail",
        t_shippingPhone: "Telefon",
        t_shippingAddressSetting: "Lieferadresse",
        t_shippingAddress_bill: "Gleiche Adresse wie Rechnungsadresse",
        t_shippingAddress_alt: "Andere Adresse",
        t_shippingAddressShow: "Lieferadresse anzeigen",
        t_shippingAddressHide: "Lieferadresse ausblenden",
        t_not_complete_data:"Bitte geben Sie Ihre Rechnungs- und Versandinformationen ein.",
		t_btn_register: "Registrieren",
		t_btn_update: "Aktualisieren",	
		t_check_res_title: 'Ergebnis der Datenprüfung',
		t_check_res_text: 'Wir haben das Vorhandensein Ihrer Steuernummer (oder Umsatzsteueridentifikationsnummer) in unserem System festgestellt. Wenn Sie bereits einen Kundencode haben, können Sie diesen für den Import Ihrer Daten verwenden',	
		t_success_import_data_title:'Importierte Daten waren erfolgreich',
		t_error_import_data_title:'Die Daten konnten nicht importiert werden. Bitte vergewissern Sie sich, dass der Kundencode korrekt ist.',
		t_error_fiscal_code:'Falscher Steuercode. Bitte geben Sie einen gültigen Code ein.',
		t_error_vat_number:'Sie haben Ihre Umsatzsteuer-Identifikationsnummer nicht eingegeben, bitte geben Sie sie ein.',
		t_error_vat_number_text:'Die Mehrwertsteuernummer muss 11 Ziffern enthalten.',
		t_confirm:'Bestätigen',
		t_cancel:'Abbrechen',
		t_error_msg: "Fehlermeldung",
		t_country_select:'Staat auswählen',
		t_category_select:'Wählen Sie die Kategorie',
		t_phone_numberInput: {
              countrySelector: {
                Platzhalter: 'Ländercode',
                error: 'Land auswählen',
              },
              phoneInput: {
                Platzhalter: 'Telefonnummer',
                example: 'Beispiel:',
              },
        },
        t_order_note:'Anmerkungen (fakultativ)',
		t_gift_note: "Müssen Sie eine Widmung hinzufügen?",
		t_delivery_carrier_tracking: "Spediteur",
		t_delivery_note_tracking: "Hinweise zur Sendungsverfolgung",
		t_delivery_url_tracking: "Sendung verfolgen",
		t_estimated_delivery: "Voraussichtliches Lieferdatum",
		t_received: "Lieferdatum",

	},
	"esp":{
		t_welcome:'Bienvenido a la tienda online',
		t_cart: "Cart",
		t_checkout: "Pago",
		t_my_account: "Mi cuenta",
		t_my_orders: "Mis pedidos",
		t_my_orderlist: 'Lista de pedidos',
		t_order_detail: 'Detalle del pedido',
		// Da tradurre in lingua corrente
		t_date_header:'Fecha',
		t_status_header:'Estado',
		t_shipping_addr_header:'Dirección de envío',
		t_city_header:'Ciudad',
		t_zip_header:'Código postal',
		t_prov_addr_header:'Provincia',
		t_shipping_cost_header:'Gastos de envío',
		t_total_header:'Total',
		// Da tradurre in lingua corrente
		t_checkout_done : "Votre commande est terminée !",
		t_continue_shopping: "Weiter einkaufen",
		t_followus: "Síguenos en las redes sociales",
		t_products: "Productos",
		t_detail_shipping: 'Detalles de envío',
		t_select_product:'Seleccionar producto',
		t_sitemaps: "Mapa del sitio",
		t_contact: "Contactos",
		t_cart_empty: "Su carrito está actualmente vacío",
		t_cart_min_kg: "El pedido mínimo es ",
		t_cart_max_kg: "El pedido máximo es ",
		t_current_kg: "El total actual es ",
		t_billing: "Facturación",
		t_shipping: "Envío",
		t_all_shipping_address:'direcciones de envío registradas',
		t_shipping_address: "Mostrar dirección de envío",
		t_same_billing_address: "Igual que la dirección de facturación",
		t_other_shipping_address: "Otra dirección de envío",
		t_update: "Actualización",
		t_register: "Registro",
		t_addtocart: "Añadir a la cesta",
		t_removefromcart: "Eliminar",
		t_product_code: "Código del producto",
		t_product_desc: "Descripción del producto",
		t_product_other_info: "Otra información",
		t_product_related_products: "Productos relacionados",
		t_product_val_nut: "Valores nutricionales medios",
		t_nv_energy: "Energía",
		t_nv_fats: "Grasas",
		t_nv_carbohidratos: "Carbohidratos",
		t_nv_fibres: "Fibras",
		t_nv_protein: "Proteína",
		t_nv_salt: "Sal",
		t_more_info_ingredients: "Ingredientes",
		t_more_info_shelfLife: "Vida útil",
		t_more_info_expiry_date: "Fecha de caducidad",
		t_more_info_storage: "Almacenamiento",
		t_more_info_place_of_grinding: "Lugar de molienda",
		t_more_info_appearance: "Aspecto",
		t_more_info_colour: "Color",
		t_more_info_cooking_time: "Tiempo de cocción",
		t_more_info_individual_package_weight: "Peso individual del paquete",
		t_product_info_gen: "Información general",
		t_available_items: 'Artículo aún disponible',
		t_not_available: 'Producto no disponible temporalmente',
		t_qty_not_available:"La cantidad solicitada no está disponible",
		t_producto: "Producto",
		t_price: "Precio",
		t_subtotal: "Imponible",
		t_quantity: "Cantidad",
		t_total: "Total",
		t_shipping_cost: "Coste de envío",
		t_vat: "IVA",
		t_discount: "Descuento",
		t_coupon: "Código del cupón",
		t_apply_coupon: "Aplicar código de cupón",
		t_proceed_to_checkout: "Proceder al pago",
		t_total_cart: "Carrito total",
		t_mustreg:" Para realizar un pedido a través de nuestra página web es necesario estar registrado. \El registro es rápido y fácil y es una gran manera de simplificar su experiencia en línea",
		t_log_or_reg: "Login/Registro",
		t_reset_psw: "Restablece tu contraseña",
		t_reset_psw_text: "Introduce tu dirección de correo electrónico y te enviaremos un enlace para acceder de nuevo a tu cuenta",
		t_reset_psw_btn: "Reiniciar",
		t_save_btn: "Guardar",
		t_edit_btn: "Editar",
		t_txt_login: "Iniciar sesión",
		t_txt_register: 'Registro',
		t_txt_register_text: "¿No tiene una cuenta?",
		t_txt_forget_psw: "¿Has olvidado la contraseña?",
		// FORM LABEL
        t_type: "Tipo de cliente",
		t_please_select_customer:'Por favor, seleccione una opción',
		t_corporation: "Corporación",
		t_partnership: "Asociación",
		t_natural_person: "Persona física",
		t_other: "Otros",
        t_clientcode: "Código de cliente",
        t_cf: "Código fiscal",
        t_piva: "Número de IVA",
        t_name: "Nombre",
        t_surname: "Apellido",
        t_cityofBirth: "Lugar de nacimiento",
        t_dateOfBirth: "Fecha de nacimiento",
        t_placeOfBirth:'Estado de nacimiento',
        t_provOfBirth:'Código de provincia',
        t_corpName: "Nombre de la empresa",
        t_billingAddress: "Dirección",
        t_billingCity: "Ciudad",
        t_billingCap: "Código postal",
        t_billingProv: "Código de provincia",
        t_billingState: "Estado",
        t_billingPec: "P.E.C.",
        t_billingCodeU: "Código único",
        t_billingEmail: "Correo electrónico",
        t_billingPhone: "Teléfono",
        t_shippingAddress: "Dirección",
        t_shippingCity: "Ciudad",
        t_shippingCap: "Código postal",
        t_shippingProv: "Código de provincia",
        t_shippingState: "Estado",
        t_shippingEmail: "Correo electrónico",
        t_shippingPhone: "Teléfono",
        t_shippingAddressSetting: "Dirección de envío",
        t_shippingAddress_bill: "Igual que la dirección de facturación",
        t_shippingAddress_alt: "Otra dirección",
        t_shippingAddressShow: "Mostrar dirección de envío",
        t_shippingAddressHide: "Ocultar la dirección de envío",
        t_not_complete_data:"Por favor, introduzca sus datos de facturación y envío.",
		t_btn_register: "Registrar",
		t_btn_update: "Actualizar",
		t_check_res_title: 'Resultado de la comprobación de datos',
		t_check_res_text: 'Hemos detectado la presencia de su código fiscal (o número de IVA) en nuestro sistema. Si ya tiene un código de cliente, puede utilizarlo para importar sus datos',
		t_success_import_data_title:'Los datos importados fueron exitosos',
		t_error_import_data_title:"Los datos no pudieron ser importados. Por favor, asegúrese de que el código de cliente es correcto",
		t_error_fiscal_code:"Código fiscal incorrecto. Por favor, introduzca un código válido",	
		t_error_vat_number:'No ha introducido su número de IVA, por favor introdúzcalo',
		t_error_vat_number_text:'El número de IVA debe contener 11 dígitos',	
		t_confirm:'Confirmar',
		t_cancel:'Cancelar',
		t_error_msg:'Mensaje de error',
		t_country_select:'Elegir País',
		t_category_select:'Seleccionar categoría',
		t_phone_numberInput: {
              countrySelector: {
                placeholder: 'Código de país',
                error: 'Elegir país',
              },
              phoneInput: {
                placeholder: 'Número de teléfono',
                example: 'Ejemplo:',
              },
        },
        t_order_note:'Notas (opcional)',
		t_gift_note: "¿Tiene que incluir una dedicatoria?",
		t_delivery_carrier_tracking: "Transportista",
		t_delivery_note_tracking: "Notas de seguimiento del envío",
		t_delivery_url_tracking: "Seguimiento del envío",
		t_estimated_delivery: "Fecha estimada de entrega",
		t_received: "Fecha de entrega",
	},
}

module.exports = {
	"objLang": objLang,
	"t_trl":t_trl,

}
 

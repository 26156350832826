<script>
import serverConfig   from '@/_helpers/config';
import getFunct   from '@/_helpers/gfunct';
import moment from "moment"
import { mapActions, mapGetters } from "vuex";
import Slideshow from '@/components/Slideshow'
import axios from "axios"
moment.locale('it')
export default {
  name:'App',
  components:{
   Slideshow,
  },
  setup() {
   
  },
  data(){
    return {
      current_date_tmp: moment().unix(),
      current_date: moment().format('LLL'),
      cat: 'ismallo',
      categories:[],
      productsList:[],
      setCategorySel:null,
      spinner:null,
      btnClick:false,
      event_id:'',
    }
  },
  computed: {
     ...mapGetters(["products","categorySel","getActiveWidgets","getSShow","getTrl","getBlocks"]),
    
  },
  mounted(){
    setInterval(() => {
      this.current_date_tmp = moment().unix()
      this.current_date = moment().format('LLL')
    }, 1000);
    this.checkQS(this.$route.query)
    this.checkCat();
    this.getSlideshows();
    this.changeProdCat();
    this.getCatList();
    this.getResCart();
  },
  methods:{
    ...mapActions(["getProducts","getSlideshows","addToNewCart"]),
    track () {
      this.$gtag.screenview({
        app_name: 'Shop La Casa del Grano',
        screen_name: 'Home page',
      })
    },
    len(param){
      if(param){
        return param.length
      } else {
        return 0
      }
    },
    checkQS(qs){
      
      if (qs){
        if(qs.hasOwnProperty('cat') == true){
          
          switch(qs.cat){
            case "‘is_malloreddus’":
             
              this.cat = 'ismallo'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘sa_fregula’":
             
              this.cat = 'fregula'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘is_maccarronis’":
              
              this.cat = 'ismaccdebu'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘le_integrali’":
                           
              this.cat = '‘le_integrali’'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘le_rustiche’":
              
              this.cat = 'rustiche'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘farine’":
              
              this.cat = 'farine'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘cesti’":
              this.cat = 'cesti'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘all’":
              this.cat = 'all'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
            case "‘semole’":
              
              this.cat = 'semole'
              $cookies.set('cat',this.cat)
              this.changeProdCat()
            break;
          }
        } else {
          
          this.cat = 'ismallo'
            $cookies.set('cat',this.cat)
            this.changeProdCat()
        }
      }

    },
    getFormatP(value){
      return getFunct.formatPrice(value)
    },
    checkCat(){
      if($cookies.get('cat')){
        this.cat = $cookies.get('cat')
      } else {
        this.cat = 'ismallo'
      }
    },
    hasProp(obj,prop){
      if(obj){
        return obj.hasOwnProperty(prop);
      } else {
        return false
      }
      
    },
    getResCart(){
      this.$root.$root.getDCart()
    },
    getCatList(){
      this.categories = this.getActiveWidgets
    },
    changeProdCat(){
     
      let self = this
      this.spinner = true
      setTimeout(function(){
        axios.get(`${serverConfig.EP}/publicapi/allproducts/${self.cat.toString()}`).then((response) => {
            $cookies.set('cat',self.cat)
            self.productsList = response.data.products
            self.categories = response.data.category_sel    
            self.spinner = false
          }).catch(error => {
          self.spinner = false
          console.log(error);
        });
        }, 400)

    },
    addProduct(id,code){
      let self = this
      this.event_id = id
      this.eventCheck(id)
      this.btnClick = true
      let cookies_oh = $cookies.get("oh")
      let cookies_puid = $cookies.get("puid")
      let data = {
        'id': id,
        'gcode': code,
        'qta': 1,
        'size':'',
        'colors':'',
        'usr':'None'
      }

      if(!cookies_oh){
        data['preorder']=0;
        $cookies.set("ohex",0)
      }else{
        data['preorder']=cookies_oh;
      }
      if(!cookies_puid){
        data['puid']=0;
      }else{
        data['puid']=cookies_puid;
      }
      if ( this.hasProp(this.getUser,'uid') == true )
      {
        data['usr'] = this.getUser.uid
      }
      this.addToNewCart(data)
      
      console.log('datacart')
      console.log(data)

      this.$gtag.event('add_to_cart', {
        currency:"EUR",
        value:20,
        items:[
          {
            item_id:"SKU_123456",
            item_name:"Malloreddus",
            currency:"EUR",
            price:2.75,
            quantity:1,
          }
        ]

      })			
      
      setTimeout(function(){
        self.getResCart()
      },500)
      if(!cookies_oh){
        setTimeout(function(){
          self.getResCart()
        },1000)
        setTimeout(function(){
          self.getResCart()
        },2000)
      }

      setTimeout(function(){
        self.event_id = ''
        self.eventCheck(id)
      },2000)
       
    }, 
    getResCart(){
      const self = this
      this.$root.$root.getDCart()
      
      setTimeout(function(){
        self.btnClick = false
      },600)
    },
    checkDiscount(now,start,end) {

      let last_date = moment.unix(end).add(1,'days')

      if (now >= start && now <= last_date){
        return true
      } else {
        return false
      }
    },
    eventCheck(id) {
      if (this.event_id == id ){
        return true
      } else {
        return false
      }
    }

  }


}
</script>
<template>
  <div class="wrapper">
    <div>
  <b-carousel
    id="carousel-fade"
    style="text-shadow: 0px 0px 2px #000"
    fade
    indicators
    img-width="1024"
    img-height="250"
  >
  <div v-for="item in getBlocks" :key="item._id">
    <template v-if="item.tag_position == 'home' ">
    <b-carousel-slide
      :caption="getTrl.t_welcome"
      :img-src="item.image"
    ></b-carousel-slide>
    </template>
  </div>
  </b-carousel>
</div>
    <section class="section-main-brown mb-50">
    <div class="">
      <div class="row">
        <div class="col-lg-4 col-md-12 mt-20 mb-20"> 
        </div>
        <div class="col-lg-4 col-md-12 mt-20 mb-20 text-center">
          <template v-if="productsList">
            <label class="label_h3 pr-5">{{getTrl.t_select_product}}</label>

            <select v-model="cat" class="custom-field-no-block" @change="changeProdCat()">
              <option :value="item.value" v-for="item in getActiveWidgets" :key="item">{{   item.text }}</option>
            </select>
          </template>  
        </div>
        <div class="col-lg-4 col-md-12">
        </div>  
      </div>
    </div>
    </section>
    <section class=" mb-10">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-10 mb-10 text-center"> 
          <h1>Shop online prodotti tipici sardi</h1>
        </div>  
      </div>
    </div>
    </section>
    <div class="container container-page">
      <div class="row hidden-md-up" v-if="len(productsList) > 0">
        <div class="productBox col-md-3"  v-for="item in productsList" :key="item._id" >
          <a :href="'/' + item.descriptions.ita.slug">
          <div class="card text-center content_img">
            <div class="card-block">
              <img :src="item.image" class="img-thumbnail" :alt="item.descriptions.ita.title">
                <p class="card-title card-product-name ">{{item.descriptions.ita.title}}</p>
                <p class="card-text p-y-1 card-product-price price_box">
                  <template v-if="item.discount_perc>0 && checkDiscount(current_date_tmp,item.start_date_promo,item.end_date_promo) == true" >
                    <span class="current_price pr-5">
                        <button class="discount-circle-button" :href="'/' + item.descriptions.ita.slug">- {{item.discount_perc}}%<span class="discount-circle-greater-than"></span></button>
                    </span>
                    <span class="current_price pr-5">{{ getFormatP(item.discount_price) }} €</span>
                    <span class="old_price"> {{item.price }} €</span>

                  </template>
                  <template v-else>
                    <span class="current_price">{{ getFormatP(item.defPrice) }} €</span>
                  </template>       
                </p>
                  <div class="middle">
                    <div v-if="eventCheck(item._id)==false">
                    <a 
                      href="javascript:void(0)"
                      class="ct-link-button mainBtnmin"  
                      target="_self" 
                      :title="getTrl.t_addtocart" 
                      @click="addProduct(item._id,item.gcode)"
                      >
                      <svg fill="#ffffff" width="40px" height="40px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_iconCarrier"><path d="M 4 7 C 3.449219 7 3 7.449219 3 8 C 3 8.550781 3.449219 9 4 9 L 6.21875 9 L 8.84375 19.5 C 9.066406 20.390625 9.863281 21 10.78125 21 L 23.25 21 C 24.152344 21 24.917969 20.402344 25.15625 19.53125 L 27.75 10 L 25.65625 10 L 23.25 19 L 10.78125 19 L 8.15625 8.5 C 7.933594 7.609375 7.136719 7 6.21875 7 Z M 22 21 C 20.355469 21 19 22.355469 19 24 C 19 25.644531 20.355469 27 22 27 C 23.644531 27 25 25.644531 25 24 C 25 22.355469 23.644531 21 22 21 Z M 13 21 C 11.355469 21 10 22.355469 10 24 C 10 25.644531 11.355469 27 13 27 C 14.644531 27 16 25.644531 16 24 C 16 22.355469 14.644531 21 13 21 Z M 16 7 L 16 10 L 13 10 L 13 12 L 16 12 L 16 15 L 18 15 L 18 12 L 21 12 L 21 10 L 18 10 L 18 7 Z M 13 23 C 13.5625 23 14 23.4375 14 24 C 14 24.5625 13.5625 25 13 25 C 12.4375 25 12 24.5625 12 24 C 12 23.4375 12.4375 23 13 23 Z M 22 23 C 22.5625 23 23 23.4375 23 24 C 23 24.5625 22.5625 25 22 25 C 21.4375 25 21 24.5625 21 24 C 21 23.4375 21.4375 23 22 23 Z"/></g></svg>
                    </a> 
                    </div>
                    <div v-if="eventCheck(item._id)==true">
                      <MazSpinner
                        size="50"
                      />
                    </div>
                  </div>
              </div>
            </div>
            </a>
          </div>


        </div>
        <div class="row" v-else>
          <div class="col-lg-12 col-md-12 text-center">
            <template v-if="spinner">
              <MazSpinner
                size="100"
              />
            </template>
            <template v-else>
              
            </template>

          </div>
        </div>
       
        <br>

    </div>
  </div>
</template>
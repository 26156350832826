<template>
    <b-carousel
    id="carousel-page"
    style="text-shadow: 0px 0px 2px #000"
    fade
    indicators
    img-width="1024"
    img-height="180"
    >
     
       <b-carousel-slide active>
        {{title}}
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="180"
            :src="image"
            alt="index"
          >
        </template>
      </b-carousel-slide>         
    </b-carousel>
</template>

<script>
  export default {
    name:'Carousel',
    props:['items','title','image'],
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    }
  }
</script>
import moment from "moment"
const genFunct = {
	current_date_tmp:function() {
		moment.locale('it')
		return moment().unix()
	},
	current_date:function() {
		moment.locale('it')
		return moment().format('LLL')
	},
	formatPrice: function(value){
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
	},
	validateEmail: function(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          return true
        } else {
          return false
        }		
	},
	simpleCheckPsw: function(psw) {
  	 	const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  	 	return format.test(psw)
	},
	getCookie: function (name) {
		let matches = document.cookie.match(new RegExp(
		  "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

}

export default genFunct;
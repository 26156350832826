<script>
  import { mapActions, mapGetters } from "vuex";
  import { t_trl }   from '@/_helpers/lang';  

  export default {
    name:'FooterNav',
    props: ["pages","categories"],
    computed: {
     ...mapGetters(["getLanguage","getContacts","getTrl"]),
    },
    data() {
      return {
        year:new Date().getFullYear(),
        trl:t_trl,
        lang:'ita',
      }
    },

  methods:{
   ...mapActions(["setLang"]),
   hasOwnProp(obj,prop) {
      if(obj){
        return Object.prototype.hasOwnProperty.call(obj, prop)
      }
      return false
   },
   isValidUrl(string){
    try{
      new URL(string)
      return true;
    } catch (err) {
      return false;
    }
   },
   len(arr){
    if(arr){
      return arr.length
    }
    return 0
   },
   handleChange(){
      this.language = event.target.value
      this.setLang(this.language)
   },
   translName(obj,lang){
      
      
      if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "title") == true )
      {
        return obj['descriptions'][lang]['title']
      } else {
        return ''
      }
      
      //return 'NOME '+lang
   },
   translHrefName(obj,lang){
          if(!lang || lang==undefined){
      lang = 'ita'
    }
    lang='ita'
    
      if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "slug") == true )
      {
        
        if (this.isValidUrl(obj['descriptions'][lang]['slug']) == true)
        {
          return obj['descriptions'][lang]['slug']
        } else {
          return '/'+ obj['descriptions'][lang]['slug']
        }

      } else {
        return '/'
      }
   },   
  }
}
</script>
<template>

<section id="section-105-29" class=" ct-section mb-50 mt-50">
  <div class="container">
  <div class="ct-section-inner-wrap text-center">
    <div id="text_block-106-29" class="ct-text-block text-center">
      <label class="card-product-name mb-50">
        Realizzato con il contributo della Regione Autonoma della Sardegna e del POR FESR 2014-2020
      </label>
      </div>
       
      <b-img src="http://beta.lacasadelgrano.com/wp-content/uploads/2022/05/loghi-istituzionali-por-fesr.jpeg" fluid-grow alt="POR FESR"></b-img>
    </div>
  </div>
</section>
  
  <div class="wrapper mt-1"></div>
<div class="cdg_primary_footer cdg_footer">
  <b-container class="bv-example-row">
  <b-row>
    <b-col lg="3" md="12" class="col-footer mt-5"><h3 v-if="getTrl">{{getTrl.t_followus}}</h3>           
        <a href="https://www.facebook.com/La-Casa-Del-Grano-Srl-1591965464354837/"><i class="fa fa-facebook fa-lg p-2"></i></a>
        <a href="https://www.instagram.com/lacasadelgrano/"><i class="fa fa-instagram fa-lg p-2"></i></a>
        <a href="https://www.youtube.com/channel/UCeqhwLA6FcQojQcvcXO1CNA"><i class="fa fa-youtube-play fa-lg p-2"></i></a>
    </b-col>
    <b-col  lg="3" md="12" class="col-footer mt-5">
      <h4 v-if="getTrl">{{getTrl.t_products}}</h4>
        <ul class="pt-10">
          <li v-for="item in categories" :key="item._id"> 
            <a :href="item.descriptions.ita.slug">{{ item.descriptions.ita.title}}</a>
          </li>
        </ul>
    </b-col>
     <b-col  lg="3" md="12" class="col-footer mt-5"><h4 v-if="getTrl">{{getTrl.t_sitemaps}}</h4>
       <ul class="pt-10">
          <li v-for="item in pages" :key="item._id"> 
            <a :href="translHrefName(item, getLanguage)"  v-html="translName(item, getLanguage)"></a>
          </li>
        </ul>
     </b-col>
     <b-col  lg="3" md="12" class="col-footer mt-5"><h4 v-if="getTrl">{{getTrl.t_contact}}</h4>
      <template v-if="len(getContacts) > 0">
        <ul class="pt-10">
          <li> 
            {{getContacts[0].address}}
          </li>
          <li> 
            {{getContacts[0].city}}
          </li>
          <li> 
            {{getContacts[0].email}}
          </li>
          <li> 
            {{getContacts[0].phone_1}}
          </li>
        </ul>
      </template>
     </b-col>
  </b-row>
  <b-row class="mt-50">
    <b-col lg="12" md="12">
      <div class="mt-20 text-center">
        © {{year}} La Casa del Grano Srl - P. IVA: 01062660921 - Sito web realizzato da Altopiano | <a href="https://lacasadelgrano.com/privacy/">Privacy e cookie policy</a> | <a href="/account/login/">Area personale</a>
      </div>
        
    </b-col>
  </b-row>
</b-container>
</div>


</template>




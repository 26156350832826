<script>
//import { useCookies } from "vue3-cookies";

//import SiteNav from '@/components/SiteNav'
import FooterNav from '@/components/FooterNav'

import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { mapActions, mapGetters } from "vuex";
import { objLang, t_trl }   from '@/_helpers/lang';  
import getFunct   from '@/_helpers/gfunct';

export default {
  name:'App',
  components:{
    //SiteNav: SiteNav,
    FooterNav: FooterNav,
    

  },
  setup() {
    const store = useStore()
    onBeforeMount(() => {
      store.dispatch('fetchUser')
    })
  //  const { cookies } = useCookies();
  //return { cookies };
  },
  data(){
    return {
      language:'it',
      iconlng:'it',
      scTimer: 0,
      scY: 0,
      languages: [
          {
            flag: require("@/assets/images/flags/italy.svg"),
            language: "ita",
            title: "Italiano",
          },
        /*
          {
            flag: require("@/assets/images/flags/gb.svg"),
            language: "en",
            title: "English",
          },

          {
            flag: require("@/assets/images/flags/germany.svg"),
            language: "de",
            title: "Deutsche",
          },

          
          {
            flag: require("@/assets/images/flags/fr.svg"),
            language: "fr",
            title: "Français",
          },
          {
            flag: require("@/assets/images/flags/spain.svg"),
            language: "esp",
            title: "Española",
          },
          */
          ],
      text:'',
      flag:'',
                message: '',
          startInterval: ''
    }
  },
  computed: {
     ...mapGetters(["getActiveWidgets","getActivePage", "getUser","getLanguage","getTrl","getCartItems", "getTotalPrice", "getDetCart"]),
  },
  mounted(){ 
    this.getLang();
    this.getWidgets();
    this.getDCart()
    this.getAuth()
    window.addEventListener('scroll', this.handleScroll);

  },
  methods:{
   ...mapActions(["getProducts","getWidgets","logout","setLang","getDetailsCart","setUpdateCart","getLang","getAuth"]),
   exitF(){
    this.logout()
   },
   isValidUrl(string){
    try{
      new URL(string)
      return true;
    } catch (err) {
      return false;
    }
   },
   getFormatP(value){
      return getFunct.formatPrice(value)
   },
   userFilt(usr){
    if( usr.includes('@') == true ) {
      let user = usr.split('@')
      return `${user[0]}@`
    } else {
      return usr
    }
   },
   handleChange(){
      this.language = event.target.value

      this.setLang(this.language)
   },
   translName(obj,lang){
      
      
      if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "title") == true )
      {
        return obj['descriptions'][lang]['title']
      } else {
        return ''
      }
      
      //return 'NOME '+lang
   },
   translHrefName(obj,lang){
    if(!lang || lang==undefined){
      lang = 'ita'
    }
    lang='ita'
    
      if (Object.prototype.hasOwnProperty.call(obj['descriptions'][lang], "slug") == true )
      {
        
        if (this.isValidUrl(obj['descriptions'][lang]['slug']) == true)
        {
          return obj['descriptions'][lang]['slug']
        } else {
          return '/'+ obj['descriptions'][lang]['slug']
        }

      } else {
        return '/'
      }

   },
   
   getDCart(){
    let self = this
    setTimeout(function(){
        self.getDetailsCart()
       
      },500)
    //this.getDetailsCart()
   },
    setLanguage(locale, country, flag) {
      this.language = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      //this.language = event.target.value

      this.setLang(this.language)
      
    },
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },


  }


}
</script>
<template>

    <div class="supNav" style="position:relative; z-index:11;"  >
        <div class="container"> 
          <div class="row hidden-md-up">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6">
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            
              <div class="social-icon-bar " >
                <a href="https://www.facebook.com/La-Casa-Del-Grano-Srl-1591965464354837/"><i class="fa fa-facebook fa-lg p-2"></i></a>
                <a href="https://www.instagram.com/lacasadelgrano/"><i class="fa fa-instagram fa-lg p-2"></i></a>
                <a href="https://www.youtube.com/channel/UCeqhwLA6FcQojQcvcXO1CNA"><i class="fa fa-youtube-play fa-lg p-2"></i></a>
              
                <template v-if="getUser != null">
                  <b-dropdown :text="userFilt(getUser.email)"  class="m-2 btb-dropd-account ml-50">
                    <b-dropdown-item href="/account/profile/">Account</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="javascript:void(0)" @click="exitF()">Logout</b-dropdown-item>
                  </b-dropdown>
                </template>
                <template v-else>
                  <a href="/account/login/" class="icon-white">
                    ACCEDI / REGISTRATI
                    <i class="fa fa-user-o fa-lg p-2"></i>
                  </a>
                </template>                
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="cdg_primary">
      <div class="container"> 
        <div class=" navbar-expand-lg cdg_primary " >
        <b-navbar class="container" toggleable="lg" type="dark" variant="cdg_primary navbar navbar-expand-lg"             >
          <a id="link-427-14" href="/" class="navbar-brand">          
            <img loading="eager" id="image-13-225" alt="La Casa del Grano" src="https://www.lacasadelgrano.com/wp-content/uploads/2022/05/la-casa-del-grano.png" class="ct-image img-logo">
          </a>
          <b-navbar-toggle id="navbar-toggle" target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item " v-for="item in getActivePage" :key="item._id" >
                    <a class="nav-link link_text-199-14" :href="translHrefName(item, getLanguage)"  v-html="translName(item, getLanguage)"></a>
                  </li>
                  <li class="nav-item li-cart" >
                    <a href="/cart/detail/">
                      <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" fill="currentColor" role="img" focusable="false" data-v-7ba5bd90=""><path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path></svg>
                    </a>
                    <a href="/cart/detail/" style=" position: relative;bottom: 7px;left: 3px;">
                      <b-badge variant="danger">{{getCartItems}}</b-badge>
                    </a>

                  </li>
                  <li class="nav-item li-cart-price" >
                    <a href="/cart/detail/" class="nav-link">
                      {{ getFormatP(getTotalPrice) }}&euro;
                    </a>
                  </li>

              <li class="nav-item li-lang">
                  <div class="dropdown  topbar-head-dropdown header-item">
                    <button  id="btn-setlang" type="button" class="btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img id="header-lang-img" src="@/assets/images/flags/it.svg" alt="Header Language" height="20" class="rounded" />
                    </button>
                  <div class="dropdown-menu dropdown-menu-end">                   
                    <a href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
                                            @click="setLanguage(entry.language, entry.title, entry.flag)"
                                            :class="{ active: lan === entry.language }" class="dropdown-item notify-item language py-2"
                                            data-lang="en" title="Italiano">
                      <img :src="entry.flag" alt="user-image" class="me-2 rounded" height="18" />
                      <span class="align-middle">{{ entry.title }}</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>        
          </b-collapse>        
        </b-navbar>
        </div>
      </div> 
    </div>
    {{message}}
    <router-view/>
    <transition name="fade">
      <template v-if='getCartItems > 0'>
        <div id="pagetop" class="fixed right-0 bottom-0 section-main-brown" v-show="scY > 300" @click="toTop" style="text-align:right; margin-right:50px">
        <div class="container">
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
              stroke="#4a5568"
              stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
            <path d="M18 15l-6-6-6 6"/>
          </svg>
          <a href="/cart/detail/" style="color:black">
            <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" fill="currentColor" role="img" focusable="false" data-v-7ba5bd90=""><path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path></svg>
          </a>
          <a href="/cart/detail/" style=" position: relative;bottom: 7px;left: 3px;">
            <b-badge variant="danger">{{getCartItems}}</b-badge>
          </a>
        </div>
        </div>
      </template>
      <template v-else>
    <div id="pagetop" class="fixed right-0 bottom-0 " v-show="scY > 300" @click="toTop" style="text-align:right; margin-right:50px">
     <div class="container">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
           stroke="#4a5568"
           stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
        <path d="M18 15l-6-6-6 6"/>
      </svg>
      <a href="/cart/detail/" style="color:black">
        <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" fill="currentColor" role="img" focusable="false" data-v-7ba5bd90=""><path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path></svg>
      </a>
      <a href="/cart/detail/" style=" position: relative;bottom: 7px;left: 3px;">
        <b-badge variant="danger">{{getCartItems}}</b-badge>
      </a>
     </div>
    </div>
      </template>

  </transition>      
    <FooterNav  :pages="getActivePage" :categories="getActiveWidgets" />

</template>
<style>
.btn{
  border: 0px solid #8c0013!important;
  width: 100%!important;
  background-color:#8c0013!important;
}
</style>
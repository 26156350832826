<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { objLang, t_trl }   from '@/_helpers/lang';  
import Carousel from '@/components/Carousel'
import { mapActions, mapGetters } from "vuex";
import getFunct   from '@/_helpers/gfunct';

import 'maz-ui/css/main.css'
export default {
	setup () {
		const login_form = ref({});
		
		const store = useStore();

		const reset = () => {
			store.dispatch('reset', login_form.value);
		
		}

		return {
            login_form,
			reset,

		}
	},
    
	components:{
		Carousel
	},
  	computed: {
     ...mapGetters(["getTrl"]),
  	},
  	methods:{

 		
  	}
}
</script>
<template>
<div class="wrapper">
    <div class="page_container "></div>
    <div class="container  ">
      <div id="div_block-360-333" class="ct-div-block">
        <div id="headline-51-12"></div>
      </div>
    </div>
   <div class="container mb-50">
      <div class="row forms mb-50">
		<div class="col-lg-4 col-md-12">
		</div>
		<div class="col-lg-4 col-md-12">
			<form class="login" @submit.prevent="reset">
				<h2 v-html="getTrl.t_reset_psw" class="mb-50"></h2>
                
          		<b-form-group :label="getTrl.t_reset_psw_text" >
				  <MazInput
				   v-model="login_form.email"
				    placeholder="E-mail"
				    autocomplete="new-email"
				    class="maz-mb-3"
				    clearable
				    left-icon-name="comment"
				    type="email"
				  />
				</b-form-group>
          		<b-form-group class="mt-10">
					<input 
						type="submit" 
						:value="getTrl.t_reset_psw_btn" 
					/>
				</b-form-group>
				<b-form-group class="mt-10 text-center">
					<a href="/account/login/">	{{getTrl.t_txt_login}}</a>
				</b-form-group>
				<b-form-group class=" text-center">
					{{ getTrl.t_txt_register_text }} <a href="/account/login/">{{ getTrl.t_txt_register }} </a>
				</b-form-group>
			</form>
		</div>
		<div class="col-lg-4 col-md-12">
		</div>	
	  </div>

</div>
</div>
</template>
<style>
.forms {
	display: flex;
	min-height: 50vh;
}
.forms > input {
	text-align: center;
}
form {
	flex: 1 1 0%;
	padding: 8rem 1rem 1rem;
}

form.register {
	color: #FFF;
	background-color:#640e19;
	background-image: linear-gradient(
		to bottom right,
		#640e19,
		#8c0013
	);
	border-radius:0.5rem;
}

h2 {
	font-size: 3.5rem;
	text-transform: capitalize;
	font-weight: 800;
	margin-bottom: 2rem;
}

input {
	appearance: none;
	border: none;
	outline: none;
	background: none;

	display: block;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	font-size: 1.5rem;
	margin-bottom: 2rem;
	padding: 0.5rem 0rem;
}

input:not([type="submit"]) {
	opacity: 0.8;
	transition: 0.4s;
}

input:focus:not([type="submit"]) {
	opacity: 1;
}

input::placeholder {
	color: inherit;
}

form.register input:not([type="submit"]) {
	color: #000;
	border-bottom: 2px solid #FFF;
	text-align: left;
}

form.login input:not([type="submit"]) {
	text-align: left;
}

form.login input[type="submit"] {
	background-color:#640e19;
	color: #FFF;
	font-weight: 700;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	cursor: pointer;
	text-transform: uppercase;
}

form.register input[type="submit"] {
	background-color: #FFF;
	color: #640e19;
	font-weight: 700;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	cursor: pointer;
	text-transform: uppercase;
}
</style>